import React,{useEffect,useRef} from 'react'
import Carousel from 'react-elastic-carousel'
import { useDispatch } from 'react-redux';
import SlideProduct from '../../components/Product/ProductCard'
import useWindowDimensions from '../useWindowDimensions';
export default function Products({items,items2=null}) {
  const {width}=useWindowDimensions()
  const carouselRef = useRef(null);
  const carouselRef2 = useRef(null);
  const dispatch = useDispatch()
  let resetTimeout;
  return (

    
      items2!=null ?
      <>
      <Carousel 
        ref={carouselRef}
        disableArrowsOnEnd={true}
        pagination={false}
        onChange={()=>{
          // carouselRef2.current.goTo(carouselRef.current.state.activeIndex)
        }}
        itemsToShow={width<1140 ? (width<1180 ? (width<900 ? 2 : 2) : 3) : (width<1600 ? (width<1400 ? 3:4) :5)}>
        {
          items.map((item,index)=>{

            return (

              <div
                key={item.id}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <SlideProduct product={item} />
                {items2[index] != undefined && (
                  <SlideProduct product={items2[index]} />
                )}
              </div>
            );
          })
        }
      </Carousel>
      
      </>
      :
      <>
      <Carousel 
      ref={carouselRef}
      disableArrowsOnEnd={true}
      pagination={true}
      onChange={()=>{
        //console.log(carouselRef.current.state.activeIndex)
      }}
        itemsToShow={width<1140 ? (width<1180 ? (width<900 ? 2 : 2) : 3) : (width<1600 ? (width<1400 ? 3:4) :5)}>
      {
        items.map((item,index)=>{
          return(
            
            <SlideProduct key={item.id} product={item} />
          )
        })
      }
    </Carousel>
      </>

    
  )
}
