import styled from "styled-components";

const SlideProductItem = styled.div`
  margin: 10px;
  border-radius: 10px;
  color: #000;
  min-height: 400px;
  // border: 2px solid #3333;
  background-color: #fff;
  @media only screen and (max-width: 1140px) {
    height: 40px;
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;

    padding: 0;
    margin: 0;
    transition: 300ms all;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);
    .box {
      border-radius: 10px;
      box-shadow: 0px 0px 15px #000;
      background: #fff;
      max-width: 90%;
      min-width: 80vw;
      max-width: 95vw;

      position: relative;
      .list {
        max-width: 100%;
        min-width: 80vw;
        overflow-x: auto;
        // padding-top: 250px;
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: -1;
      }
      .close {
        position: absolute;
        top: -13px;
        right: -13px;
        color: #333;
        font-size: 28px;
        z-index: 99999;
        padding: 5px 20px;
        border-radius: 0px 10px 0px 10px;
      }
    }
  }
  min-width: 240px;
  max-width: 240px;
  overflow: hidden;
  cursor: pointer;
  // box-shadow: 0px 2px 4px #cccccc;

  @media only screen and (max-width: 1140px) {
    width: 100%;
    min-height: 450px;
    max-height: 450px;
  }
  &:hover {
    box-shadow: 0px 5px 10px #cccccc;
  }
  h3 {
    font-size: 15px;
    font-weight: normal;
    color: #000;
    padding: 5px 15px 10px 15px;
    @media only screen and (max-width: 1140px) {
      font-size: 12px;
      padding: 5px;
      margin-top: 5px;
    }
  }
  .image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0%;
    height: 330px;
    @media only screen and (max-width: 1140px) {
      height: 300px;
    }
    margin-bottom: 20px;
    @media only screen and (max-width: 1140px) {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    overflow: hidden;
    object-fit: cover !important;
    img {
      background-color: red;
      width: 100%;
      object-fit: cover !important;
      user-select: none;
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  @media only screen and (max-width: 840px) {
    max-width: ${(props) =>
      (props.slider != undefined && props.slider != null) ||
      props.grid == "list"
        ? "100%"
        : "48%"};
    min-width: ${(props) =>
      (props.slider != undefined && props.slider != null) ||
      props.grid == "list"
        ? "100%"
        : "48%"};
    min-height: ${(props) => (props.grid == "list" ? "550px" : "300px")};
    margin: 1%;
    .image-box {
      height: unset;
      img {
        height: unset;
        width: 100%;
      }
    }
  }
  .variant-list {
    position: absolute;
    background: rgba(0, 0, 0, 0.03);
    padding: 5px 10px;
    border-radius: 150px;
    right: ${(props) => (props.grid == "list" ? 30 : 10)}px;
    top: 320px;
    display: flex;
    align-items: center;
    font-size: 14px;
    &:hover {
      transform: scale(1.1);
    }
    .colors {
      width: 30px;
      display: flex;

      div {
        width: 15px;
        height: 15px;
        position: relative;
        border-radius: 150%;
      }

      div:first-child {
        background-image: linear-gradient(
          to right,
          #3023ae 0%,
          #53a0fd 48%,
          #b4ec51 101%
        );
      }
      div:last-child {
        margin-left: -7px;
        background-image: linear-gradient(
          to right,
          #6bceb4 0%,
          #f98cc2 48%,
          #fff781 101%
        );
      }
    }
    @media only screen and (max-width: 1140px) {
      position: absolute;
      top: ${(props) => (props.grid == "list" ? "360" : "171")}px;
      z-index: 50;
      .colors {
        width: ${(props) => (props.grid == "list" ? 25 : 16)}px;
        div {
          width: ${(props) => (props.grid == "list" ? 15 : 9)}px;
          height: ${(props) => (props.grid == "list" ? 15 : 9)}px;
          border-radius: 150%;
        }
        height: ${(props) => (props.grid == "list" ? 15 : 9)}px;
      }
      font-size: ${(props) => (props.grid == "list" ? 15 : 10)}px;
    }
  }

  .product-count {
    opacity: 0;
    top: 10px;
    right: 10px;
    position: absolute;
    top: 0;
    transition: 600ms all;
    font-size: 22px;
    z-index: 5;
    padding: 5px;
  }
  position: relative;
  &:hover {
    .action {
      .basket {
        opacity: 100%;
      }
    }
  }
  &:focus-within {
    .action {
      .basket {
        opacity: 100%;
      }
    }
  }
  .action {
    position: ${(props) => (props.grid != "list" ? "absolute" : "")};
    @media only screen and (max-width: 1140px) {
      .price {
        position: ${(props) => (props.grid != "list" ? "absolute" : "")};
        bottom: 20px;
      }
    }
    bottom: 0;
    left: 5px;
    width: 94%;
    padding: 5px 15px 10px 15px;
    display: flex;
    flex-direction: column !important;
    justfiy-content: center;
    align-items: center;

    .price {
      display: flex;
      justfiy-content: center;
      font-size:14px!important;
      text-align: center;
      color: #005bb4 !important;
      display: flex;
      transition: 500ms all;
      align-items: center;

      transition: 600ms all;
      div {
        font-family: "Overpass", sans-serif !important;
        line-height: 22px;
        font-size: 18px;
        display: flex;
        span {
          font-size: 13px;
          margin-left: 5px;
        }
      }
      @media only screen and (max-width: 1140px) {
        div {
          font-size: 18px !important;
        }
      }
    }

    .basket {
      transition: 300ms all;
      z-index: 5;
      font-size: 13px;
      display: flex;
      opacity: ${(props) => (props.grid != "list" ? "0" : "100%")};
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        border: 0;
        width: 100%;
        display: block;
        color: #000;
        margin-top: 10px;
        outline: 0;
        padding: 13px 10px;
        background-color: #f27a1a;
        transition: 200ms all;
        color: ${({ theme }) => theme.mainThemeColor};
        border-radius: 15px;
        &:hover {
          color: #000;
          background-color: #ff9f43;
        }
      }
    }
    .basket,
    .price {
      color: #000;
    }
  }
  .list-price div {
    position: relative;
    opacity: 0.7;
    &:before {
      bottom: 50%;
      transform: rotate(-10deg);
      content: "";
      position: absolute;
      height: 3px;
      left: -5%;
      width: 110%;
      background: ${({ theme }) => theme.altHeader};
    }
  }
  &:hover {
    .product-count {
      opacity: 100%;
    }
    .action {
      .basket {
      }
      .price {
      }
    }
  }

  .in-stock {
    position: absolute;
    top: 0;
    right: 0;
    background: #eee;
    padding: 2px 5px;
    display: none;
    font-family: "OverPass", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #000;
    box-shadow: 0px 0px 2px 0px #000;
  }
`;
const SlideBrandItem = styled.div`
  border: 1px solid ${({ theme }) => theme.elementBackground};
  margin: 10px;
  display: inline-block;
  border-radius: 30px;
  overflow: hidden;
  min-height: 140px;
  cursor: pointer;
  @media only screen and (max-width: 1140px) {
    width: ;
  }
  transition: 300ms all;
  &:hover {
    box-shadow: 0px 0px 7px #616161;
  }
  h3 {
    font-size: 14px;
    text-align: center;
    background: ${({ theme }) => theme.elementBackground};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;

    padding: 5px 10px;
  }
  img {
    width: 100%;
    padding: 5px;
    min-width: 190px;
    max-width: 190px;

    height: 190px;
    @media only screen and (max-width: 1140px) {
      min-width: 135px;
      max-width: 135px;
      height: 135px;
    }
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  position: relative;
  .product-count {
    position: absolute;
    opacity: 0;
    top: 10px;
    right: 10px;
    transition: 600ms all;
    font-size: 22px;
    z-index: 5;
    background-color: ${({ theme }) => theme.mainThemeBackground};
    border-radius: 50%;
    padding: 5px;
  }
  .action {
    position: relative;

    .price {
      display: flex;
      opacity: 100%;
      flex-direction: column;
      justify-content: center;
      transition: 600ms all;
      align-items: center;

      h4 {
        font-size: 15px;
        color: ${({ theme }) => theme.mainThemeColor};
      }
      h5 {
        font-size: 13px;
        text-decoration: line-through;
      }
    }
    .basket {
      transition: 800ms all;
      top: 10px;
      opacity: 0;
      button {
        border: 0;
        outline: 0;
        width: 200px;
        padding: 10px 0px;
        background-color: ${({ theme }) => theme.mainThemeBackground};
        transition: 600ms all;
        color: ${({ theme }) => theme.mainThemeColor};
        border-radius: 10px;
        &:hover {
          background-color: ${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    .basket,
    .price {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &:hover {
    .product-count {
      opacity: 100%;
    }
    .action {
      .basket {
        opacity: 100%;
      }
      .price {
        opacity: 0;
      }
    }
  }
`;
export {SlideProductItem,SlideBrandItem}