import React from 'react'
import { MobileSearchContainer } from '../../styles/Header'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function MobileSearchBox({open,setOpen,getResult,result,search,setSearch,blurred}) {
  const history=useHistory()
  const searchRef=React.useRef(null)
  React.useEffect(()=>{
    if(open && searchRef!=null && searchRef.current!=null){
      setTimeout(() => {
        searchRef.current.focus();
      }, 300);
    }
  },[open])
  return (
    <MobileSearchContainer open={open}>
      <div className='overlay'></div>
      <div  className={"search-box"}>
        <FontAwesomeIcon icon={faSearch} />
        <input
          ref={searchRef}
          type="text"
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              history.push("/search/" + search.replace(/\//g, "-slash-"));
              setOpen(false);
            } else {
              getResult(search);
            }
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Ürün ara"
        />
        <button onClick={() => {
          history.push("/search/" + search.replace(/\//g, "-slash-"));
          setOpen(false)
        }}> ARA </button>
        <div
          className="search-result"
          style={{
            transform: result.length > 0 ? "scale(1)" : "scale(0)",
            height: result.length > 0 ? "auto" : "0",
            width: result.length > 0 ? "100%" : "0",
          }}
        >
          <ul>
            {result.map((x) => {
              const startIndex = x.title
                .toLowerCase()
                .indexOf(search.toLowerCase());
              if (startIndex !== -1) {
                // Aranan metni bulduysa vurgula
                const before = x.title.substring(0, startIndex);
                const highlighted = x.title.substring(
                  startIndex,
                  startIndex + search.length
                );
                const after = x.title.substring(startIndex + search.length);
                console.log("before", before);
                console.log("highlighted", highlighted);
                console.log("after", after);
                return (
                  <li key={x.slug} onClick={(e) => {
                    history.push("/urun/" + x.slug);
                    setOpen(false)
                  }}>
                    <Link
                      // onClick={(e) => e.stopPropagation()}
                      to={"/urun/" + x.slug}
                    >
                      <img src={x.image!=null ? x.image:'https://entegroid.com/noimage'} alt={x.title} />
                      <span>
                        {before}
                        <strong>{highlighted}s</strong>
                        {after}
                      </span>
                    </Link>
                  </li>
                );
              } else {
                // Aranan metni bulamadıysa normal olarak görüntüle
                return (
                  <li key={x.slug}>
                    <Link
                      onClick={(e) => e.stopPropagation()}
                      to={"/urun/" + x.slug}
                    >
                      <img
                        src={
                          x.image
                        }
                        alt={x.title}
                      />
                      <span>{x.title}</span>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </MobileSearchContainer>
  );
}
