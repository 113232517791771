import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import ProductList from "../components/Content/ProductList";
import { useProductRequests } from "../helpers/ProductRequests";
import { Helmet } from "react-helmet";
import Footer from "../components/Navigation/Footer";
import { BottomNav } from "../styles/Header";
import Header from "../components/Navigation/Header";
import { ProductContent } from "../styles/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faBars,
  faCheck,
  faFilter,
  faPalette,
  faSearch,
  faSlidersH,
  faStarHalfAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CategoryItem from "../components/Content/Category/FilterGroup/CategoryItem";
import ProductItem from "../components/Product/ProductCard";
import Carousel, { consts } from "react-elastic-carousel";
import useWindowDimensions from "../components/useWindowDimensions";
import { Sentry } from "react-activity";
import ColorItem from "../components/Content/Category/FilterGroup/ColorItem";
import SoleItem from "../components/Content/Category/FilterGroup/SoleItem";
import BrandItem from "../components/Content/Category/FilterGroup/BrandItem";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { SwapButton } from "../styles/Product";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setProducts,
  setCategories,
  setPage,
  setLoading,
  setAllData,
  setSelected,
  setBrands,
  setSelectedBrands,
  setColors,
  setSoles,
  setCurrentIndex,
  setSelectedColors,
  setSelectedSoles,
  setMinPrice,
  setMaxPrice,
  setFilterOpen,
  setBrandOpen,
  setColorOpen,
  setSort,
  setBrandSearchTxt,
  setOpenedBox,
  setColorSearchTxt,
} from "../components/stores/Product";
export default function Category(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    soles,
    products,
    page,
    brandSearchTxt,
    colorSearchTxt,
    currentImageIndex,
    openedBox,
    filterOpen,
    brandOpen,
    colorOpen,
    logLoad,
    colors,
    brands,
    minPrice,
    maxPrice,
    selectedSoles,
    selectedColors,
    selectedBrands,
    selected,
    loading,
    allData,
    sort,
    categories,
  } = useSelector((state) => state.product);
  const location = useLocation();

  const width = useWindowDimensions().width;
  const { slug } = useParams();

  const { getProducts, getCategories, getBrands, getColors, getSpecialCodes } =
    useProductRequests();

  const simpleCount = 60;
  const [opened, setOpened] = useState("");
  const [open, setOpen] = useState(width < 1140 ? false : true);

  useEffect(() => {
    if (
      location.state == undefined ||
      location.state.back == undefined ||
      location.state.back == null ||
      products.length == 0
    ) {
      dispatch(setPage(1));
      dispatch(setSelectedColors([]));
      dispatch(setSelected([]));
      dispatch(setSelectedBrands([slug]));
      getCategories(slug).then((res) => {
        dispatch(setLoading(false));
        console.log("res", res);
        dispatch(
          setCategories(res.data.data.filter((x) => x.subGroups.length > 0))
        );
      });
    }
  }, [slug]);

  const getProductReq = () => {
    getProducts({
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList: [slug],
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        dispatch(setAllData(res.data.data));
        if (width < 1000) {
          // setOpen(false)
        }
        dispatch(setLoading(false));
        dispatch(setProducts([...res.data.data.items]));
        dispatch(setPage(1));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };
  useEffect(() => {
    if (loading != false) {
      return;
    }
    dispatch(setLoading(true));
    dispatch(setProducts([]));

    getProducts({
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList:[slug],
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        dispatch(setAllData(res.data.data));
        if (width < 1000) {
          // setOpen(false)
        }
        dispatch(setLoading(false));
        dispatch(setProducts([...res.data.data.items]));
        dispatch(setPage(1));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });

   
  }, [selected]);

  useEffect(() => {
    getColors({
      groupSlugList: selected.length == 0 ? [] : selected,
      brandSlugList:[slug],
    }).then((res) => {
      dispatch(setColors(res.data.data));
    });
  }, [selected, selectedBrands, slug]);

  useEffect(() => {
    if (loading == true) {
      return;
    }
    dispatch(setProducts([]));
    dispatch(setLoading(true));

    getProducts({
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,

      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList: [slug],
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        if (width < 1000) {
          // setOpen(false);
        }
        dispatch(setLoading(false));
        dispatch(setAllData(res.data.data));

        dispatch(setPage(1));
        dispatch(setProducts([...res.data.data.items]));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }, [selectedBrands, selectedColors, selectedSoles]);

  const getNewPage = (pg) => {
    if (loading == true) {
      return;
    }
    dispatch(setLoading(true));
    getProducts({
      page: pg,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList: [slug],
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        if (width < 1000) {
          // setOpen(false);
        }
        dispatch(setAllData(res.data.data));
        dispatch(setPage(pg));
        dispatch(setProducts([...products, ...res.data.data.items]));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (loading == false) {
      dispatch(setLoading(true));
      dispatch(setProducts([]));
      getProducts({
        page: 1,
        count: simpleCount,
        specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
        specialCodeIdList: selectedSoles,
        colorList: selectedColors,
        groupSlugList:
          selected.length == 0
            ? []
            : selected,
        brandSlugList:[slug],
        sort: sort.sort,
        sortedBy: sort.sortedBy,
        betweenPrices: minPrice == null && maxPrice == null ? false : true,
        minPrice: minPrice == null ? -1 : minPrice,
        maxPrice: maxPrice == null ? -1 : maxPrice,
      })
        .then((res) => {
          if (width < 1000) {
            setOpen(false);
          }
          dispatch(setAllData(res.data.data));
          dispatch(setPage(1));
          dispatch(setProducts(res.data.data.items));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  }, [sort]);

  useEffect(() => {
    getSpecialCodes(2).then((res) => {
      dispatch(setSoles(res.data.data));
    });
  }, []);

  useEffect(() => {
    //
  }, [
    selected,
    selectedBrands,
    selectedColors,
    selectedSoles,
    minPrice,
    maxPrice,
    sort,
    page,
  ]);

  const getSlugToName = (rslug) => {
    if (categories.length == 0) {
      return "";
    }

    if (categories.filter((x) => x.slug == rslug).length > 0) {
      return categories.filter((x) => x.slug == rslug)[0].name;
    } else {
      try {
        return categories
          .filter(
            (x) => x.subGroups.filter((y) => y.slug == rslug).length > 0
          )[0]
          .subGroups.filter((x) => x.slug == rslug)[0].name;
      } catch (error) {
        return "";
      }
    }
  };

  const gotoPrevious = () =>
    currentImageIndex > 0 && dispatch(setCurrentIndex(currentImageIndex - 1));

  const gotoNext = () =>
    currentImageIndex + 1 < products.map((x) => x.image).length &&
    dispatch(setCurrentIndex(currentImageIndex + 1));
  return (
    <>
      {categories.length > 0 ? (
        <Helmet>
          <title>
            Toptan {categories[0].name} Ürünleri Toptan Masa Lambası , bilaloto
            Toptan Lastik
          </title>
          <meta
            name="description"
            content={
              " Toptan " +
              categories[0].name +
              " "
            }
          />
          <meta
            name="og:description"
            content={
              " Toptan " +
              categories[0].name +
              " "
            }
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>
            Toptan Lastik Toptan Masa Lambası , bilaloto Toptan Lastik
          </title>
        </Helmet>
      )}

      <Header />
      <h1 className="analizicin">
        {categories.length > 0 && categories[0].name} Kategori Ürünleri - bilaloto
        Toptan Lastik{" "}
      </h1>
      <div className="category-img-carousel">
        <Carousel
          disableArrowsOnEnd={true}
          pagination={false}
          renderArrow={({ type, onClick, isEdge }) => {
            const pointer = type === consts.PREV ? faAngleLeft : faAngleRight;
            return true ? (
              <div>
                <button
                  onClick={onClick}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    outline: "none",
                    padding: 10,
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 100900,
                    left: type === consts.PREV ? 0 : "unset",
                    right: type === consts.NEXT ? 0 : "unset",
                  }}
                >
                  <FontAwesomeIcon icon={pointer} style={{ fontSize: 30 }} />
                </button>
              </div>
            ) : (
              <></>
            );
          }}
          itemsToShow={width < 1140 ? 3 : width < 1300 ? 4 : 10}
        >
          {categories.map((x) => {
            return x.subGroups.map((item) => {
              return (
                <div
                  key={item.id + item.slug}
                  onClick={() => {
                    if (selected.includes(item.slug)) {
                      dispatch(
                        setSelected(selected.filter((x) => x != item.slug))
                      );
                    } else {
                      dispatch(setSelected([...selected, item.slug]));
                    }
                  }}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundSize: "80%",
                      backgroundRepeat: "no-repeat",
                      border: selected.includes(item.slug)
                        ? "3px solid #F27A1A"
                        : "1px solid #F5F5F5",
                      borderRadius: 1000,
                      background: "#F5F5F5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundPosition: "center",
                      width: 80,
                      height: 80,
                      boxShadow:
                        "0px 0px " +
                        (selected.includes(item.slug) ? "4" : "0") +
                        "px #F27A1A",
                    }}
                  >
                    <span
                      className="brand-name"
                      style={{
                        fontSize: 16,
                        backgroundImage: "url(" + item.image + ")",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        border: "1px solid #F5F5F5",
                        height: "100%",
                        backgroundColor: "#fff",
                        borderRadius: 1000,
                        backgroundSize: "90%",
                        backgroundPosition: "center",
                        color: selected.includes(item.slug)
                          ? "#F27A1A"
                          : "#000",
                        fontWeight: selected.includes(item.slug)
                          ? "bold"
                          : "bold",
                      }}
                    ></span>
                  </div>
                  <span style={{ fontSize: 11 }}>{item.name}</span>
                </div>
              );
            });
          })}
        </Carousel>
      </div>
      <div style={{ width: "100%" }}>
        <div className="filters">
          <div className="left">
            {!open && (
              <div className="filter-btn" onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faSlidersH} /> Filtreler
              </div>
            )}
          </div>
          <div className="right">
            {selected.length > 0 ||
            minPrice != null ||
            maxPrice != null ||
            selectedColors.length > 0 ||
            selectedSoles.length > 0 ? (
              <>
                <div className="filtered-list">
                  <div
                    className="filtered-item all-remover"
                    key={"filtergfsed-x-"}
                    onClick={() => {
                      dispatch(setSelected([]));
                      dispatch(setSelectedColors([]));
                    }}
                  >
                    Filtreyi Temizle
                    <span>x</span>
                  </div>

                
                  {selected.map((x) => {
                    return (
                      <div
                        key={"filtereddfgd-y-" + x}
                        onClick={() => {
                          dispatch(setSelected(selected.filter((y) => y != x)));
                        }}
                        className="filtered-item"
                      >
                        {getSlugToName(x)} <span>x</span>
                      </div>
                    );
                  })}
                  {selectedColors.map((x) => {
                    return (
                      <div
                        key={"filtered-color-sdfgc" + x}
                        onClick={() => {
                          dispatch(
                            setSelectedColors(
                              selectedColors.filter((y) => y != x)
                            )
                          );
                        }}
                        className="filtered-item"
                      >
                        {x}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {selectedSoles.map((x) => {
                    return (
                      <div
                        key={"filtered-sodlfdge-" + x.id}
                        onClick={() => {
                          setSelectedSoles(selectedSoles.filter((y) => y != x));
                        }}
                        className="filtered-item"
                      >
                        {soles.filter((y) => y.id == x).length < 1
                          ? ""
                          : soles
                              .filter((y) => y.id == x)[0]
                              .specialCode.replace(/Taban/g, " ")
                              .replace(/:/g, " ")}{" "}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {minPrice != null && (
                    <div
                      onClick={() => {
                        dispatch(setMinPrice(null));
                      }}
                      className="filtered-item"
                    >
                      En Az {minPrice}₺<span>x</span>
                    </div>
                  )}
                  {maxPrice != null && (
                    <div
                      onClick={() => {
                        dispatch(setMaxPrice(null));
                      }}
                      className="filtered-item"
                    >
                      En Fazla {maxPrice}₺<span>x</span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            <button
              className="sort-btn"
              onClick={() => {
                dispatch(setFilterOpen(!filterOpen));
              }}
            >
              <FontAwesomeIcon
                icon={sort.sortedBy == "DESC" ? faAngleDown : faAngleUp}
                style={{ marginRight: 10 }}
              />{" "}
              {sort.name}
              <div
                className="filter-list"
                style={{ display: filterOpen != true ? "none" : "unset" }}
              >
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "DESC",
                        name: "Yeni Ürünler",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Yeni Ürünler
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "ASC",
                        name: "Eski Ürünler",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Eski Ürünler
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "ASC",
                        name: "Artan Fiyat",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Fiyat
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "DESC",
                        name: "Azalan Fiyat",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Fiyat
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "DESC",
                        name: "Azalan Mevcut",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Mevcut
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "ASC",
                        name: "Artan Mevcut",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Mevcut
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TOPSALES",
                        sortedBy: "DESC",
                        name: "En Çok Satan",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TOPSALES" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  En Çok Satan
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TITLE",
                        sortedBy: "ASC",
                        name: "Ürüne Göre (A-Z)",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "TITLE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Ürüne Göre (A-Z)
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TITLE",
                        sortedBy: "DESC",
                        name: "Ürüne Göre (Z-A)",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TITLE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Ürüne Göre (Z-A)
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "RANDOM",
                        sortedBy: "",
                        name: "Keşfet",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sort == "RANDOM" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Keşfet
                </div>
              </div>
            </button>

            <div className="total">
              {allData != null &&
              allData.currentPage != null &&
              allData.count != null ? (
                <div className="count">
                  {/* {allData.count * allData.currentPage} /{" "} */}
                  {/* {products.length}
                    <strong>/</strong> */}
                  {allData.totalCount} Ürün
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <ProductContent open={open}>
        <div className="filter">
          {width < 1140 && (
            <div className="overlay" onClick={() => setOpen(false)}></div>
          )}
          <div className="categories">
            <div className="category-item head" onClick={() => setOpen(!open)}>
              <span>
                <FontAwesomeIcon icon={faBars} />
              </span>
              <span>Kategoriler</span>
              <span>x</span>
            </div>
            {categories.map((x) => {
              return (
                <CategoryItem
                  slug={slug}
                  dispatch={dispatch}
                  key={"categorydfscv-item-" + x.slug}
                  category={x}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}

        
            <div
              className={"category-item alt-head "}
              onClick={() => {
                dispatch(setColorOpen(!colorOpen));
              }}
              // onClick={() => setOpened(opened == "renkler" ? "" : "renkler")}
            >
              <span
                style={{
                  transition: "600ms all",
                  transform: colorOpen ? "rotateX(180deg)" : "rotateX(0deg)",
                }}
              >
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
              <span>Renk</span>
              <span style={{ fontSize: 11 }} onClick={() => {}}>
                (
                {
                  colors.filter((x) =>
                    x.color
                      .toLocaleLowerCase("tr")
                      .startsWith(colorSearchTxt.toLocaleLowerCase("tr"))
                  ).length
                }
                )
              </span>
            </div>
            <div
              className="search-box category-sub-item"
              style={{ display: colorOpen ? "" : "none" }}
            >
              <input
                type="text"
                value={colorSearchTxt}
                onChange={(e) => dispatch(setColorSearchTxt(e.target.value))}
                placeholder="Renk Ara"
              />
              {colorSearchTxt.length > 0 && (
                <span
                  onClick={() => dispatch(setColorSearchTxt(""))}
                  className="search-trash"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              )}
            </div>
            <div
              className="colors"
              // style={{ display: opened == "renkler" ? "" : "none" }}
              style={{ display: colorOpen ? "" : "none" }}
            >
              {colors
                .filter((x) => selectedColors.includes(x.color))
                .filter((x) =>
                  x.color
                    .toLocaleLowerCase("tr")
                    .startsWith(colorSearchTxt.toLocaleLowerCase("tr"))
                )
                .map((x) => {
                  return (
                    <ColorItem
                      slug={slug}
                      dispatch={dispatch}
                      key={"color-item-dfgh-" + x.color}
                      color={x}
                      selectedColors={selectedColors}
                      setSelectedColors={setSelectedColors}
                      categoryMode={false}
                    />
                  );
                })}
              {colors
                .filter((x) => !selectedColors.includes(x.color))
                .filter((x) =>
                  x.color
                    .toLocaleLowerCase("tr")
                    .startsWith(colorSearchTxt.toLocaleLowerCase("tr"))
                )
                .map((x) => {
                  return (
                    <ColorItem
                      slug={slug}
                      dispatch={dispatch}
                      key={"color-item-dfgh-" + x.color}
                      color={x}
                      selectedColors={selectedColors}
                      setSelectedColors={setSelectedColors}
                      categoryMode={false}
                    />
                  );
                })}
            </div>
          </div>
          <div
            className={
              "category-item alt-head" + (opened == "fiyat" ? " open" : "")
            }
            onClick={() => setOpened(opened == "fiyat" ? "" : "fiyat")}
          >
            <span>₺</span>
            <span>Fiyat Aralığı</span>
            <span style={{ fontSize: 11 }} onClick={() => {}}>
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </div>
          <div
            className="category-item price-sort"
            style={{ display: opened == "fiyat" ? "flex" : "none" }}
          >
            <span className="input">
              <input
                type="number"
                placeholder="Minimum"
                value={minPrice == null ? "" : minPrice}
                onChange={(e) => dispatch(setMinPrice(e.target.value))}
              />
            </span>
            <span className="minus">-</span>
            <span className="input">
              <input
                type="number"
                placeholder="Maximum"
                value={maxPrice == null ? "" : maxPrice}
                onChange={(e) => dispatch(setMaxPrice(e.target.value))}
              />
            </span>
            <span
              onClick={() => {
                getProductReq();
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>
        </div>

        <div className="list">
          <div className="filter-mobil-group">
            <div className="filter-btn mobil" onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={faSlidersH} /> Filtreler
            </div>
            <button
              className="sort-btn-mobil"
              onClick={() => dispatch(setFilterOpen(!filterOpen))}
            >
              <FontAwesomeIcon
                icon={sort.sortedBy == "DESC" ? faAngleDown : faAngleUp}
                style={{ marginRight: 10 }}
              />{" "}
              {sort.name}
              <div
                className="filter-list"
                style={{ display: filterOpen != true ? "none" : "unset" }}
              >
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "RANDOM",
                        sortedBy: "",
                        name: "Keşfet",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sort == "RANDOM" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Keşfet
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "DESC",
                        name: "Yeni Ürünler",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Yeni Ürünler
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "ASC",
                        name: "Eski Ürünler",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Eski Ürünler
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "ASC",
                        name: "Artan Fiyat",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Fiyat
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "DESC",
                        name: "Azalan Fiyat",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Fiyat
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "DESC",
                        name: "Azalan Mevcut",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Mevcut
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "ASC",
                        name: "Artan Mevcut",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Mevcut
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TOPSALES",
                        sortedBy: "DESC",
                        name: "En Çok Satan",
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TOPSALES" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  En Çok Satan
                </div>
              </div>
            </button>
          </div>

          {products.map((x) => {
            return (
              <ProductItem
                key={x.id + x.slug + "asda"}
                product={x}
                setOpenedBox={setOpenedBox}
              />
            );
          })}
          <div style={{ width: "100%", textAlign: "center" }}>
            {allData != null &&
            products.length > 0 &&
            allData.currentPage != null &&
            allData.count != null ? (
              <div className="count">
                {products.length == allData.totalCount ? (
                  <>Toplam {products.length} Ürün</>
                ) : (
                  <>
                    {products.length}
                    <strong
                      style={{
                        marginRight: 5,
                        marginLeft: 5,
                        fontWeight: "bold",
                      }}
                    >
                      /
                    </strong>
                    {allData.totalCount} Ürün
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {allData != null &&
          products.length > 0 &&
          allData.currentPage != null &&
          allData.count != null &&
          allData.totalCount != products.length ? (
            <div
              onClick={() => {
                getNewPage(page + 1);
              }}
              style={{
                color: "#ffff",
                padding: "5px 55px",
                marginBottom: 40,
                width: "100%",
                borderRadius: 100,
                textAlign: "center",
                cursor: "pointer",
                fontSize: 22,
              }}
            >
              {!loading && (
                <div
                  style={{
                    display: "inline-block",
                    background: "#F27A1A",
                    padding: "15px 55px",
                    marginTop: 20,
                    borderRadius: 100,
                    fontSize: 18,
                  }}
                >
                  Daha Fazla Ürün Göster
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && <Sentry color="#FBA800" size={180} />}
          </div>
        </div>
      </ProductContent>
      <div style={{ zIndex: 1009090 }}>
        {products.length > 0 && (
          <Lightbox
            isOpen={openedBox}
            onPrev={gotoPrevious}
            className="lightbox-image-box"
            onNext={gotoNext}
            images={products.map((x) => {
              return { src: x.image, loading: "lazy", alt: "deha" };
            })}
            currentIndex={currentImageIndex}
            onClose={() => dispatch(setOpenedBox(false))}
            style={{ background: "#fff", zIndex: 1009090 }}
            renderNextButton={() => (
              <SwapButton onClick={gotoNext} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleRight} />
              </SwapButton>
            )}
            renderPrevButton={() => (
              <SwapButton onClick={gotoPrevious} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleLeft} />
              </SwapButton>
            )}
            renderFooter={() => (
              <div
                style={{
                  width: "40vw",
                  // background: "rgba(0,0,0,0.5)",
                  height: "40vh",
                  top: "30vh",
                  left: "30vw",
                  position: "fixed",
                  zIndex: 100,
                }}
                onClick={() => {
                  history.push("/urun/" + products[currentImageIndex].slug);
                }}
              ></div>
            )}
            renderHeader={() => (
              <button
                onClick={() => dispatch(setOpenedBox(false))}
                className="light-close"
              >
                X
              </button>
            )}
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
        )}
      </div>
      <BottomNav />
      {/* <Footer /> */}
    </>
  );
}
