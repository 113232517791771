import React,{useState,useRef,useLayoutEffect, useEffect} from 'react'
import Header from '../Navigation/Header'
import BottomNav from '../Navigation/Bottom'
import "react-activity/dist/Sentry.css";
import {ProductList,FilterContainer,ProductListTitle} from '../../styles/Product'
import Title from './Title';
import ProductItem from '../Product/ProductItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faBorderAll, faList, faUser, faArrowAltCircleDown, faArrowDown, faSort, faAngleDown, faAngleUp, faCheck, faSortAmountDown, faSortAmountUp, faTh, faThLarge, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Sentry } from "react-activity";
import CategoryList from './Category/CategoryList';
import { Button } from '@mui/material';
import { useHistory,useLocation } from 'react-router-dom';
import { Menu } from '@headlessui/react'
import BrandList from './BrandList';
import ScrollToTop from 'react-scroll-to-top';
import { ReactComponent as MyTestSvg } from "../../MyTestSvg.svg";
import useWindowDimensions from '../useWindowDimensions';
import Footer from '../Navigation/Footer';




export default function Category({setSort,allData,setAllData,search,setSearch,setSelectedBrand,selectedBrand,slug,getNewProducts,getProducts,getSearchedProducts,products,setProducts,simpleCount,sort,variant=false}) {

  const [loading, setLoading] = useState(true);

  const [grid,setGrid] = useState('grid')
  
  const width=useWindowDimensions().width
  const listInnerRef = useRef();
  

  const [page,setPage]=useState(1)

  const [goTopVisibility,setGoTopVisibility] = useState(false) 

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if ( scrollHeight-(scrollTop + (clientHeight)) <=70  ) {
    
        getNewProducts(page+1,setLoading,search,setPage,sort!=undefined && sort!=null ? sort.sort : '',sort!=undefined && sort!=null ? sort.sortedBy : '')

      }else{
      
      }
      //if(scrollTop>280){
      //  setGoTopVisibility(true)
      //}else{
      //  setGoTopVisibility(false)
      //}

      
    }
  };

  useEffect(()=>{
    if(products.length<1 && loading==false){
      setLoading(true)
      getProducts({
        all: search,
        brandSlug: selectedBrand,
        sort: sort != undefined && sort != null ? sort.sort : "",
        sortedBy: sort != undefined && sort != null ? sort.sortedBy : "",
        count: 100,
        groupSlug: window.location.href.indexOf("/marka") == -1 ? slug : "",
        page: page,
      })
        .then((res) => {
          if (res.data.data.items && res.data.data.items.length < 1) {
            setLoading(false);
          } else {

            setProducts(res.data.data.items);
            setAllData(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response != undefined) {
            if (err.response.data.data == null) {
              setLoading(false);
            }
          }
          console.log(err.response);
        });
    }
  },[products])

  useEffect(()=>{
    if(products.length<1){
      return
    }
    setAllData([]);
    setPage(1);
    setProducts([]);
    getProducts({
      all: search,
      brandSlug: selectedBrand,
      sort: sort != undefined && sort != null ? sort.sort : "",
      sortedBy: sort != undefined && sort != null ? sort.sortedBy : "",
      count: 100,
      groupSlug: window.location.href.indexOf("/marka") == -1 ? slug : "",
      page: 1,
    })
      .then((res) => {
        if (res.data.data.items && res.data.data.items.length < 1) {
          setLoading(false);
        } else {
          setProducts(res.data.data.items);
          setAllData(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (err.response.data.data == null) {
            setLoading(false);
          }
        }
        console.log(err.response);
      });
    

  },[selectedBrand])

  useEffect(()=>{
    setProducts([])
    setAllData([])
    setPage(1)
    
    setTimeout(() => {
      window.scrollTo(0, 100);
    }, 2000);
  },[slug])

  const handleSearch=(e)=>{
    setSearch(e.target.value)
  }

  useEffect(()=>{
    if(search.length>0){
      setAllData([])
      setProducts([]);
      getSearchedProducts(setLoading,setPage,search,sort!=undefined && sort!=null ? sort.sort : '',sort!=undefined && sort!=null ? sort.sortedBy : '')
    }else{
      setPage(1)
      setAllData([]);
      setProducts([])
      getNewProducts(1,setLoading,search,setPage,sort!=undefined && sort!=null ? sort.sort : '',sort!=undefined && sort!=null ? sort.sortedBy : '')
    }
  },[search,selectedBrand])

  useEffect(()=>{
    if(sort!=undefined && sort!=null && loading!=true ){
      setAllData([]);
      setPage(1);
      setProducts([])
      getProducts(1,setLoading,search,setPage)
    }
  },[sort])
  

  const [documentTitle, setDocumentTitle] = useState("Ürün Listesi");
  const [size, setSize] = useState(
    localStorage.getItem("size") != null
      ? localStorage.getItem("size") * 1
      : 100
  );

  React.useEffect(() => {
    var interval;

    const handleVisibilityChange = () => {
      if(!document.hidden){
        setDocumentTitle("Ürün Listesi");
        clearInterval(interval);
        document.title="Ürün Listesi"
        return;
      }
      interval=setInterval(() => {
        document.title= document.hidden ? document.title=="ÜRÜN LİSTESİNE"?"GERİ DÖN":"ÜRÜN LİSTESİNE" : "Ürün Listesi";
      }, 100);
    };

    // Set the initial title
    document.title = document.hidden ? "ÜRÜN LİSTESİNE GERİ DÖN" : "Ürün Listesi";

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [slug]);

  const history=useHistory()
  const location=useLocation()
  const [filterOpen,setFilterOpen]=useState(false)
  React.useEffect(() => {
    localStorage.setItem("size", size + "");
  }, [size]);
  return (
    <div className={"unlimited-scroll" + (variant == false ? "" : " variant")}>
      {variant == false && <Header />}
      {window.location.href.indexOf("search/") == -1 && variant == false ? (
        <BrandList
          slugPrefix={"/kategori/"}
          slug={slug}
          setSelectedBrand={setSelectedBrand}
          selectedBrand={selectedBrand}
        />
      ) : (
        <></>
      )}

      {location.pathname != "/yeni-urunler" && variant == false ? (
        <CategoryList
          slugPrefix={"/kategori/"}
          slug={slug}
          setSelectedBrand={setSelectedBrand}
          selectedBrand={selectedBrand}
        />
      ) : (
        <></>
      )}

      <div className={"content-container "}>
        <div className="productList-parent">
          {variant == false ? (
            <>
              <div className="filter-buttons">
                <div className="filters">
                  <div
                    onClick={() =>
                      setSort({
                        sort: "DATE",
                        sortedBy:
                          sort.sort == "DATE"
                            ? sort.sortedBy == "DESC"
                              ? "ASC"
                              : "DESC"
                            : "DESC",
                      })
                    }
                  >
                    <span>
                      {sort.sort == "DATE"
                        ? sort.sortedBy == "DESC"
                          ? "Yeni"
                          : "Eski"
                        : "Yeni"}{" "}
                      Ürünler
                    </span>
                    {sort != null && sort.sort == "DATE" ? (
                      <span className="icon">
                        <FontAwesomeIcon
                          icon={
                            sort.sortedBy == "DESC" ? faAngleDown : faAngleUp
                          }
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div
                    onClick={() =>
                      setSort({
                        sort: "PRICE",
                        sortedBy:
                          sort.sort == "PRICE"
                            ? sort.sortedBy == "DESC"
                              ? "ASC"
                              : "DESC"
                            : "DESC",
                      })
                    }
                  >
                    <span>
                      Fiyata Göre
                      {sort.sort == "PRICE"
                        ? sort.sortedBy == "DESC"
                          ? "(Azalan)"
                          : "(Artan)"
                        : ""}
                    </span>
                    {sort != null && sort.sort == "PRICE" ? (
                      <span className="icon">
                        <FontAwesomeIcon
                          icon={
                            sort.sortedBy == "DESC" ? faAngleDown : faAngleUp
                          }
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    onClick={() =>
                      setSort({
                        sort: "STOCK",
                        sortedBy:
                          sort.sort == "STOCK"
                            ? sort.sortedBy == "DESC"
                              ? "ASC"
                              : "DESC"
                            : "DESC",
                      })
                    }
                  >
                    <span>
                      Mevcuta Göre
                      {sort.sort == "STOCK"
                        ? sort.sortedBy == "DESC"
                          ? "(Azalan)"
                          : "(Artan)"
                        : ""}
                    </span>
                    {sort != null && sort.sort == "STOCK" ? (
                      <span className="icon">
                        <FontAwesomeIcon
                          icon={
                            sort.sortedBy == "DESC" ? faAngleDown : faAngleUp
                          }
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div
                    onClick={() =>
                      setSort({
                        sort: "TOPSALES",
                        sortedBy:
                          sort.sort == "TOPSALES"
                            ? sort.sortedBy == "DESC"
                              ? "ASC"
                              : "DESC"
                            : "DESC",
                      })
                    }
                  >
                    <span>
                      En Çok Satan
                      {sort.sort == "TOPSALES"
                        ? sort.sortedBy == "DESC"
                          ? "(Azalan)"
                          : "(Artan)"
                        : ""}
                    </span>
                    {sort != null && sort.sort == "TOPSALES" ? (
                      <span className="icon">
                        <FontAwesomeIcon
                          icon={
                            sort.sortedBy == "DESC" ? faAngleDown : faAngleUp
                          }
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="filters-mobile">
                  <button onClick={() => setFilterOpen(!filterOpen)}>
                    <FontAwesomeIcon
                      icon={sort.sortedBy == "DESC" ? faAngleDown : faAngleUp}
                      style={{ marginRight: 10 }}
                    />{" "}
                    {sort.name}
                    <div
                      className="mobile-filter-list"
                      style={{ display: filterOpen != true ? "none" : "unset" }}
                    >
                      <div
                        onClick={() =>
                          setSort({
                            sort: "RANDOM",
                            sortedBy: "",
                            name: "Keşfet",
                          })
                        }
                        className="item"
                      >
                        {sort.sort == "RANDOM" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        Keşfet
                      </div>
                      <div
                        onClick={() =>
                          setSort({
                            sort: "DATE",
                            sortedBy: "DESC",
                            name: "Yeni Ürünler",
                          })
                        }
                        className="item"
                      >
                        {sort.sortedBy == "DESC" && sort.sort == "DATE" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        Yeni Ürünler
                      </div>
                      <div
                        onClick={() =>
                          setSort({
                            sort: "DATE",
                            sortedBy: "ASC",
                            name: "Eski Ürünler",
                          })
                        }
                        className="item"
                      >
                        {sort.sortedBy == "ASC" && sort.sort == "DATE" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        Eski Ürünler
                      </div>
                      <div
                        onClick={() =>
                          setSort({
                            sort: "PRICE",
                            sortedBy: "ASC",
                            name: "Artan Fiyat",
                          })
                        }
                        className="item"
                      >
                        {sort.sortedBy == "ASC" && sort.sort == "PRICE" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        Artan Fiyat
                      </div>
                      <div
                        onClick={() =>
                          setSort({
                            sort: "PRICE",
                            sortedBy: "DESC",
                            name: "Azalan Fiyat",
                          })
                        }
                        className="item"
                      >
                        {sort.sortedBy == "DESC" && sort.sort == "PRICE" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        Azalan Fiyat
                      </div>
                      <div
                        onClick={() =>
                          setSort({
                            sort: "STOCK",
                            sortedBy: "DESC",
                            name: "Azalan Mevcut",
                          })
                        }
                        className="item"
                      >
                        {sort.sortedBy == "DESC" && sort.sort == "STOCK" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        Azalan Mevcut
                      </div>
                      <div
                        onClick={() =>
                          setSort({
                            sort: "STOCK",
                            sortedBy: "ASC",
                            name: "Artan Mevcut",
                          })
                        }
                        className="item"
                      >
                        {sort.sortedBy == "ASC" && sort.sort == "STOCK" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        Artan Mevcut
                      </div>
                      <div
                        onClick={() =>
                          setSort({
                            sort: "TOPSALES",
                            sortedBy: "DESC",
                            name: "En Çok Satan",
                          })
                        }
                        className="item"
                      >
                        {sort.sortedBy == "DESC" && sort.sort == "TOPSALES" ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        En Çok Satan
                      </div>
                    </div>
                  </button>
                </div>
                <div
                  className="grids"
                  style={{
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 15,
                  }}
                >
                  {width < 1140 ? (
                    <div
                      style={{ paddingLeft: 0, marginRight: -30, zIndex: 10 }}
                    >
                      <FontAwesomeIcon
                        onClick={() =>
                          setGrid(grid == "list" ? "grid" : "list")
                        }
                        icon={grid == "list" ? faList : faThLarge}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          width: 100,
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          backgroundColor: "#eee",
                          paddig: "5px 10px",
                          borderRadius: 5,
                          userSelect: "none",
                          padding: 5,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          onClick={() =>
                            setSize(
                              size - 20 > 0 ? size - (size > 140 ? 70 : 20) : 20
                            )
                          }
                          style={{ cursor: "pointer" }}
                        />
                        {/* {size} */}
                        <FontAwesomeIcon
                          icon={faPlus}
                          onClick={() => setSize(size + (size > 140 ? 70 : 30))}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </>
                  )}
                  <div>
                    {allData != null &&
                    allData.currentPage != null &&
                    allData.count != null ? (
                      <div className="count" style={{ fontSize: 12 }}>
                        {/* {allData.count * allData.currentPage} /{" "} */}
                        {allData.totalCount} Ürün
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <ProductList
            width={width}
            size={variant == false ? size : 100}
            ref={listInnerRef}
            className={` product-${grid}`}
          >
            {/*  */}
      
            {products.map((product, index) => {
              return (
                <React.Fragment
                  key={
                    "key-item-isdf" +
                    Math.floor(Math.random() * 10100) +
                    (product.id + "id") +
                    product.slug
                  }
                >
                  <ProductItem
                    product={product}
                    grid={grid}
                    variant={variant}
                  />
                </React.Fragment>
              );
            })}
            {allData != null &&
            allData.currentPage != null &&
            variant == false &&
            allData.count != null ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Toplam {allData.currentPage * allData.count} /{" "}
                {allData.totalCount} Ürün
              </div>
            ) : (
              <></>
            )}
            {variant == false && (
              <Title
                text={
                  <>
                    {loading ? (
                      <>{/* <Sentry color={"#a30711"} size={130} /> */}</>
                    ) : products.length > 0 ? (
                      <div
                        onClick={onScroll}
                        style={{
                          color: "#000",
                          cursor: "pointer",
                          fontSize: 22,
                        }}
                      ></div>
                    ) : (
                      <div
                        style={{
                          color: "#000",
                          cursor: "pointer",
                          fontSize: 22,
                        }}
                      >
                        Ürün bulunamadı
                      </div>
                    )}
                  </>
                }
              />
            )}
            {variant == false ? (
              <div
                onClick={onScroll}
                style={{
                  color: "#ffff",
                  padding: "5px 55px",
                  marginBottom: 40,
                  borderRadius: 100,
                  background: "#F27A1A",
                  textAlign: "center",
                  cursor: "pointer",
                  fontSize: 22,
                }}
              >
                {loading ? <Sentry /> : <>Daha Fazla Ürün Göster</>}
              </div>
            ) : loading == true ? (
              <Sentry size={250} color="#F27A1A" />
            ) : (
              <></>
            )}
          </ProductList>
        </div>
      </div>

      {variant == false && <BottomNav />}
      {variant == false && <Footer />}
    </div>
  );
}
