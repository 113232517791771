import { Button } from '@mui/material'
import React,{useEffect, useState} from 'react'
import { DetailContainer, SwapButton } from "../../styles/Product";
import ProductImage from '../Slider/ProductImage'
import { useCartRequests } from '../../helpers/CartRequests';
import { setBasket } from '../stores/Cart';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrash, faTrashAlt, faShoppingCart, faArrowRight, faArrowLeft, faHome, faSignInAlt, faCartPlus, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios'
import { Helmet } from 'react-helmet';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';
import { Currency } from "react-intl-number-format/dist/index.js";
import useWindowDimensions from '../useWindowDimensions';
import { useLocation } from 'react-router-dom';


export default function ProductDetail({data,language}) {
  const history=useHistory()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data])
  
  const [productImages, setProductImages] = useState(
    data.images ?
      data.images.map((x) => {
        return { src: x, loading: "lazy", alt: "deha" };
      })
  :[]);

  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [openedBox, setOpenedBox] = useState(false);

  const gotoPrevious = () =>
      currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
      currentImageIndex + 1 < productImages.length &&
      setCurrentIndex(currentImageIndex + 1);
      

  const { basket } = useSelector((state) => state.cart);
  const [count,setCount]=useState(1)
  const [mobileCount,setMobileCount]=useState(basket!=undefined && basket!=null ? (((basket.filter((x) => x.productId == data.id)).length>0 ? basket.filter((x) => x.productId == data.id)[0].quantity : 1)) :1)
  const [variantDetail,setVariantDetail]=useState(false)
  const [currency,setCurrency]=useState(null)
  const dispatch=useDispatch()

  const { addCart, getCart, extractionCart,incrementCart,decrementCart } = useCartRequests();
  const width=useWindowDimensions().width
console.log('MC',mobileCount)
  const getBasket=()=>{
    getCart().then(res=>{
      dispatch(setBasket(res.data.cart))
    })
  }
  
  const increment = async (ct=count) => {
    if (!data.inStock) {
      //return;
    }
    addCart([{ id: data.id, count: ct }])
      .then((response) => {
        getBasket();
        toast.success(response.data.message, {
          position:
            width > 1140
              ? toast.POSITION.BOTTOM_RIGHT
              : toast.POSITION.TOP_RIGHT,
          closeOnClick: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: false,
        });
        setCount(1);
      })
      .catch((error) => {
        if (error.response != undefined) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            closeOnClick: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            draggable: false,
          });
        }
      });
  };
  
  const handleChange=async(e)=>{
    setCount(e.target.value)
  }

  const getCurrency=()=>{
    axios({url:'https://finans.truncgil.com/v3/today.json',headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }}).then(res=>{
      setCurrency(res.data)
    })
  }
  
  useEffect(()=>{
    if(currency==null){
      getCurrency()
    }
  },[currency])

  useEffect(()=>{
    setProductImages(data.images.map(x=>{
              return {src:x,loading:'lazy',alt:'deha'}
            }))
    setCurrentIndex(0)
    setCount(1)
    
  },[data])

  const removeItem = async(id) => {
    const newbasket = await basket.filter((sub) => {
        return sub.productId !== id;
      });
      extractionCart(id)
        .then((response) => {
          dispatch(setBasket(newbasket));
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(error.response);
        });
  }

  const incrementItem = async() => {
    const newbasket = await basket.map((sub) => {
      if (sub.id === data.id) {
        return {
          ...sub,
          quantity: sub.quantity + 1,
          total: sub.total + sub.price * 1,
        };
      }
      return sub;
    });

    incrementCart(data.id, 1)
      .then((response) => {
        dispatch(setBasket(newbasket));
        setMobileCount(mobileCount + 1);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.message);
      });
  }
  const decrementItem = async() => {
    const newbasket = await basket.map((sub) => {
      if (sub.id === data.id) {
        return {
          ...sub,
          quantity: sub.quantity - 1,
          total: sub.total - sub.price * 1,
        };
      }
      return sub;
    });
    decrementCart(data.id, 1)
      .then((response) => {
        dispatch(setBasket(newbasket));
        setMobileCount(mobileCount - 1);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.response);
      });
  }
  const location=useLocation()  
  return (
    <DetailContainer>
      <Helmet>
        <title>
          {" "}
          {language.lang != "tr" && data.title2 != null && data.title2 != ""
            ? data.title2
            : data.title}
          - bilaloto
        </title>
        <link
          rel="canonical"
          href={"https://b2b.bilalotomotiv.com/urun/" + data.slug}
        />
        <meta property="og:type" content="website" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta
          property="og:url"
          content={"https://b2b.bilalotomotiv.com/urun/" + data.slug}
        />
        <meta
          property="og:title"
          content={
            language.lang != "tr" && data.title2 != null && data.title2 != ""
              ? data.title2
              : data.title + " - bilaloto"
          }
        />
        <meta
          property="twitter:title"
          content={
            language.lang != "tr" && data.title2 != null && data.title2 != ""
              ? data.title2
              : data.title + " - bilaloto"
          }
        />
        <meta
          property="og:description"
          content={
            language.lang != "tr" && data.title2 != null && data.title2 != ""
              ? data.title2
              : data.title +
                " , " +
                data.description3 +
                " , " +
                data.description
          }
        />
        <meta
          property="twitter:description"
          content={
            language.lang != "tr" && data.title2 != null && data.title2 != ""
              ? data.title2
              : data.title +
                " , " +
                data.description3 +
                " , " +
                data.description
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="keywords"
          content={
            "" + language.lang != "tr" &&
            data.title2 != null &&
            data.title2 != ""
              ? data.title2
              : data.title +
                ", " +
                data.brand +
                ", " +
                data.group +
                ", " +
                data.subGroup +
                ", " +
                data.subSubGroup +
                ", " +
                data.description3 +
                ", " +
                data.description +
                ", bilaloto"
          }
        />
        <meta property="og:image" content={data.images[0]} />
        <meta property="twitter:image" content={data.images[0]} />
      </Helmet>
      <div className="detail-header">
        <div
          className="go-back-btn"
          onClick={() => {
            if (
              location.state &&
              location.state.prevPage != undefined &&
              location.state.prevPage != null
            ) {
              history.push(location.state.prevPage, { back: true });
            } else {
              history.goBack();
            }
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          <span>{language.goPrevious}</span>
        </div>
        {/* <div className="detail-nav">
          <Link to={"/kategori/" + data.groupSlug}>{data.group}</Link>/
          <Link to={"/kategori/" + data.subGroupSlug}>{data.subGroup}</Link>/
          <Link to={"/kategori/" + data.subSubGroupSlug}>
            {data.subSubGroup}
          </Link>
        </div> */}

        <div className="title">
          {/* <h1 style={{ color: "#000" }}>{data.barcode}</h1> */}
        </div>
      </div>

      <div className="product">
        <div className="product-image">
        <span  className='stock-qty'>
  {data.availableStock == 0 ? language.outOfStock : data.availableStock + " " + language.qty}
</span>

          <Lightbox
            isOpen={openedBox}
            onPrev={gotoPrevious}
            className="lightbox-image-box"
            onNext={gotoNext}
            images={productImages}
            currentIndex={currentImageIndex}
            onClose={() => setOpenedBox(false)}
            style={{ background: "#fff" }}
            renderNextButton={() => (
              <SwapButton onClick={gotoNext} className="image-swap-button">
                <FontAwesomeIcon icon={faArrowRight} />
              </SwapButton>
            )}
            renderPrevButton={() => (
              <SwapButton onClick={gotoPrevious} className="image-swap-button">
                <FontAwesomeIcon icon={faArrowLeft} />
              </SwapButton>
            )}
            renderHeader={() => (
              <button
                onClick={() => setOpenedBox(false)}
                className="light-close"
              >
                X
              </button>
            )}
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
          <ProductImage
            openedBox={openedBox}
            setOpenedBox={setOpenedBox}
            setCurrentIndex={setCurrentIndex}
            currentImageIndex={currentImageIndex}
            items={data.images}
          />
          {data.description3 != null ? (
            <div className="product-asorti">
              <div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 30,
                    alignItems: "center",
                    background: "rgba(02,0,0,.1)",
                    padding: 10,
                    width:
                      200 +
                      data.description3.replace("Asorti:", "").split(" ")
                        .length *
                        50,
                  }}
                >
                  <div style={{ width: 100, textAlign: "center" }}>BEDEN</div>
                  {data.description3
                    .replace("Asorti:", "")
                    .split(" ")
                    .map((x) => {
                      return (
                        <div style={{ width: 50, textAlign: "center" }}>
                          <span>{x.split(":")[0]}</span>
                        </div>
                      );
                    })}
                  <div style={{ width: 100, textAlign: "center" }}>TOPLAM</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    background: "rgba(02,0,0,.1)",
                    padding: 10,
                    width:
                      200 +
                      data.description3.replace("Asorti:", "").split(" ")
                        .length *
                        50,
                  }}
                >
                  <div style={{ width: 100, textAlign: "center" }}>ADET</div>
                  {data.description3
                    .replace("Asorti:", "")
                    .split(" ")
                    .map((x) => {
                      return (
                        <div style={{ width: 50, textAlign: "center" }}>
                          <span>{x.split(":")[1]}</span>
                        </div>
                      );
                    })}
                  <div style={{ width: 100, textAlign: "center" }}>
                    {data.unit2coefficient}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className={"product-store " + (data.inStock == false ? "" : "")}>
          {/* <span className="varitants variant-mobile" style={{ fontSize: 15 }}>
            {" "}
            Renkler{" "}
          </span>
          <div className="varitants variant-mobile">
            {data.variantProducts &&
              data.variantProducts
                .filter((x) => x.image != null && x.image != "")
                .slice(0, variantDetail ? data.variantProducts.length : 5)
                .map((x) => {
                  return (
                    <div
                      onClick={() => history.push("/urun/" + x.slug)}
                      key={x.slug + "ded"}
                      className={
                        "variant-item " + (data.slug == x.slug ? "active" : "")
                      }
                    >
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${x.image})` }}
                      ></div>
                    </div>
                  );
                })}

            {data.variantProducts?.length > 5 && !variantDetail ? (
              <div className="variant-item">
                <div
                  onClick={() => {
                    setVariantDetail(true);
                  }}
                  className="image"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: 30 }}>
                    +{data.variantProducts.length - 5}
                  </span>
                  <span style={{ fontSize: 14 }}>Renk Daha</span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div> */}

          {/* <div className="product-stock">{data.stockText}</div> */}

          <div className="product-title">
            {language.lang != "tr" && data.title2 != null && data.title2 != ""
              ? data.title2
              : data.title}
          </div>
          <div
            className="product-brand"
            onClick={() => history.push("/marka/" + data.brandSlug)}
          ></div>

          <div className="product-informations">
            {/* <div className="info-item">
              <div className="title">Bilgi Kodu</div>
              <div className="desc">{data.id}</div>
            </div> */}

            <div className="info-item">
              <div className="title">{language.stockcode}</div>
              <div className="desc">{data.id}</div>
            </div>
            <div className="info-item">
              <div className="title">Barkodu</div>
              <div className="desc">{data.barcode}</div>
            </div>

            {/* <div className="info-item">
              <div className="title">{language.stockCount}</div>
              <div className="desc">{data.availableStock}</div>
            </div> */}

            {/* <div className="info-item">
              <div className="title">{language.color}</div>
              <div className="desc">{data.color}</div>
            </div> */}

            <div className="info-item">
              <div className="title">{language.category}</div>
              <div
                className="desc"
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "#ff9f43",
                }}
                onClick={() => {
                  history.push("/kategori/" + data.subGroupSlug);
                }}
              >
                {data.subGroup}{" "}
                {data.subSubGroup != "" ? " - " + data.subSubGroup : ""}
              </div>
            </div>

            {/* <div className="info-item">
              <div className="title">Tür</div>
              <div className="desc">
                {data.description4 != null
                  ? data.description4.replace("Taban:", "")
                  : ""}
              </div>
            </div> */}
            {/* <div className="info-item">
              <div className="title">Mevcut</div>
              <div className="desc">
                {data.availableStock} 
              </div>
            </div> */}
          </div>

          <div className="varitants variant-web">
            {data.variantProducts &&
              data.variantProducts
                .filter((x) => x.image != null && x.image != "")
                .slice(0, variantDetail ? data.variantProducts.length : 5)
                .map((x) => {
                  return (
                    <div
                      onClick={() => history.push("/urun/" + x.slug)}
                      key={x.slug + "ded"}
                      className={
                        "variant-item " + (data.slug == x.slug ? "active" : "")
                      }
                    >
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${x.image})` }}
                      ></div>
                    </div>
                  );
                })}

            {data.variantProducts?.length > 5 && !variantDetail ? (
              <div className="variant-item">
                <div
                  onClick={() => {
                    setVariantDetail(true);
                  }}
                  className="image"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontSize: 30 }}>
                    +{data.variantProducts.length - 5}
                  </span>
                  <span style={{ fontSize: 14 }}></span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* <div className="price-list">
            <div className="product-price adet">
              ADET <span style={{ marginRight: 15 }}></span>
              <Currency>{data.price}</Currency>
            </div>

            <div className="product-price" style={{ marginLeft: 20 }}>
              <Currency>{data.salePrice * count}</Currency>
              <span
                style={{ fontWeight: "normal", fontSize: 15, marginLeft: 10 }}
              >
                + KDV
              </span>
            </div>
          </div> */}

          <div
            className="price-box"
            // style={{  }}
          >
                    {localStorage.getItem("bilalototoken") != null ? (
                      <>
                      {data.listPrice !== data.salePrice ? (
          <div>
            <div className="counter-top-price">
              <span>{data.unit}</span>
              <div className="listPrice">
                <Currency>{data.listPrice}</Currency>
              </div>
            </div>
            <div className="counter-bottom-price">
              <span>{language.specialForYou}</span>
              <div className="salePrice">
                <Currency>{data.salePrice}</Currency>
              </div>
            </div>
          </div>
        ) : (
          <div className="counter-top-price">
            <span>{data.unit}</span>
            <Currency>{data.price}</Currency>
            <div style={{ marginLeft: 10, fontSize: 12 }}>
              {language.plusVat}
            </div>
          </div>
        )}
                        

                <div style={{ width: "100%", textAlign: "center" }}>
                  {count * data.unit2coefficient} {data.unit}
                </div>
                <div className="counter">
                  <Button
                    variant="contain"
                    onClick={() =>
                      setCount(count * 1 - 1 < 1 ? 1 : count * 1 - 1)
                    }
                    className={`buttons ${data.quantity == 1 ? "delete" : ""}`}
                  >
                    <FontAwesomeIcon
                      icon={data.quantity == 1 ? faTrashAlt : faMinus}
                    />{" "}
                  </Button>
                  <div className="input">
                    <input type="text" onChange={handleChange} value={count} />
                    <span>{data.unit2}</span>
                  </div>
                  <Button
                    variant="contain"
                    // disabled={
                    //   count >= data.availableStock / data.unit2coefficient
                    //     ? true
                    //     : false
                    // }
                    onClick={() => setCount(count * 1 + 1)}
                    className="buttons"
                  >
                    <FontAwesomeIcon icon={faPlus} />{" "}
                  </Button>
                </div>

                <div className="add-basket">
                  <Button onClick={() => increment(count)} variant="contain">
                    {" "}
                    <span>
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </span>{" "}
                    <span style={{ fontSize: 14 }}>{language.addBasket}</span>
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div style={{ fontSize: 13, marginBottom: 10 }}>
                  Fiyatları görmek ve sipariş vermek için{" "}
                </div>
                <div className="add-basket">
                  <Button
                    onClick={() => history.push("/giris-yap")}
                    variant="contain"
                  >
                    {" "}
                    <span>
                      <FontAwesomeIcon icon={faSignInAlt} />
                    </span>{" "}
                    <span style={{ fontSize: 14 }}>{language.login}</span>
                  </Button>
                </div>
              </>
            )}
          </div>
          <div
            className="mobile-price-box"
            // style={{  }}
          >
            {localStorage.getItem("bilalototoken") != null ? (
              <>
                <div className="prices">
                  {/* <div className="simple-price">
                    <span>Adet</span>
                    <span>
                      <Currency>{data.price}</Currency>
                    </span>
                  </div>

          
                  <div className="simple-price">
                    <span>KDV Dahil(x{mobileCount})</span>
                    
                    <span>
                      {" "}
                      <Currency>{data.salePrice * mobileCount}</Currency>
                    </span>
                  </div> */}

                {data.listPrice !== data.salePrice ? (
                  <div>
                    <div className="simple-price">
                      <span>Adet</span>
                      <span style={{ textDecoration: "line-through", color: "red" }}>
                        <Currency>{data.listPrice}</Currency>
                      </span>
                    </div>
                    <div className="simple-price">
                      <span>{language.specialForYou}</span>
                      <span>
                        <Currency>{data.salePrice}</Currency>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="simple-price">
                    <span>Adet</span>
                    <span>
                      <Currency>{data.salePrice}</Currency>
                    </span>
                  </div>
                )}
                </div>
                <div className="mobile-action">
                  {basket != null &&
                  basket != undefined &&
                  basket.length > 0 &&
                  basket.filter((x) => x.productId == data.id).length != 0 ? (
                    <>
                      <Button
                        variant="contain"
                        onClick={() => {
                          if (mobileCount == 1) {
                            removeItem(data.id);
                          } else {
                            decrementItem();
                          }
                        }}
                        className={`buttons ${
                          mobileCount == 1 ? "delete" : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={mobileCount == 1 ? faTrashAlt : faMinus}
                        />{" "}
                      </Button>
                      <div className="input">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={mobileCount}
                        />
                        {/* <span>{data.unit2}</span> */}
                      </div>
                      <Button
                        variant="contain"
                        // disabled={
                        //   mobileCount >=
                        //   data.availableStock / data.unit2coefficient
                        //     ? true
                        //     : false
                        // }
                        onClick={() => incrementItem()}
                        className="buttons"
                      >
                        <FontAwesomeIcon icon={faPlus} />{" "}
                      </Button>
                    </>
                  ) : (
                    <div className="mobile-add">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          increment(mobileCount);
                        }}
                      >
                        <FontAwesomeIcon icon={faCartPlus} />
                        <span>{language.addBasket}</span>
                      </button>
                    </div>
                  )}
                </div>

                {/* <div style={{ width: "100%", textAlign: "center" }}>
                  {count * data.unit2coefficient} {" Çift "}
                </div> */}
                {/* <div className="mobile-counter">
                  <Button
                    variant="contain"
                    onClick={() =>
                      setCount(count * 1 - 1 < 1 ? 1 : count * 1 - 1)
                    }
                    className={`buttons ${data.quantity == 1 ? "delete" : ""}`}
                  >
                    <FontAwesomeIcon
                      icon={data.quantity == 1 ? faTrashAlt : faMinus}
                    />{" "}
                  </Button>
                  <div className="input">
                    <input type="text" onChange={handleChange} value={count} />
                    <span>{data.unit2}</span>
                  </div>
                  <Button
                    variant="contain"
                    disabled={
                      count >= data.availableStock / data.unit2coefficient
                        ? true
                        : false
                    }
                    onClick={() => setCount(count * 1 + 1)}
                    className="buttons"
                  >
                    <FontAwesomeIcon icon={faPlus} />{" "}
                  </Button>
                </div>

                <div className="add-basket">
                  <Button onClick={increment} variant="contain">
                    {" "}
                    <span>
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </span>{" "}
                    <span style={{ fontSize: 14 }}>{language.addBasket}</span>
                  </Button>
                </div> */}
              </>
            ) : (
              <>
                <div style={{ fontSize: 13, marginBottom: 10 }}>
                  Fiyatları görmek ve sipariş vermek için{" "}
                </div>
                <div className="add-basket">
                  <Button
                    onClick={() => history.push("/giris-yap")}
                    variant="contain"
                  >
                    {" "}
                    <span>
                      <FontAwesomeIcon icon={faSignInAlt} />
                    </span>{" "}
                    <span style={{ fontSize: 14 }}>{language.login}</span>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="product-information">
        <h3>
          {language.product} {language.description}
        </h3>
        {(language.lang != "tr" &&
        data.specialDescription2 != null &&
        data.specialDescription2 != ""
          ? data.specialDescription2
          : data.specialDescription
        )
          ?.split("\n")
          .map((x) => {
            return <div className="desc">{x}</div>;
          })}
        {/* <div
          dangerouslySetInnerHTML={{
            __html: (data.eTradeDescription || "")
              .replace("<[OZELLIK]>", "")
              .replace("<[ACIKLAMA]>", "")
              .replace("<[TARIF]>", "")
              .replace("<[SURELER]>", ""),
          }}
        /> */}

        <p>
          {/* {data.description}. {data.description2}. {data.description3}.{" "}
          {data.description4} */}
        </p>

        <div className="property"></div>
      </div>
    </DetailContainer>
  );
}
