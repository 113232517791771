import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { BrandList } from "../styles/Product";
import { Link } from "react-router-dom";
import { useProductRequests } from "../helpers/ProductRequests";
import { Helmet } from "react-helmet";
import Header from "../components/Navigation/Header";
import BottomNav from "../components/Navigation/Bottom";
import Footer from "../components/Navigation/Footer";
import { Sentry } from "react-activity";



export default function Home() {
  const { getBrands } = useProductRequests();
  const [brands, setBrands] = useState([]);
  const [value,setValue] = useState([0,100]);
  const [selectedLetters, setSelectedLetters] = useState(["A", "Z"]);
  const [alphabet, setAlphabet] = useState("");

  useEffect(() => {
    if (brands.length === 0) {
      getBrands()
        .then((response) => {
          setBrands(response.data.data);
          setAlphabet(
            Array.from(
              new Set(response.data.data.map((brand) => brand.name[0]))
            ).join("")
          );
        })
        .catch((error) => {});
        
    }
  }, [brands]);

  const handleChange = (_, newValue) => {
    const selectedStartIndex = Math.floor(
      (newValue[0] / 100) * alphabet.length
    );
    const selectedEndIndex = Math.ceil((newValue[1] / 100) * alphabet.length);

    const startLetter = alphabet[selectedStartIndex];
    const endLetter = alphabet[selectedEndIndex - 1];

    setSelectedLetters([startLetter==undefined ? endLetter : startLetter, endLetter==undefined ? startLetter : endLetter]);
    setValue(newValue);
    
  };

  

  return (
    <>
      <Helmet>
        <title>Markalar</title>
      </Helmet>
      <Header />
      <div style={{ marginTop: 50, marginBottom: 20, fontSize: 40 }}>
        <center>Markalarimiz</center>
      </div>
      <Box
        sx={{
          width: "calc(90%)",
          marginLeft: "5%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        style={{ display: "flex" }}
      >
        <div style={{ width: 40, fontSize: 30 }}>{selectedLetters[0]}</div>
        <Slider
          style={{ width: "calc(100% - 80px)" }}
          value={value}
          onChange={handleChange}
          disableSwap
        />
        <div style={{ width: 40, textAlign: "right", fontSize: 30 }}>
          {selectedLetters[1]}
        </div>
      </Box>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          paddingRight: 10,
          paddingLeft: 10,
          width: "100%",
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Typography
          onClick={() => {
            setSelectedLetters([alphabet[0], alphabet[alphabet.length - 1]]);
            setValue([0, 100]);
          }}
          sx={{
            display: "inline-block",
            cursor: "pointer",
            padding: "5px 15px",
            textAlign: "center",
            fontSize: 20,
            color:
              selectedLetters[0] == alphabet[0] &&
              selectedLetters[1] == alphabet[alphabet.length - 1]
                ? "black"
                : "gray",
            backgroundColor:
              selectedLetters[0] == alphabet[0] &&
              selectedLetters[1] == alphabet[alphabet.length - 1]
                ? "orange"
                : "transparent",
          }}
        >
          HEPSİ
        </Typography>
        {alphabet.split("").map((x) => (
          <Typography
            key={x}
            onClick={() => {
              setSelectedLetters([x, x]);
            }}
            sx={{
              display: "inline-block",
              cursor: "pointer",
              textAlign: "center",
              fontSize: 20,
              padding: "5px 25px",
              color:
                x
                  .toLowerCase()
                  .localeCompare(selectedLetters[0].toLowerCase(), "tr") >= 0 &&
                x
                  .toLowerCase()
                  .localeCompare(selectedLetters[1].toLowerCase(), "tr") <= 0
                  ? "black"
                  : "gray",
              backgroundColor:
                x
                  .toLowerCase()
                  .localeCompare(selectedLetters[0].toLowerCase(), "tr") >= 0 &&
                x
                  .toLowerCase()
                  .localeCompare(selectedLetters[1].toLowerCase(), "tr") <= 0
                  ? "orange"
                  : "transparent",
            }}
          >
            {x}
          </Typography>
        ))}
      </div>

      <BrandList>
        {brands.length != 0  ? (
          brands
            .filter(
              (brand) =>
                brand.name.charAt(0).localeCompare(selectedLetters[0], "tr") >=
                  0 &&
                brand.name.charAt(0).localeCompare(selectedLetters[1], "tr") <=
                  0
            )
            .map((x, index) => (
              <Link key={x.slug} to={`/marka/${x.slug}`} className="brand-item">
                {/* <div className="overlay" style={{animationDelay: `${index*1}s`,animationDuration: `${index*1}s`}}>Beni Seç</div> */}

                <p>{x.name}</p>

                <div
                  className={"image" + (index == 0 ? " overlayimg" : "")}
                  style={{ backgroundImage: `url(${x.image})` }}
                />
                <div
                  className={"imageFlip" + (index == 0 ? " overlayimg" : "")}
                  style={{ backgroundImage: `url(${x.productImage})` }}
                />

                <span className="mevcut">
                  <span>{x.productCount}</span> Ürün
                </span>
              </Link>
            ))
        ) : (
          <Sentry size={250} color="#FFA500" />
        )}
      </BrandList>

      <BottomNav />

      <Footer />
    </>
  );
}
