import axios from 'axios'

export const useProductRequests = () => {
  const cacheMod=false;
  const getSearchCompleteRequest = async (string) => {
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_SEARCH_COMPLETE+`?title=${string}`
      }`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_GET_SEARCH_COMPLETE
        }/?count=10`,
        Authorization: `${localStorage.getItem("bilalototoken")}`,
      },
    });
  }
  const getSearchComplete = async (string) => {
    const cachedSearchComplete = localStorage.getItem("bilalotosearch" + string.replace(/\s+/g, '-space-'));
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cachedSearchComplete != null) {
      const check = await JSON.parse(cachedSearchComplete);
      if (check.timestamp > secondsSinceEpoch) {
        
        return check.data;
      }
    }
    const result = await getSearchCompleteRequest(string);
    localStorage.setItem(
      "bilalotosearch" + string.replace(/\s+/g, "-space-"),
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: result,
      })
    );
    return result;
  }
  
  const getProducts = async (data={}) => { 
    console.log('getProducts',data);

    
    /*
      page=${data.page||"1"}&
      name=${data.name||""}&
      description=${data.description||""}&
      price=${data.price||""}&
      stock=${data.stock||""}&
      slug=${data.slug||""}&
      categorySlug=${data.category||""}&
      sort=${data.sort||""}&
      allSearch=${data.allSearch||""}
    */
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}`,
      data:data,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}/?count=10`,
        'Authorization': `${localStorage.getItem('bilalototoken')}`
      }
    })
  }
  const getAnotherProducts = async (data = {}) => {

    return axios({
      method: "POST",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_ANOTHER_PRODUCTS
      }?type=${data.type || 0}`,
      data:data,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_GET_ANOTHER_PRODUCTS
        }/?type=${data.type || 1}`,
        Authorization: `${localStorage.getItem("bilalototoken")}`,
      },
    });
  };
  const getSlidersRequest = async () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_GET_SLIDERS}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `${localStorage.getItem("bilalototoken")}`,
      },
    });
  }
  const getSliders = async () => {
    const cachedSliders = localStorage.getItem("bilalotosliders");
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cachedSliders != null && cacheMod==true) {
      const check = await JSON.parse(cachedSliders);
      if (check.timestamp > secondsSinceEpoch) {
        
        return check.data;
      }
    }
    const sliders = await getSlidersRequest();
    localStorage.setItem(
      "bilalotosliders",
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: sliders,
      })
    );
    return sliders;
  };

  const getProduct = async (id) => { 
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCT}?slug=${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}/${id}`,
        'Authorization': `${localStorage.getItem('bilalototoken')}`
      }
    })
  }

  

  const getDiscover = async (data={}) => {
    
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}`,
      data:data,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `${localStorage.getItem('bilalototoken')}`
      }
    })
  }
  const getCategoryRequest=async(brand)=>{
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_CATEGORIES
      }?brandSlug=${brand}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `${localStorage.getItem("bilalototoken")}`,
      },
    });
  }
  const getCategories = async (brand="") => {
    const cachedCategoryies = localStorage.getItem("bilalotocategories"+brand);
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000; 
    if(cachedCategoryies!=null && cacheMod==true){
      const check=await JSON.parse(cachedCategoryies);
      if(check.timestamp>secondsSinceEpoch){
        return check.data
      }
    } 
    const categories = await getCategoryRequest(brand);
    localStorage.setItem(
      "bilalotocategories"+brand,
      JSON.stringify({ timestamp: (secondsSinceEpoch+(60*60)), data: categories })
    );
    return categories;
    
    
    
  }
  const getBrandsRequest = async (category) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_BRANDS}`,
      // data: category,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `${localStorage.getItem("bilalototoken")}`,
      },
    });
  };
  const getBrands = async (category=[]) => {
  
    const cachedCategoryies = localStorage.getItem("bilalotobrands"+JSON.stringify(category));
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cachedCategoryies != null && cacheMod==true) {
      const check = await JSON.parse(cachedCategoryies);
      if (check.timestamp > secondsSinceEpoch) {
        return check.data;
      }
    }

    const brands = await getBrandsRequest(category);
    localStorage.setItem(
      "bilalotobrands" + JSON.stringify(category),
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: brands,
      })
    );

    return brands;
  }

  const getColorRequest = async (data) => {
    return axios({
      method: "POST",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_PRODUCT_COLORS
      }`,
      data: {
        brandSlugList: data.brandSlugList,
        groupSlugList: data.groupSlugList,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `${localStorage.getItem("bilalototoken")}`,
      },
    });
  }

  const getColors=async(data={})=>{
    const cachedColors = localStorage.getItem(
      "bilalotocolors"+JSON.stringify(data)
    );
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cachedColors != null && cacheMod==true) {
      const check = await JSON.parse(cachedColors);
      if (check.timestamp > secondsSinceEpoch) {
        return check.data;
      }
    }

    const colors = await getColorRequest(data);
    localStorage.setItem(
      "bilalotocolors" + JSON.stringify(data),
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: colors,
      })
    );

    return colors;
    
  }

  const getSpecialCodesRequest = async (id) => {
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_PRODUCT_SPECIAL_CODES
      }?id=${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `${localStorage.getItem("bilalototoken")}`,
      },
    });
  };

  const getSpecialCodes = async (id=2) => {
    const cachedSpecialCodes = localStorage.getItem("bilalotospecialcodes"+id);
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cachedSpecialCodes != null && cacheMod==true) {
      const check = await JSON.parse(cachedSpecialCodes);
      if (check.timestamp > secondsSinceEpoch) {
        return check.data;
      }
    }

    const codes = await getSpecialCodesRequest(id);
    localStorage.setItem(
      "bilalotospecialcodes" + id,
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: codes,
      })
    );

    return codes;
  };
  
  
  return {
    getSearchComplete,
    getProducts,
    getProduct,
    getDiscover,
    getCategories,
    getBrands,
    getSliders,
    getColors,
    getSpecialCodes,
    getAnotherProducts,
  };
}