import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProductList from "../components/Content/ProductList";
import { useProductRequests } from "../helpers/ProductRequests";
import { Helmet } from "react-helmet";

export default function NewProducts(props) {
  const { slug } = useParams();
  const [search, setSearch] = useState("");

  const [sort, setSort] = useState({ sort: "DATE", sortedBy: "DESC" });
  const [products, setProducts] = useState([]);
  const [allData, setAllData] = useState(null);
  const { getProducts } = useProductRequests();
  const [selectedBrand, setSelectedBrand] = useState("");

  React.useEffect(() => {
    setSelectedBrand("");
  }, [slug]);
  const simpleCount = 20;

  const getNewProducts = async (newpage, setLoading, search, setPage) => {
    setLoading(true);
    getProducts({
      sort: sort,
      productOfCampaign: true,
      page: newpage,
      allSearch: search,
    }).then(async (response) => {
      if (response.data.products.length < 1) {
        setLoading(false);
      } else {
        setPage(newpage);
        setProducts([...products, ...response.data.products]);
        setAllData(response.data);
      }
    });
  };
  const getSearchedProducts = (setLoading, setPage, search) => {
    setLoading(true);
    getProducts({
      sort: sort,
      productOfCampaign:true,
      page: 1,
      allSearch: search,
    }).then((response) => {
      if (response.data.products.length < 1) {
        setLoading(false);
      } else {
        setPage(2);
        setProducts(response.data.products);
        setAllData(response.data);
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Kampanyalı Ürünler</title>
      </Helmet>
      <ProductList
        setSelectedBrand={setSelectedBrand}
        selectedBrand={selectedBrand}
        slug={slug}
        getNewProducts={getNewProducts}
        getSearchedProducts={getSearchedProducts}
        products={products}
        search={search}
        setSearch={setSearch}
        setProducts={setProducts}
        allData={allData}
        setAllData={setAllData}
        getProducts={getProducts}
        sort={sort}
        setSort={setSort}
      />
    </>
  );
}
