import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ProductList from "../components/Content/ProductList";
import { useProductRequests } from "../helpers/ProductRequests";
import { Helmet } from "react-helmet";
import Footer from "../components/Navigation/Footer";
import { BottomNav } from "../styles/Header";
import Header from "../components/Navigation/Header";
import { ProductContent } from "../styles/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faBars,
  faCheck,
  faFilter,
  faPalette,
  faSearch,
  faSlidersH,
  faStarHalfAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CategoryItem from "../components/Content/Category/FilterGroup/CategoryItem";
import ProductItem from "../components/Product/ProductCard";
import Carousel, { consts } from "react-elastic-carousel";
import useWindowDimensions from "../components/useWindowDimensions";
import { Sentry } from "react-activity";
import ColorItem from "../components/Content/Category/FilterGroup/ColorItem";
import SoleItem from "../components/Content/Category/FilterGroup/SoleItem";
import BrandItem from "../components/Content/Category/FilterGroup/BrandItem";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { SwapButton } from "../styles/Product";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function NewProducts(props) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const location = useLocation();

  const width = useWindowDimensions().width;
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState({
    sort: "DATE",
    sortedBy: "DESC",
    name: "Yeni Ürünler",
  });

  const { getProducts, getCategories, getBrands, getColors, getSpecialCodes } =
    useProductRequests();

  const simpleCount = 60;
  const dispatch = useDispatch();

  const [colors, setColors] = useState([]);
  const [soles, setSoles] = useState([]);
  const [selectedSoles, setSelectedSoles] = useState([]);
  const [open, setOpen] = useState(width < 1140 ? false : true);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [selected, setSelected] = React.useState([]);
  const [selectedBrands, setSelectedBrands] = React.useState([]);
  const [selectedColors, setSelectedColors] = React.useState([]);
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState(null);

  React.useEffect(() => {
    setPage(1);
    getCategories().then((res) => {
      setLoading(false);
      setSelected([]);
      setCategories(res.data.data.filter((x) => x.subGroups.length > 0));
      
    });
  }, []);

  const getProductReq = () => {
    getProducts({
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        setAllData(res.data.data);
        if (width < 1000) {
          // setOpen(false)
        }
        setLoading(false);
        setProducts([...res.data.data.items]);
        setPage(1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (loading != false) {
      return;
    }
    setLoading(true);
    setProducts([]);

    getProducts({
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        setAllData(res.data.data);
        if (width < 1000) {
          // setOpen(false)
        }
        setLoading(false);
        setProducts([...res.data.data.items]);
        setPage(1);
      })
      .catch((err) => {
        setLoading(false);
      });

    getBrands(selected).then((res) => {
      if (selectedBrands.length != 0) {
        setSelectedBrands(
          selectedBrands.filter(
            (x) => res.data.data.filter((y) => y.slug == x).length > 0
          )
        );
      }

      setBrands(res.data.data);
    });
  }, [selected]);

  React.useEffect(() => {
    getColors({
      groupSlugList: selected.length == 0 ? [] : selected,
      brandSlugList: selectedBrands,
    }).then((res) => {
      setColors(res.data.data);
    });
  }, [selected, selectedBrands]);

  React.useEffect(() => {
    if (loading == true) {
      return;
    }
    setProducts([]);
    setLoading(true);

    getProducts({
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,

      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        if (width < 1000) {
          // setOpen(false);
        }
        setLoading(false);
        setAllData(res.data.data);

        setPage(1);
        setProducts([...res.data.data.items]);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [selectedBrands, selectedColors, selectedSoles]);

  const getNewPage = (pg) => {
    if (loading == true) {
      return;
    }
    setLoading(true);
    getProducts({
      page: pg,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:
        selected.length == 0
          ? []
          : selected,
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        if (width < 1000) {
          // setOpen(false);
        }
        setAllData(res.data.data);
        setPage(pg);
        setProducts([...products, ...res.data.data.items]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (loading == false) {
      setLoading(true);
      setProducts([]);
      getProducts({
        page: 1,
        count: simpleCount,
        specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
        specialCodeIdList: selectedSoles,
        colorList: selectedColors,
        groupSlugList:
          selected.length == 0
            ? []
            : selected,
        brandSlugList: selectedBrands,
        sort: sort.sort,
        sortedBy: sort.sortedBy,
        betweenPrices: minPrice == null && maxPrice == null ? false : true,
        minPrice: minPrice == null ? -1 : minPrice,
        maxPrice: maxPrice == null ? -1 : maxPrice,
      })
        .then((res) => {
          if (width < 1000) {
            setOpen(false);
          }
          setAllData(res.data.data);
          setPage(1);
          setProducts(res.data.data.items);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [sort]);

  React.useEffect(() => {
    getSpecialCodes(2).then((res) => {
      setSoles(res.data.data);
    });
  }, []);

  const getSlugToName = (rslug) => {
    if (categories.length == 0) {
      return "";
    }

    if (categories.filter((x) => x.slug == rslug).length > 0) {
      return categories.filter((x) => x.slug == rslug)[0].name;
    } else {
      try {
        return categories
          .filter(
            (x) => x.subGroups.filter((y) => y.slug == rslug).length > 0
          )[0]
          .subGroups.filter((x) => x.slug == rslug)[0].name;
      } catch (error) {
        return "";
      }
    }
  };

  const [opened, setOpened] = useState("");
  const [brandSearchOpen, setBrandSearchOpen] = useState(false);
  const [brandSearchTxt, setBrandSearchTxt] = useState("");
  const [colorSearchTxt, setColorSearchTxt] = useState("");
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [openedBox, setOpenedBox] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [colorOpen, setColorOpen] = useState(false);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < products.map((x) => x.image).length &&
    setCurrentIndex(currentImageIndex + 1);
  return (
    <>
      {categories.length > 0 ? (
        <Helmet>
          <title>
            Toptan {categories[0].name} Ürünleri - bilaloto Toptan Lastik
          </title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Toptan Lastik - bilaloto Toptan Lastik</title>
        </Helmet>
      )}

      <Header />
      <h1 className="analizicin">
        {categories.length > 0 && categories[0].name} Kategori Ürünleri - bilaloto
        Toptan Lastik{" "}
      </h1>
    
      <div style={{ width: "100%" }}>
        <div className="filters">
          <div className="left">
            {!open && (
              <div className="filter-btn" onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faSlidersH} /> Filtreler
              </div>
            )}
          </div>
          <div className="right">
            {selected.length > 0 ||
            minPrice != null ||
            maxPrice != null ||
            selectedBrands.length > 0 ||
            selectedColors.length > 0 ||
            selectedSoles.length > 0 ? (
              <>
                <div className="filtered-list">
                  <div
                    className="filtered-item all-remover"
                    key={"filtergfsed-x-"}
                    onClick={() => {
                      setSelectedBrands([]);
                      setSelected([]);
                      setSelectedColors([]);
                    }}
                  >
                    Filtreyi Temizle
                    <span>x</span>
                  </div>

                  {selectedBrands.map((x) => {
                    return (
                      <div
                        className="filtered-item"
                        key={"filtergfsed-x-" + x}
                        onClick={() => {
                          setSelectedBrands(
                            selectedBrands.filter((y) => y != x)
                          );
                        }}
                      >
                        {brands.filter((y) => y.slug == x).length < 1
                          ? ""
                          : brands.filter((y) => y.slug == x)[0].name}{" "}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {selected.map((x) => {
                    return (
                      <div
                        key={"filtereddfgd-y-" + x}
                        onClick={() => {
                          setSelected(selected.filter((y) => y != x));
                        }}
                        className="filtered-item"
                      >
                        {getSlugToName(x)} <span>x</span>
                      </div>
                    );
                  })}
                  {selectedColors.map((x) => {
                    return (
                      <div
                        key={"filtered-color-sdfgc" + x}
                        onClick={() => {
                          setSelectedColors(
                            selectedColors.filter((y) => y != x)
                          );
                        }}
                        className="filtered-item"
                      >
                        {x}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {selectedSoles.map((x) => {
                    return (
                      <div
                        key={"filtered-sodlfdge-" + x.id}
                        onClick={() => {
                          setSelectedSoles(selectedSoles.filter((y) => y != x));
                        }}
                        className="filtered-item"
                      >
                        {soles.filter((y) => y.id == x).length < 1
                          ? ""
                          : soles
                              .filter((y) => y.id == x)[0]
                              .specialCode.replace(/Taban/g, " ")
                              .replace(/:/g, " ")}{" "}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {minPrice != null && (
                    <div
                      onClick={() => {
                        setMinPrice(null);
                      }}
                      className="filtered-item"
                    >
                      En Az {minPrice}₺<span>x</span>
                    </div>
                  )}
                  {maxPrice != null && (
                    <div
                      onClick={() => {
                        setMaxPrice(null);
                      }}
                      className="filtered-item"
                    >
                      En Fazla {maxPrice}₺<span>x</span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            <button
              className="sort-btn"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <FontAwesomeIcon
                icon={sort.sortedBy == "DESC" ? faAngleDown : faAngleUp}
                style={{ marginRight: 10 }}
              />{" "}
              {sort.name}
              <div
                className="filter-list"
                style={{ display: filterOpen != true ? "none" : "unset" }}
              >
                <div
                  onClick={() =>
                    setSort({
                      sort: "RANDOM",
                      sortedBy: "",
                      name: "Keşfet",
                    })
                  }
                  className="item"
                >
                  {sort.sort == "RANDOM" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Keşfet
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "DATE",
                      sortedBy: "DESC",
                      name: "Yeni Ürünler",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Yeni Ürünler
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "DATE",
                      sortedBy: "ASC",
                      name: "Eski Ürünler",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Eski Ürünler
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "PRICE",
                      sortedBy: "ASC",
                      name: "Artan Fiyat",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Fiyat
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "PRICE",
                      sortedBy: "DESC",
                      name: "Azalan Fiyat",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Fiyat
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "STOCK",
                      sortedBy: "DESC",
                      name: "Azalan Mevcut",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Mevcut
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "STOCK",
                      sortedBy: "ASC",
                      name: "Artan Mevcut",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Mevcut
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "TOPSALES",
                      sortedBy: "DESC",
                      name: "En Çok Satan",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TOPSALES" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  En Çok Satan
                </div>
              </div>
            </button>

            <div className="total">
              {allData != null &&
              allData.currentPage != null &&
              allData.count != null ? (
                <div className="count">
                  {/* {allData.count * allData.currentPage} /{" "} */}
                  {/* {products.length}
                    <strong>/</strong> */}
                  {allData.totalCount} Ürün
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <ProductContent open={open}>
        <div className="filter">
          {width < 1140 && (
            <div className="overlay" onClick={() => setOpen(false)}></div>
          )}
          <div className="categories">
            <div className="category-item head" onClick={() => setOpen(!open)}>
              <span>
                <FontAwesomeIcon icon={faBars} />
              </span>
              <span>Kategoriler</span>
              <span>x</span>
            </div>
            {categories.map((x) => {
              return (
                <CategoryItem
                  slug={""}
                  dispatch={dispatch}
                  key={"categorydfscv-item-" + x.slug}
                  category={x}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}
            {/* {selected.length > 0 ||
            selectedBrands.length > 0 ||
            selectedColors.length > 0 ||
            selectedSoles.length > 0 ? (
              <>
                <div className="category-item alt-head">
                  <span>
                    <FontAwesomeIcon icon={faFilter} />
                  </span>
                  <span>Filtreler</span>
                  <span
                    style={{ fontSize: 11 }}
                    onClick={() => {
                      setSelectedBrands([]);
                      setSelected([]);
                    }}
                  >
                    Tümünü Kaldır
                  </span>
                </div>
                <div className="filtered-list">
                  {selectedBrands.map((x) => {
                    return (
                      <div
                        className="filtered-item"
                        key={"filtergfsed-x-" + x}
                        onClick={() => {
                          setSelectedBrands(
                            selectedBrands.filter((y) => y != x)
                          );
                        }}
                      >
                        {brands.filter((y) => y.slug == x).length < 1
                          ? ""
                          : brands.filter((y) => y.slug == x)[0].name}{" "}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {selected.map((x) => {
                    return (
                      <div
                        key={"filtereddfgd-y-" + x}
                        onClick={() => {
                          setSelected(selected.filter((y) => y != x));
                        }}
                        className="filtered-item"
                      >
                        {getSlugToName(x)} <span>x</span>
                      </div>
                    );
                  })}
                  {selectedColors.map((x) => {
                    return (
                      <div
                        key={"filtered-color-sdfgc" + x}
                        onClick={() => {
                          setSelectedColors(
                            selectedColors.filter((y) => y != x)
                          );
                        }}
                        className="filtered-item"
                      >
                        {x}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {selectedSoles.map((x) => {
                    return (
                      <div
                        key={"filtered-sodlfdge-" + x.id}
                        onClick={() => {
                          setSelectedSoles(selectedSoles.filter((y) => y != x));
                        }}
                        className="filtered-item"
                      >
                        {soles.filter((y) => y.id == x).length < 1
                          ? ""
                          : soles
                              .filter((y) => y.id == x)[0]
                              .specialCode.replace(/Taban/g, " ")
                              .replace(/:/g, " ")}{" "}
                        <span>x</span>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )} */}
            {brands.length > 0 && (
              <>
                <div
                  className={"category-item alt-head "}
                  onClick={() => {
                    setBrandOpen(!brandOpen);
                  }}
                  style={{ position: "relative" }}
                >
                  <span
                    style={{
                      transition: "600ms all",
                      transform: brandOpen
                        ? "rotateX(180deg)"
                        : "rotateX(0deg)",
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                  <span>Marka</span>

                  <span style={{ fontSize: 11 }} onClick={() => {}}>
                    (
                    {
                      brands.filter((x) =>
                        x.name
                          .toLocaleLowerCase("tr")
                          .startsWith(brandSearchTxt.toLocaleLowerCase("tr"))
                      ).length
                    }
                    )
                  </span>
                </div>
                <div
                  className="search-box category-sub-item"
                  style={{ display: brandOpen ? "" : "none" }}
                >
                  <input
                    type="text"
                    value={brandSearchTxt}
                    onChange={(e) => setBrandSearchTxt(e.target.value)}
                    placeholder="Marka Ara"
                  />
                  {brandSearchTxt.length > 0 && (
                    <span
                      onClick={() => setBrandSearchTxt("")}
                      className="search-trash"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  )}
                </div>
                <div
                  className="colors"
                  style={{ display: brandOpen ? "" : "none" }}
                >
                  {brands
                    .filter((x) => selectedBrands.includes(x.slug))
                    .filter((x) =>
                      x.name
                        .toLocaleLowerCase("tr")

                        .startsWith(brandSearchTxt.toLocaleLowerCase("tr"))
                    )
                    .map((x) => {
                      return (
                        <BrandItem
                          key={"sole-item-dffgdhgh-" + x.id}
                          brand={x}
                          selectedBrands={selectedBrands}
                          setSelectedBrands={setSelectedBrands}
                          categoryMode={false}
                        />
                      );
                    })}
                  {brands
                    .filter((x) => !selectedBrands.includes(x.slug))
                    .filter((x) =>
                      x.name
                        .toLocaleLowerCase("tr")
                        .startsWith(brandSearchTxt.toLocaleLowerCase("tr"))
                    )
                    .map((x) => {
                      return (
                        <BrandItem
                          key={"sole-item-dffgdhgh-" + x.id}
                          brand={x}
                          selectedBrands={selectedBrands}
                          setSelectedBrands={setSelectedBrands}
                          categoryMode={false}
                        />
                      );
                    })}
                </div>
              </>
            )}
          
          </div>
          <div
            className="category-item price-sort"
            style={{ display: opened == "fiyat" ? "flex" : "none" }}
          >
            <span className="input">
              <input
                type="number"
                placeholder="Minimum"
                value={minPrice == null ? "" : minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
            </span>
            <span className="minus">-</span>
            <span className="input">
              <input
                type="number"
                placeholder="Maximum"
                value={maxPrice == null ? "" : maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </span>
            <span
              onClick={() => {
                getProductReq();
              }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>
        </div>

        <div className="list">
          <div className="filter-mobil-group">
            <div className="filter-btn mobil" onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={faSlidersH} /> Filtreler
            </div>
            <button
              className="sort-btn-mobil"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <FontAwesomeIcon
                icon={sort.sortedBy == "DESC" ? faAngleDown : faAngleUp}
                style={{ marginRight: 10 }}
              />{" "}
              {sort.name}
              <div
                className="filter-list"
                style={{ display: filterOpen != true ? "none" : "unset" }}
              >
                <div
                  onClick={() =>
                    setSort({
                      sort: "RANDOM",
                      sortedBy: "",
                      name: "Keşfet",
                    })
                  }
                  className="item"
                >
                  {sort.sort == "RANDOM" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Keşfet
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "DATE",
                      sortedBy: "DESC",
                      name: "Yeni Ürünler",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Yeni Ürünler
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "DATE",
                      sortedBy: "ASC",
                      name: "Eski Ürünler",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Eski Ürünler
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "PRICE",
                      sortedBy: "ASC",
                      name: "Artan Fiyat",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Fiyat
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "PRICE",
                      sortedBy: "DESC",
                      name: "Azalan Fiyat",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Fiyat
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "STOCK",
                      sortedBy: "DESC",
                      name: "Azalan Mevcut",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Azalan Mevcut
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "STOCK",
                      sortedBy: "ASC",
                      name: "Artan Mevcut",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Artan Mevcut
                </div>
                <div
                  onClick={() =>
                    setSort({
                      sort: "TOPSALES",
                      sortedBy: "DESC",
                      name: "En Çok Satan",
                    })
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TOPSALES" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  En Çok Satan
                </div>
              </div>
            </button>
          </div>

          {products.map((x) => {
            return (
              <ProductItem
                key={x.id + x.slug + "asda"}
                product={x}
                setOpenedBox={setOpenedBox}
              />
            );
          })}
          <div style={{ width: "100%", textAlign: "center" }}>
            {allData != null &&
            products.length > 0 &&
            allData.currentPage != null &&
            allData.count != null ? (
              <div className="count">
                {products.length == allData.totalCount ? (
                  <>Toplam {products.length} Ürün</>
                ) : (
                  <>
                    {products.length}
                    <strong
                      style={{
                        marginRight: 5,
                        marginLeft: 5,
                        fontWeight: "bold",
                      }}
                    >
                      /
                    </strong>
                    {allData.totalCount} Ürün
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {allData != null &&
          products.length > 0 &&
          allData.currentPage != null &&
          allData.count != null &&
          allData.totalCount != products.length ? (
            <div
              onClick={() => {
                getNewPage(page + 1);
              }}
              style={{
                color: "#ffff",
                padding: "5px 55px",
                marginBottom: 40,
                width: "100%",
                borderRadius: 100,
                textAlign: "center",
                cursor: "pointer",
                fontSize: 22,
              }}
            >
              {!loading && (
                <div
                  style={{
                    display: "inline-block",
                    background: "#F27A1A",
                    padding: "15px 55px",
                    marginTop: 20,
                    borderRadius: 100,
                    fontSize: 18,
                  }}
                >
                  Daha Fazla Ürün Göster
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && <Sentry color="#FBA800" size={180} />}
          </div>
        </div>
      </ProductContent>
      <div style={{ zIndex: 1009090 }}>
        {products.length > 0 && (
          <Lightbox
            isOpen={openedBox}
            onPrev={gotoPrevious}
            className="lightbox-image-box"
            onNext={gotoNext}
            images={products.map((x) => {
              return { src: x.image, loading: "lazy", alt: "deha" };
            })}
            currentIndex={currentImageIndex}
            onClose={() => setOpenedBox(false)}
            style={{ background: "#fff", zIndex: 1009090 }}
            renderNextButton={() => (
              <SwapButton onClick={gotoNext} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleRight} />
              </SwapButton>
            )}
            renderPrevButton={() => (
              <SwapButton onClick={gotoPrevious} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleLeft} />
              </SwapButton>
            )}
            renderFooter={() => (
              <div
                style={{
                  width: "40vw",
                  // background: "rgba(0,0,0,0.5)",
                  height: "40vh",
                  top: "30vh",
                  left: "30vw",
                  position: "fixed",
                  zIndex: 100,
                }}
                onClick={() => {
                  history.push("/urun/" + products[currentImageIndex].slug);
                }}
              ></div>
            )}
            renderHeader={() => (
              <button
                onClick={() => setOpenedBox(false)}
                className="light-close"
              >
                X
              </button>
            )}
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
        )}
      </div>
      <BottomNav />
      {/* <Footer /> */}
    </>
  );
}
