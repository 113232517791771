import styled from "styled-components";

const PrintContainer = styled.div`
  .makbuz {
    width: 100%;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-height: 100vh;
  }

  .makbuz h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .bilgiler p {
    font-size: 16px;
    margin: 10px 0;
  }

  .bilgiler strong {
    font-weight: bold;
  }

  .head {
    display: flex;
    justify-content: space-between;
    .leftHead {
      width: 20%;
      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          min-width: 40%;
        }
      }
    }
  }
  color: #000 !important;
  width: 100%;
  h1 {
    text-align: center;
  }
  ul {
    margin-block-start: 0px;
    margin-inline-start: 0px;
    padding-inline-start: 0px;
    width: 100%;

    li {
      
      display: flex;

      div {
        padding-left: 10px;
        border: 1px solid #000;
        width: 100%;
        min-width: 10%;
        img {
          max-width: 40px;
        }
      }
      div:first-child {
        padding-left: 0px;
      }
    }
  }
`;


export {PrintContainer}