import React,{useState,useLayoutEffect} from 'react'
import { HeaderAlt, HeaderAltMobile } from "../../styles/Header";

import CategoryLoader from '../Content/CategoryLoader';

import { useProductRequests } from '../../helpers/ProductRequests';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from '../useWindowDimensions';
import { useSelector } from 'react-redux';

const Foo = () => {
  return <div>hello foo</div>;
};
const Bar = () => {
  return <div>hello b</div>;
};
export default function Categories({open,setOpen}) {

  const {getCategories} = useProductRequests();
  const {language}=useSelector(state=>state.site)

  const [categories, setCategories] = useState([])

  const history=useHistory()

  useLayoutEffect(() => {
    
    if(categories.length === 0){
      getCategories().then((response)=>{
        setCategories(response.data.data)
      }).catch((error)=>{
        console.log("error",error.response)
      })
    }
    
  }, [])
  const data = [
  {
    label: "foo",
    key: 1,
    items: <Foo />
  },
  {
    label: "bar",
    key: 2,
    items: <Bar />
  },
  {
    label: "Category2",
    key: "Category3",
    items: "Category3 content"
  }
];
const [selected, setSelected] = useState(null);
const width=useWindowDimensions().width
  return (
    <>
      <HeaderAltMobile open={open} style={{ zIndex: 999999 }}>
        <div className="overlay" onClick={() => setOpen(false)}></div>
        <button className="close-btn" onClick={() => setOpen(false)}>
          x
        </button>
        <div style={{ width: "90%", zIndex: 999999100 }}>
          <h1 style={{ padding: 10, textAlign: "center" }}>
            {language.categories}
          </h1>
          <ul>
            {categories != null &&
            categories != undefined &&
            categories.length > 0 ? (
              <>
                {categories[1].subGroups.map((category, index) => {
                    return (
                      <li key={"ctgm" + category.slug} className={" test"}>
                        <button>
                          <div>
                            <span
                              onClick={() => {
                                history.push({
                                  pathname: "/kategori/" + category.slug,
                                });
                                setOpen(false);
                              }}
                              style={{ width: "100%" }}
                            >
                             {category.name}
                            </span>
                            {/* history.location.pathname.indexOf("/kategori/") ==-1 && width < 1140  */}
                            {true ? (
                              <FontAwesomeIcon
                                onClick={() =>
                                  setSelected(
                                    selected == category.slug
                                      ? null
                                      : category.slug
                                  )
                                }
                                style={{
                                  textAlign: "right",
                                  minHeight: 20,
                                  transition: 500 + "ms all",
                                  transform:
                                    selected == category.slug
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                }}
                                icon={faAngleDown}
                              />
                            ) : (
                              <div
                                style={{
                                  width: 200,
                                  textAlign: "right",
                                  minHeight: 20,
                                  transition: 500 + "ms all",
                                  transform:
                                    selected == category.slug
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                }}
                              ></div>
                            )}
                          </div>
                        </button>
                        {/* Alt  kategoriler burada ; history.location.pathname.indexOf("/kategori/") == -1 && */}
                        {category.subSubGroups?.length > 0 && width < 1140 ? (
                          <>
                            <div
                              className="sub-category"
                              style={{
                                transform:
                                  selected == category.slug
                                    ? "scale(1)"
                                    : "scale(0)",
                                height:
                                  selected == category.slug ? "unset" : "0",
                              }}
                            >
                              {category.subSubGroups.map(
                                (sub_category, index) => {
                                  return (
                                    <button
                                      key={"sb-s" + sub_category.slug}
                                      onClick={() => {
                                        history.push(
                                          "/kategori/" + sub_category.slug
                                        );

                                        setTimeout(() => {
                                          setOpen(false);
                                        }, 150);
                                      }}
                                    >
                                      <div
                                        className="ct-image"
                                        style={{
                                          display: "none",
                                          backgroundImage: `url(${
                                            process.env
                                              .REACT_APP_ENTEGROID_FOLDER +
                                            "/kategori/" +
                                            sub_category.slug +
                                            ".jpg"
                                          })`,
                                        }}
                                      />
                                      <span>
                                        {sub_category.name.replace(
                                          /\//g,
                                          " / "
                                        )}
                                      </span>
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>
                    );
                })}
              </>
            ) : (
              <>
                <CategoryLoader head={"li"} count={10} />
              </>
            )}
          </ul>
        </div>
      </HeaderAltMobile>
      {open ? (
        <></>
      ) : (
        <HeaderAlt>
          <ul>
            <li style={{textTransform:"uppercase"}} onClick={() => window.location.href = "/"}><button><span>{language.homepage}</span></button></li>
            {categories != null &&
            categories != undefined &&
            categories.length > 0 ? (
              <>
                {categories[1].subGroups.map((category, index) => {
                    return (
                      <li
                        key={"ctg" + category.slug}
                        className={
                          "" +
                          (window.location.href.indexOf(
                            "/kategori/" + category.slug
                          ) != -1
                            ? "active"
                            : "")
                        }
                      >
                        <button
                          onClick={() =>
                            history.push({
                              pathname: "/kategori/" + category.slug,
                            })
                          }
                        >
                          <span>{category.name}</span>
                        </button>
                       
                      </li>
                    );
                })}
              </>
            ) : (
              <>
                <CategoryLoader head={"li"} count={10} />
              </>
            )}

            {/* <li
              className={
                "" +
                (window.location.href.indexOf("/conteyner") != -1
                  ? "active"
                  : "")
              }
            >
              <button
                onClick={() =>
                  history.push({ pathname: "/marka/conteyner-3125" })
                }
              >
                <span>Conteyner</span>
              </button>
            </li> */}
            {/* <li
              className={
                "" +
                (window.location.href.indexOf("/markalar") != -1
                  ? "active"
                  : "")
              }
            >
              <button onClick={() => history.push({ pathname: "/markalar" })}>
                <span>Markalar</span>
              </button>
            </li> */}

            {/* <li
              className={
                "" +
                (window.location.href.indexOf("/yeni-urunler") != -1
                  ? "active"
                  : "")
              }
            >
              <button
                onClick={() => history.push({ pathname: "/yeni-urunler" })}
              >
                <span>Yeni Ürünler</span>
              </button>
            </li> */}
            {/* <li>
          <button
            onClick={() => history.push({ pathname: "/kampanyali-urunler" })}
          >
            <span>Kampanya</span>
          </button>
        </li> */}
          </ul>
        </HeaderAlt>
      )}
    </>
  );
}
