
import {createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  *{
    font-family: 'Roboto', sans-serif!important;
    margin-inline-end: 0px;
    padding-inline-end: 0px;
    padding-block-end: 0px;
    margin-block-end: 0px;
    dl,ol,ul{
      margin-bottom:0px!important;
    }
    img{
      user-select:none!important;
    }
  }
  html,body,#root{
    overflow-x:none;
  }
  .brand-name{
    font-family: 'Nunito Sans', sans-serif!important;
  }
  html,body{
    background:${(props) => props.theme.background};
    color:${(props) => props.theme.color};
  }
  
  .changerTheme{
    display:flex;height:100%;justify-content:center,align-items:center;
  }
  
  .authtabList{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }
  .authtabList button{
    border:0;
    outline:0;
    background-color: ${({ theme }) => theme.mainThemeBackground};
    padding:10px 40px;
    transition:300ms all;
    color:${({ theme }) => theme.opacityColor};
  }
  .authtabList button[aria-selected="true"]{
    background-color: ${({ theme }) => theme.mainThemeColor};
    color: #fff;
    border:2px solid ${({ theme }) => theme.mainThemeBackground};
  }
  .loader{
    width:100%;
    padding:30px 0px;
    display:flex;
    justify-content:center;
    align-items:center;
    
  }
  .productList-parent{
    
    position:relative;
    .filter-buttons{
      width:100%;
      display:flex;
      justify-content:space-between;
      margin:20px 0px;
      .grids{
        display:flex;
        width:100%;
        justify-content:flex-end;
        padding-right:20px;
        .count{
          margin-left:20px;
          border-radius:10px;
          display:flex;
          cursor:pointer;
          justify-content:center;
          align-items:center;
          padding:5px 20px; 
        }
      }
      .filters{
        width:60%;
        display:flex;
        justify-content:space-around;
        cursor:pointer;
        align-items:center;
        div{
          padding:10px 20px;
          background-color:rgba(242,242,242,.4);
          transition:300ms all;
          border-radius:10px;
          .icon{
            margin-left:20px;
          }
          &:hover{
          background-color:rgba(0,0,0,.05);
          }
        }
        div.active{
          background-color:rgba(0,0,0,.2);

        }
        @media only screen and (max-width:1140px){
          flex-wrap:wrap;
          width:100%;
          justify-content:space-between;
          div{
            margin-bottom:20px;
          }
          
        }
      }
      .filters-mobile{
        display:none;
      }
      .filters-mobile{
        width:50%;
        button{
          border:0;
          outline:0;
          background:rgba(242,242,242,.4);
          font-size:13px;
          width:100%;
          padding:10px 0px;
          position:relative;
          border-radius:10px;
          .mobile-filter-list{
            position:absolute;
            width:100%;
            left:0;
            top:100%;
            background:#fff;
            z-index:100;
            box-shadow: 0px 0px 10px #eee;
            div{
              padding:7px 10px;
              &:not(:last-child){
                border-bottom:1px solid #ddd;
              }
            }
          }
        }
      }
      @media only screen and (max-width:1140px){
        flex-wrap:wrap;
        .filters{
          display:none!important;
        }
        .filters-mobile{
          display:flex;
        }
      }
    }
    width:100%;
    
    .product-sorter{
      margin-bottom:-65px;
      z-index:1000!important;
      width:100%;
      display:flex;top:0px;
      z-index:1;
      justify-content:space-between;
    }
  }

  #cell-2-undefined,
  #cell-3-undefined,
  #cell-4-undefined,
  #cell-5-undefined,
  #cell-6-undefined,
  #cell-7-undefined
{
  width:120px;


}

.rdt_TableCol,
.rdt_TableCell{
  min-width:0px !important;
  }



  .garanti-belgesi{
      display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  @media only screen and (max-width:1140px){
    .productList-parent{
      width:100%;
    }
    
  }
  .unlimited-scroll{
    align-items:center;
    min-height:100vh;
    z-index: 100000;
    max-height:100vh;
    
    overflow:scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .unlimited-scroll.variant {
    min-height:unset;
    max-height:unset;
    max-height: 70vh;
    overflow-y: scroll;
    .content-container{
    
    }
  }
  .content-container{
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    z-index: 100000;
    justify-content:space-around;
    
  }
  
  .invoices{
    padding:7px 20px!important;
    cursor:pointer; 
    font-weight:bold;
    font-family: 'Overpass', sans-serif!important;
    transition:600ms all;
    text-decoration:none;
    display:block;
    color:#000;
    border-radius:10px;
    &:hover{
      border-radius:0px;
      color:#000;
      background:rgba(0,0,0,.1);
      font-weight:bold;
    }
  }
  .light-close {
    display: block;
    position: fixed;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    box-shadow: 0px 0px 19px #000;
    outline: none;
    border: none;
    z-index: 999;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    right: 438px;
    top: 23px;
    width: 70px;
    background:transparent;
    color:#333;
    font-weight:normal;
    box-shadow:none;
    font-size: 25px;      
    @media only screen and (max-width: 1140px) {
      right: 0;
      top: 0;
      width:50px;
      background:transparent;
      color:#333;
      font-weight:normal;
      box-shadow:none;
      font-size: 25px;      
      
    }
  }
  .cancel{
    margin-left:10px;border:0;outline:0;padding:7px;background:#733;color:#fff;
  }
  .Beklemede{
    background:#fffa65;
  }
  .Onaylandı{
    background:#7bed9f;
  }
  .Muhasebelendi{
    background:#2ed573;
  }
  .İptal{
    background:#e84118;
  }
  .FATURA{
    background:#fbc531;
  }
  .DEKONT{
    background:#9c88ff;
  }
  .BONUS{
    background:#273c75;
  }
  .ÇEK{
    background:#e84118;
  }
  .DEKONT{
    background:#00a8ff;
  }
  .DEVIR{
    background:#ff6b81;
  }
  .EVRAK{
    background:#7bed9f;
  }
  .IRSALIYE{
    background:#2ed573;
  }
  .KREDI-KARTI-IADE{
    background:#ff6348;
  }
  .KREDI-KARTI{
    background:#eccc68;
  }
  .NAKIT{
    background:#ffffff;
  }
  .POS-IADE{
    background:#ffcccc;
  }
  .POS{
    background:#7158e2;
  }
  .SENET{
    background:#67e6dc;
  }
  .SERVIS{
    background:#3ae374;
  }
  .SIPARIS{
    background:#fffa65;
  }
  .TAHAKKUK{
    background:#67e6dc;
  }
  .TAKSIT-VIRMAN{
    background:#67e6dc;
  }
  .VIRMAN{
    background:#67e6dc;
  }
  .mini-product {
    img{
      user-select:none;
    }
    *{
      user-select:none!important;
    }
    transition: 300ms transform;
    .title{
      position:relative;
    }
    .title .btn{
      position:absolute;
      
      width:200px;
      
      background:#f27a1a;
      top:0px;
      color:#fff;
      font-size:14px;
      transition: 500ms all;
      transform:scale(0);
      left:calc(50% - 100px);
    }
    .title .name{
      transition: 500ms all;
    }
    .price{
      font-size:20px;
      text-align:center;
      color:#005bb4;
      transition: 500ms all;
    }
    .title .btn:hover{
      background:#f89648;
    }
    &:hover{
      .title .name,.title .price{
        transform:scale(0);
      }
      .title .btn{
        transform:scale(1);
      }
    }
  }
  .rec-arrow,.rec-arrow,.rec-arrow-left,button.rec{
    // background:#f27a1a!important;
    // &:hover,&:hover:enabled, &:focus:enabled{
    //   background:#f27a4a!important;
    // }
  }
  @media only screen and (max-width: 1140px) {
    button.rec-arrow{
      display:none;
    }
    .home-slider{
      flex-direction:column;
      h1{
        margin:20px 0px;
      }
      button{
        display:none;
      }
      .rec-item-wrapper{
        border-radius:0px!important;
      }
      .rec-item-wrapper img{
        width:100%!important;
        border-radius:0px!important;
        
      }
    }
    
    .home-slider-v{
      
      padding:0px!important;
      width:100%!important;
      img{
        width:100%!important;
        height:100%!important;
        
      }
    }
  }
  .arrow-btn{
    transition: 300ms all;
    transform:scale(0);
    height:0px;
  }
  .home-slider-v{
    h1{
      transition: 300ms all;
      transform:scale(1);
    }
    button{
      transition: 300ms all;
      transform:scale(0)!important;
      height:40px!important;
      
    }
    &:hover{
      h1{
        transform:scale(0);
      }
      button{
        transform:scale(1)!important;
        height:40px!important;
        
      }
    }
  }

  .category-item{
    cursor:pointer;
    
    .search-icon{
      position:absolute;
      right:30px;
      font-weight:normal;
      font-size:14px;
      top:10px
    }
    .img{
      height:80px;
      width:80px;
      background-size:100%;
      background-repeat:no-repeat;
      background-position:center;
      margin-bottom:10px;
    }
    padding:10px;
    border-radius:10px;
    &:hover{
    }
    padding:10px;
    
    a{
      font-size:14px;
      text-decoration:none;
      display:flex;
      color:#000;
      user-select:none;
      flex-direction:column;
      flex-direction:column!important;
      justify-content:center;
      align-items:center;
      width:240px;
    }
  }

  ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF9F43; 
}

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .dehasoft-img{
    margin-top: 40px!important;
    cursor:pointer;
    height:unset!important;
    width:100px!important;
    max-width:100px!important;
  }
  .go-top-btn{
    bottom:110px!important;
    right:10px!important;
    z-index:100000!important;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    box-shadow:none!important;
    background:transparent!important;
    svg{
      margin-bottom:-5px!important;
    }
    span{
      font-weight:bold!important;
      font-size:9px;
    }
  }
  .analizicin{
    position:fixed;
    top:-500vh;
    left:-500vw;
  }

  .product-card{
    text-decoration:none;
    width:300px;
    box-shadow: 0px 0px 1px #eee;
    background:#fff;
    margin:5px;
    padding:5px;
    cursor:pointer;
    img{
      width:100%;
      height:300px;
      object-fit:cover;
      object-position:center;
      margin:0;
      padding:0;
      scale:0.7;
      object-fit:contain
    }
    position:relative;  
    .absolute-stock-count{
      position:absolute;
      top:10px;
      left:0px;
      z-index:1;
      background:#f27a1a;
      color:#fff;
      padding:2.5px 5px;
      border-radius:5px;
      font-size:12px;
      &.out-of-stock{
        background:#dd8d8d;
        color:#430404;
        border:0px solid #430404;
      }
      &.MEVCUT{
        background:green;
        color:white;
        border:0px solid #430404;
        display:none;
      
      }
      &.TUKENIYOR{
        background:#dd8d8d;
        color:white;
        border:0px solid #430404;
        display:none;
     
      }
      &.TUKENDI{
        background:red;
        color:white;
        border:0px solid #430404;
      }
      &.AZKALDI{
        background:#c4c447;
        color:white;
        border:0px solid #430404;
        display:none;
      }

      @media screen and (max-width:400px) {
        font-size:10px;
      }
    }
    .absolute-stock-qty{
      position:absolute;
      bottom: 55px;
      left: 15px;
      z-index:1;
      background:#f27a1a;
      color:#fff;
      padding:2.5px 5px;
      border-radius:5px;
      font-size:12px;

      &.MEVCUT{
        // background:green;
        color:white;
        border:0px solid #430404;
      
      }
      &.TUKENIYOR{
        // background:#dd8d8d;
        color:white;
        border:0px solid #430404;
     
      }
      &.TUKENDI{
        background:red;
        color:white;
        border:0px solid #430404;
      }
      &.AZKALDI{
        // background:#c4c447;
        color:white;
        border:0px solid #430404;
     
      }

      &.low-stock {
        background-color: red;
      }
      
     &.medium-stock {
        background-color: #c4c447;
      }
      
      &.high-stock {
        background-color: #ffa500; /* sarı */
      }
      
      &.full-stock {
        background-color: green;
      }

      @media screen and (max-width:400px) {
        font-size:10px;
      }
     
    }
    .overlay{
      position:absolute;
      opacity:0;
      top:20px;
      right:20px;
      display:flex;
      justify-content:center;
      align-items:center;
      transition:300ms all;
      z-index:100;
      color:#777;
      font-size:20px;
      border-radius:50%;
    }
    &:hover{
      .overlay{
        opacity:100;
        z-index:100;

      }
    }
    .image{
      margin:0;
      img{
        margin:0;
        padding:0;
        border-radius:10px;
      }
      width:300px;
      height:300px;
      background-size:cover;  
      background-position:center;
      background-repeat:no-repeat;
    }
    .brand{
      padding:0px 10px;
      margin-top:15px;
      width:100%;
      justify-content:space-between;
      align-items:center;
      display:flex;
      color:#000;
      font-size:13px;
      span{
        cursor:pointer;
        &:hover{
          text-decoration:underline;
          text-decoration-color:#ddd;
        }
      }
      @media screen and (max-width: 768px) {
        font-size:10px;
      }
      
      margin-bottom:6px;
      span:first-child{
        font-weight:bold;
      }
      span{
        
      }
    }
    .no-auth{
      margin-top:10px;
      text-align:center;
      font-size:10px;
    }
    .name{
      padding:0px 10px;
      padding-right:0;
      min-height:50px;
      color:#555;
      width:100%;
      font-size:14px;
      @media screen and (max-width: 768px) {
        font-size:12px;
      }
    }
    position:relative;
    .variants{
      position: absolute;
      right:10px;
      top:290px;
      width: 25px;
      display: flex;
      font-size:11px;
      align-items: center;
      justify-content:  space-between;
      .colors {
        display: flex;

        div {
          width: 10px;
          height: 10px;
          position: relative;
          border-radius: 150%;
        }

        div:first-child {
          background-image: linear-gradient(
            to right,
            #3023ae 0%,
            #53a0fd 48%,
            #b4ec51 101%
          );
        }
        div:last-child {
          margin-left: -7px;
          background-image: linear-gradient(
            to right,
            #6bceb4 0%,
            #f98cc2 48%,
            #fff781 101%
          );
        }
      }
    }
    .action{
      padding:0px 10px;
      padding-right:0;
      width:100%;
      min-height:50px;
      display:flex;
      justify-content:space-between;
      align-items:center;

      .btn{
        border:0;
        outline:0;
        .btn-groups{
          display:flex;
          align-items:center;
          border:1px solid #F27E21;
          border-radius:10px;

          button,input{
            width:25px;
            border:0;
            outline:0;
            padding:0;
            height:30px!important;
            text-align:center;
            font-size:12px;
            margin-bottom:5px;
          }
          button{
            border-radius:0px;
            &:first-child{
              border-top-left-radius:10px;
              border-bottom-left-radius:10px;
            }
            &:last-child{
              border-top-right-radius:10px;
              border-bottom-right-radius:10px;
            }
          }
        }
        button{
          border:0;outline:0;
          background:transparent;
          font-size:20px;
          color:#F27E21;
          background:#fdfdfd;
          border-radius:50%;
          transition:300ms all;
          padding:5px 10px;
          &:hover{
            background:#F27E21;
            color:#fff;
          }
        }
        button[disabled]{
          opacity:0.2;
          cursor:not-allowed;
        }
      }
      .unit{
        position:absolute;
        font-size:9px;
        right:46px;
        bottom:18px;
        @media only screen and (max-width: 1140px) {
          bottom:3.9%;
        }
      }
      .price{
        display:flex;
        align-items:center;
        color:#005BB4;
        margin-top:10px;
        span{
          margin-left:5px;
          font-size:13px;
        }
        @media only screen and (max-width: 1140px) {
          font-size:12px;
          span{
            font-size:10px;
          }
        }
        
        
      }
    }
    @media only screen and (max-width: 1840px) {
      width:300px;
      .image{
        width:300px;
        height:300px;
      }
    }
    @media only screen and (max-width: 1800px) {
      width:280px;
      .variants{
        top:250px;
      }
      .image{
        width:280px;
        height:280px;
      }
    }
    @media only screen and (max-width: 1850px) {
      width:280px;
      .image{
        width:280px;
        height:280px;
      }
    }
    @media only screen and (max-width: 1550px) {
      width:280px;
      .image{
        width:280px;
        height:280px;
      }
    }
    @media only screen and (max-width: 1500px) {
      width:260px;
      .image{
        width:260px;
        height:260px;
      }
    }
    @media only screen and (max-width: 1450px) {
      width:265px;
      .image{
        width:265px;
        height:265px;
      }
    }
    @media only screen and (max-width: 1401px) {
      width:250px;
      .image{
        width:250px;
        height:250px;
      }
    }
    @media only screen and (max-width: 1362px) {
      width:300px;
      .variants{
        top:270px;
      }
      .image{
        width:300px;
        height:300px;
      }
    }

    @media only screen and (max-width: 1250px) {
      width:275px;
      .variants{
        top:255px;
      }
      .image{
        width:275px;
        height:275px;
      }
    }

    @media only screen and (max-width: 1168px) {
      width:350px;
      .variants{
        top:350px;
      }
      .image{
        width:350px;
        height:350px;
      }
    }
    
    @media only screen and (max-width: 780px) {
      
      .variants{
        top:44vw;
      }
      width:46vw;
      .image{
        width:46vw;
        height:46vw;
      }
      
    }
  }
  .category-img-carousel{
    width:100%;
    height:120px;
    position:relative;
  }
  .filters {
    
    width: 100%;
    display: flex;
    justify-content: space-around;
    @media only screen and (max-width: 1140px) {
      flex-direction:column;
    }
    .left{
      width:20%;
      display:flex;
      .filter-btn{
        padding:5px 15px;
        margin-left:30px;
        cursor:pointer;
        background:#eee;
        &:hover{
          background:#fff;
        }
      }
      
    }
    .right{
      justify-content: flex-end;
      display: flex;
      width: 100%;
    }
    .total {
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      width:80px;
      margin-right: 20px;
      strong {
        margin: 0px 5px;
      }
    }
    align-items: center;
    button {
      border: none;
      outline: none;
      margin-right: 20px;
      padding: 2px 5px;
      position: relative;
      max-height:40px;
      .filter-list {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        background: #fff;
        z-index: 100;
        box-shadow: 0px 0px 10px #eee;
        div {
          padding: 7px 10px;
          &:not(:last-child) {
            border-bottom: 1px solid #ddd;
          }
        }
      }
    }
  }
  .filtered-list {
    margin-right: 30px;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    .filtered-item {
      background: #eee;
      &:hover {
        background: #fff;
      }
      &.all-remover{
        color: red;
      }
      
      padding: 5px;
      margin: 5px;
      display: flex;
      font-size: 10px;
      cursor: pointer;
      span {
        padding-left: 10px;
      }
    }
  }
  .brands{
    width: 100%;
      background-color: #fdfdfd;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 5px 0px;
      position: relative;
  }
  .filter-mobil-group{
    display:none;
  }
  .sort-btn{
    min-width:160px;
    
  }
  @media only screen and (max-width: 1140px) {
    .list{
      display:flex;
      
    }
    .filter-mobil-group{
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      
      .filter-btn{
        width:100%;
        height:40px;
        background:#fff;
        display:flex;
        align-items:center;
        justify-content:center;
        svg{

          margin-right:15px;
        }
      }
      
      .sort-btn-mobil{
        width:100%;
        *{
          text-decoration:none;
          color:#000;
        }
        text-decoration:none;
        color:#000;
        border:none;outline:none;
        border:1px solid #555!important;
        background: #fff;
        z-index:100;
        height:40px;
        border: none;
        outline: none;
        margin-right: 0px!important;

        padding: 2px 5px;
        position: relative;
        max-height:40px;
        .filter-list {
          position: absolute;
          flex-direction: column;
          width: 100%;
          left: 0;
          top: 100%;
          background: #fff;
          z-index: 100;
          box-shadow: 0px 0px 10px #eee;
          div {
            padding: 7px 10px;
            &:not(:last-child) {
              border-bottom: 1px solid #ddd;
            }
          }
        }
      }
    }
    .filter-btn{
      display:none;
    }
    .filter-btn.mobil{
      display:flex!important;
      border:1px solid #555;
      background-color:#000;
    }
    .sort-btn{
      display:none;
    }
    .sort-btn-mobil{
      
    }
  }
  .flag{
    width:23px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:15px;
    &:not(:last-child){
      margin-right:10px;
    }
    &.active{
      outline:2px solid green;
    }
  }

`;
export {GlobalStyle}