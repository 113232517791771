import axios from 'axios'

export const useAuthRequests = () => {

  const loginRequest = async (email,password) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_LOGIN}`,
      data: {
        username: email,
        password: password
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_LOGIN}`
      }
    })
  }
  const setCurrency = async (currency) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_SET_CURRENCY}?currencyUnit=${currency}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${localStorage.getItem('bilalototoken')}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_SET_CURRENCY}`
      }
    })
  }
  const getCustomer=async (token)=>{
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_CUSTOMER}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_REGISTER}`,
        'Authorization': `${token}`
      }
    })
  }
  const registerRequest = async (email,name,surname,company,phoneNumber,phoneNumber2,address,guarantorName,guarantorPhoneNumber) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_REGISTER}`,
      data: {
        name: name,
        surname: surname,
        email: email,
        companyTitle: company,
        phoneNumber: phoneNumber,
        // phoneNumber2:phoneNumber2,
        address: address,
        guarantorName: guarantorName,
        guarantorPhoneNumber: guarantorPhoneNumber,
      },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_REGISTER
        }`,
      },
    });
  }
  
  return {loginRequest,registerRequest,getCustomer,setCurrency}
}