import React,{useState,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { InvoiceContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faCheck, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Currency } from 'react-intl-number-format/dist/index.js';
import { Button } from 'react-bootstrap'
import { Sentry } from 'react-activity'
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

export default function Basket(props) {
  
  const {id} = useParams()


  const {getDiscover} = useProductRequests()
  const {getOrder,cancelOrder} = useOrderRequests()
  
  const { language } = useSelector((state) => state.site);
  const { basket } = useSelector((state) => state.cart);

  const [tetik,setTetik]=useState(false)
  const [discover, setDiscover] = useState([]);
  const [order,setOrder]=useState([])
  const [loading,setLoading]=useState(true)

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:1,count:20,sort:'RANDOM',sortedBy:''}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])
  const timesOrder = (id) => {
    const loader = toast.loading("Sipariş İptal Ediliyor...", {
      position: toast.POSITION.BOTTOM_RIGHT,
      isLoading: true,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: false,
    });
    cancelOrder(id)
      .then((res) => {
        toast.update(loader, {
          render: "Sipariş başarı ile iptal edildi",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
        getOrder(id).then((response) => {
          setOrder(response.data.data);
        });
      })
      .catch((error) => {
        if (error.response != undefined) {
          toast.update(loader, {
            render: error.response.data.message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          });
        } else {
          toast.update(loader, {
            render: "Sipariş iptal edilemedi",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          });
        }
      });
  };
  const [printOn,setPrintOn]=React.useState(false)
  const printPdf=()=>{
    setPrintOn(true)
    setTimeout(() => {
      setPrintOn(false)
    }, 5500);
  }
  useLayoutEffect(() => {
    
    if(order.length===0){

      getOrder(id).then((response)=>{
        setOrder(response.data.data)
        setLoading(false)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])
  const columns = [
    {
      name: "",
      width: "50px",
      selector: (row, index) => index + 1,
    },
    {
      name: "",
      width: "40px",
      selector: (row, index) => (
        <img onContextMenu={() => false} height={20*1.5} width={20} alt={row.image} />
      ),
    },
    {
      name: language.stockcode,
      selector: (row) => row.productId,
    },
    {
      name: language.name,
      selector: (row) => row.title,
    },
    // {
    //     name: 'Barkod',
    //     selector: row => row.barcode,
    // },

    {
      name: language.quantity,
      selector: (row) => row.quantity,
    },
    {
      name: language.unit,
      selector: (row) => row.unit,
    },
    {
      name: language.vat,
      selector: (row) => "%" + row.vatRate,
    },
    {
      name: language.price,
      selector: (row) => (
        <>
          {row.price}
          {order.order.currencyUnit}
        </>
      ),
    },
    {
      name: language.total,
      selector: (row) => (
        <>
          {row.totalPrice}
          {order.order.currencyUnit}
        </>
      ),
    },
    {
      name: language.vatInclude,
      selector: (row) => (
        <>
          {row.totalPriceWithVat}
          {order.order.currencyUnit}
        </>
      ),
    },
  ];
  const history=useHistory()
  return (
    <>
      {printOn ? (
        <>
          <iframe
            src={"/yazdir/siparisler/" + id}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Header />

      <div>
        <SlideProductContainer>
          <InvoiceContainer>
            <div className="go-back-btn"  style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: 10 }} />
              <span style={{ fontWeight: "bold",color:'#333',fontSize:13 }}>{language.goPrevious}</span>
            </div>
            <div
              style={{
                width: "100%",
                minHeight: 50,
                marginBottom: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {order.order != undefined &&
              order.order.cancelable != undefined &&
              order.order.cancelable == true && false ? (
                <button
                  className="cancel invoices"
                  onClick={() => {
                    if (tetik) {
                      timesOrder(id);
                    } else {
                      setTetik(true);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={tetik ? faCheck : faTimes} />{" "}
                  {tetik ? "İPTALİ ONAYLIYORUM" : "İPTAL ET"}
                </button>
              ) : (
                <></>
              )}

              <button
                className="invoices"
                style={{
                  color: "#000",
                  border: "none",
                  outline: "1px solid #eee",
                  marginRight: 20,
                  marginLeft: 20,
                }}
                onClick={() => {
                  if (!printOn) {
                    printPdf();
                  }
                }}
              >
                {printOn ? <Sentry /> : "PDF"}
              </button>
            </div>

            {order == null || (order.length === 0 && loading) ? (
              <>
                <div className="loader">
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
                <div className="loader" style={{ marginTop: -100 }}>
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
              </>
            ) : (
              <>
                <div className="head">
                  <div className="leftHead">
                    <div>
                      <span>{language.date}</span>
                      <span>:</span>
                      <span>{order.order.date}</span>
                    </div>

                    <div>
                      <span>{language.orderNo}</span>
                      <span>:</span>
                      <span>{order.order.orderCode}</span>
                    </div>

                    <div>
                      <span>{language.status}</span>
                      <span>:</span>
                      <span>{order.order.status}</span>
                    </div>
                  </div>

                  <div className="rightHead">
                    <h4>
                      {JSON.parse(localStorage.getItem("customer")).title}
                    </h4>
                    <img
                      onContextMenu={() => false}
                      src={order.order.image}
                      height={70}
                    />
                  </div>
                </div>

                <DataTable columns={columns} data={order.items} />

                <div className="total">
                  <div className="space"></div>
                  <div>
                    <span>{language.total} {language.amount}</span>
                    <span>:</span>
                    <>
                      {order.order.totalPrice}
                      {order.order.currencyUnit}
                    </>
                  </div>
                </div>

                {/* <Button>YAZDIR</Button> */}
              </>
            )}
          </InvoiceContainer>
        </SlideProductContainer>

        <SlideProductContainer>
          <h1>{language.discovery}</h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
