import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { SlideProductContainer } from "../styles/Home";
import SlideProducts from "../components/Slider/Products";
import Header from "../components/Navigation/Header";
import BottomNav from "../components/Navigation/Bottom";
import Footer from "../components/Navigation/Footer";
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader";

import { useProductRequests } from "../helpers/ProductRequests";
import { useOrderRequests } from "../helpers/OrderRequests";
import { DetailContainer } from "../styles/Product";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Currency } from "react-intl-number-format/dist/index.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Sentry } from "react-activity";
import { toast } from "react-toastify";

export default function Basket(props) {
  const { getDiscover } = useProductRequests();
  const { getOrders, cancelOrder } = useOrderRequests();

  const history = useHistory();

  const { basket } = useSelector((state) => state.cart);
  const { language } = useSelector((state) => state.site);

  const [loading, setLoading] = useState(true);
  const [discover, setDiscover] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderTetik, setOrderTetik] = useState([]);

  useLayoutEffect(() => {
    if (discover.length === 0) {
      getDiscover({ page: 1, count: 20, sort: "RANDOM", sortedBy: "" })
        .then((response) => {
          setDiscover(response.data.data.items);
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    }
  }, []);
  const timesOrder = (id) => {
    const loader = toast.loading("Sipariş İptal Ediliyor...", {
      position: toast.POSITION.BOTTOM_RIGHT,
      isLoading: true,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: false,
    });
    cancelOrder(id)
      .then((res) => {
        toast.update(loader, {
          render: "Sipariş başarı ile iptal edildi",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
        getOrders().then(async (response) => {
          setOrders(response.data.data);
        });
      })
      .catch((error) => {
        if (error.response != undefined) {
          toast.update(loader, {
            render: error.response.data.message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          });
        } else {
          toast.update(loader, {
            render: "Sipariş iptal edilemedi",
            type: "error",
            isLoading: false,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          });
        }
      });
  };
  useLayoutEffect(() => {
    
    if (orders.length === 0) {
      getOrders()
        .then(async (response) => {
          let data = [];
          const seter = await response.data.data.map((x) => {
            data.push({ id: x.orderId, tetik: false });
          });
          setOrderTetik(data);
          setLoading(false);
          setOrders(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error.response);
        });
    }
  }, []);
  const columns = [
    {
      name: language.date,
      selector: (row) => row.date,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Kod",
    //   selector: (row) => row.orderCode,
    //   sortable: true,
    // },
    {
      name: language.description,
      selector: (row) => row.detail,
      sortable: true,
    },

    {
      name: language.amount,
      selector: (row) => (
        <span style={{ color: "green", fontWeight: "bold" }}>
          <>
            {row.totalPrice}
            {row.currencyUnit}
          </>
        </span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: language.status,
      selector: (row) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Link
            className={row.status + " invoices"}
            to={"/siparisler/" + row.orderId}
          >
            {" "}
            {(row.status == null
              ? "İşlem Türü Boş"
              : row.status
            ).toUpperCase()}{" "}
          </Link>
          {row.cancelable != undefined && row.cancelable == true && false ? (
            <button
              className="invoices cancel"
              onClick={() => {
                if (
                  orderTetik.filter((x) => x.id == row.orderId)[0].tetik == true
                ) {
                  timesOrder(row.orderId);
                  return;
                }
                setOrderTetik(
                  orderTetik.map((x) => {
                    if (x.id == row.orderId) {
                      return { id: x.id, tetik: true };
                    }
                    return x;
                  })
                );
              }}
            >
              <FontAwesomeIcon
                icon={
                  orderTetik.filter((x) => x.id == row.orderId)[0].tetik == true
                    ? faCheck
                    : faTimes
                }
              />{" "}
              {orderTetik.filter((x) => x.id == row.orderId)[0].tetik == true
                ? "İPTALİ ONAYLIYORUM "
                : "İPTAL"}
            </button>
          ) : (
            <></>
          )}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: language.detail,
      width: "100px",
      selector: (row) => (
        <div
          style={{
            display: "flex",

            flexWrap: "wrap",
            justifyContent: "space-around",
            fontWeight: "normal",
          }}
        >
          <Link
            className={row.status + " invoices Beklemede"}
            to={"/siparisler/" + row.orderId}
          >
            {" "}
            <FontAwesomeIcon icon={faEye} />{" "}
            <span style={{ fontWeight: "normal" }}>{language.inspect}</span>
          </Link>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = React.useState("");
  const [printOn, setPrintOn] = React.useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = orders.filter((item) => {
    return (
      item.date.toLowerCase().includes(filterText.toLowerCase()) ||
      item.detail.toLowerCase().includes(filterText.toLowerCase()) ||
      (item.orderCode + "").toLowerCase().includes(filterText.toLowerCase()) ||
      (item.orderId + "").toLowerCase().includes(filterText.toLowerCase()) ||
      item.status.toLowerCase().includes(filterText.toLowerCase()) ||
      (item.totalPrice + "").toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const printPdf = () => {
    setPrintOn(true);
    setTimeout(() => {
      setPrintOn(false);
    }, 5500);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <button
          style={{
            color: "#000",
            border: "none",
            outline: "1px solid #eee",
            marginRight: 20,
            height: 30,
            width: 70,
          }}
          onClick={() => {
            if (!printOn) {
              printPdf();
            }
          }}
        >
          {printOn ? <Sentry /> : "PDF"}
        </button>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 6px #000",
            padding: 5,
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            style={{ border: "none", outline: "none", textAlign: "right" }}
            placeholder={language.search}
            className="input-group"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </>
    );
  }, [filterText, printOn, resetPaginationToggle, orders]);

  return (
    <>
      {printOn ? (
        <>
          <iframe
            src={"yazdir/siparisler"}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Header />

      <div>
        <SlideProductContainer>
          {orders.length < 1 && loading == true ? (
            <>
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
              <div className="loader" style={{ marginTop: -100 }}>
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
            </>
          ) : (
            <DataTable
              pagination
              subHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[50, 100, 200]}
              noDataComponent={<>Sipariş kaydı bulunamadı</>}
              subHeaderComponent={subHeaderComponentMemo}
              columns={columns}
              data={filteredItems}
            />
          )}
        </SlideProductContainer>

        {/* <SlideProductContainer>
          <h1>Keşfet</h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer> */}
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
