import styled from "styled-components";

const AuthPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 20vh;
  .logo{
    position: absolute;
    top:35px;
    display: flex;
    cursor: pointer;
    width:100%;
    height:auto;
    justify-content: center;
    align-items: center;
    &:hover{
        filter:drop-shadow(0px 0px 1px  ${({theme})=>theme.elementShadow});
    }
  }
  .form-navigator{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    width:100%;
    align-items: center;
    padding:0;
    &::before{
      display:none;
    }
  }
`

const Login=styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  padding-bottom: 20vh;
  .error-area{
    background-color: ${({theme})=>theme.error};
    color: ${({theme})=>theme.errorText};
    padding: 10px 0px;
  }
  .success-area{
    background-color: ${({theme})=>theme.success};
    color: ${({theme})=>theme.successText};
    padding: 10px 0px;
  }
  form{
    label{
      cursor:pointer;
    }
    div{
        border:0px;
        text-align:center;
        cursor:pointer;
        margin-top:10px;
        width:100%;
        &:before{
          content:'';
          border-bottom:0px!important;
        }
      
    }
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    input{
      background-color:${({theme})=>theme.header};
      border-radius:5px;
      border:0px!important;
      outline:0px!important;
      
    }
    
    .authBtn{
      width:96%;
      padding:10px 0px;
      margin-top:10px;
    }
    a{
      color:${({theme})=>theme.color};
      text-align:center;
      margin-top:20px;
      &:hover{
        color:${({theme})=>theme.opacityColor};
      }
      text-decoration:none;
    }
    width:35%;
    @media only screen and (max-width:1140px){
      width:80%;
    }

  }
`
const LoginBoxContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .robotkafasi {
    position: absolute;
    transform: scale(0.5);
    z-index: -1;
    transition: 300ms all;
  }
  .robotkafasi.hide {
    top: -80px;
  }
  .robotkafasi.show {
    top: -170px;
  }
  .box {
    position: relative;
    .close {
      position: absolute;
      top: -15px;
      right: -15px;
      background-color: red;
      border-radius: 10px;
      padding: 5px 15px;
      cursor: pointer;
      font-size: 24px;
      color: #fff;
      transition: 300ms all;
      box-shadow: 0px 0px 1px red;
      &:hover {
        background-color: darkred;
        box-shadow: 0px 0px 3px red;
      }
    }
    width: 30%;

    height: auto;
    min-height: 300px;
    border-radius: 10px;
    border: 3px solid #ddd;
    background-color: ${({ theme }) => theme.header};
    margin-top: ${(props) =>
      props.register != undefined && props.register != null ? 0 : -140}px;

    form {
      width: 100%;
      .success-area {
        width: 100%;
        padding: 10px;
        background-color: rgba(0, 255, 0, 0.2);
      }
      .error-area {
        width: 100%;
        padding: 10px;
        background-color: rgba(255, 0, 0, 0.2);
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 80px;
      padding-bottom: 90px;
      h1 {
        margin-bottom: 20px;
        text-align: center;
        font-size: 23px;
        margin-top: 50px;
        color: #333;
      }
      button {
        display: block;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: 0;
        outline: 0;
        color: #fff;
        font-weight: bold;
        background: #ff7f13;
        transition: 300ms all;
        margin-top: 15px;
        &:hover {
          background: #ff6d13;
        }
      }
      .form-box {
        width: 100%;
        position: relative;
        span {
          position: absolute;
          top: 36%;
          right: 8px;
          cursor: pointer;
        }
        display: flex;
        border: 1px solid #eee;
        label {
          transform: scale(1);
          transition: 300ms all;
        }
        input {
          margin-top: 10px;
          width: 100%;
          &:not(:first-child) {
            margin-left: 10px;
          }
          margin-bottom: 10px;
          height: 50px;
          padding: 10px;
          transition: 300ms all;
          outline: 0;
          border-radius: 5px;
          border: 0;
          background: #fff;
          &::placeholder {
            color: #333;
          }
        }
        &:focus-within {
          label {
            transform: scale(0);
          }
          input {
            box-shadow: 0px 0px 13px #fff;
          }
        }
      }
    }
    @media only screen and (max-width: 1140px) {
      width: 93%;
      .close {
        font-size: 24px;
        padding: 0px 10px;
        background-color: #fff;
        color: #000;

        box-shadow: none;
        border: none;
        right: -12px;
        top: -22px;
      }
      form {
        input {
        }
        padding: 10px 10px;
        padding-bottom: 70px;
      }
    }
  }
`;
export {Login,AuthPage,LoginBoxContent}