import { faCartPlus, faMinus, faPlus, faTrash, faSearch, faAngleRight, faSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Currency } from "react-intl-number-format/dist/index.js";
import { useCartRequests } from '../../helpers/CartRequests';
import { setBasket } from "../stores/Cart";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function ProductCard({ product,setOpenedBox, variant =false}) {

  const {basket}=useSelector(state=>state.cart)
  const { language} = useSelector((state) => state.site
  );

  const [loading,setLoading]=React.useState(false)

  const dispatch = useDispatch();
  const history = useHistory()
  const { addCart, getCart, extractionCart, decrementCart } = useCartRequests();
  const [count, setCount] = React.useState(0);
  const [updatedable, setUpdatedable] = React.useState(false);
  const getBasket = () => {
    if (variant == false) {
      getCart().then((res) => {
        setLoading(false);
        setUpdatedable(false)
        setCount(0)
        dispatch(setBasket(res.data.data));
      });
    }
  };
  
  const handleChange = (e) => {
    setUpdatedable(true);
    setCount(e.target.value);
  };

  const addToBasket = async (prod, e = null) => {
    e.stopPropagation();
    setLoading(true);
    const loader = toast.loading("Ürün Sepete Ekleniyor...", {
      position: toast.POSITION.BOTTOM_RIGHT,
      isLoading: true,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: false,
    });
    addCart([{ id: prod.id, count: updatedable? count: 1 }])
      .then((res) => {
        getBasket();
        const newBasket = toast.update(loader, {
          render: res.data.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response != undefined && err.response.data.message != undefined) {
          toast.update(loader, {
            render: err.response.data.message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: false,
            draggable: true,
          });
          return
        }
        toast.update(loader, {
          render: "Sepet Güncellenemedi",
          type: "error",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
      });
  };

  const removeItem =async (item) => {
    setUpdatedable(false);
    setCount(0);
    const newbasket = await basket.filter((sub) => {
      return sub.productId !== item.id;
    });
    setLoading(true);
    extractionCart(item.id)
    .then((response) => {
      setLoading(false);
      dispatch(setBasket(newbasket));
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
  }

  const decrease =async (item) => {
    setLoading(true);
    setUpdatedable(false);
    setCount(0)
    const newbasket = await basket.map((sub) => {
      if (sub.productId === item.id) {
        return {
          ...sub,
          quantity: sub.quantity - 1,
          total: sub.total - sub.price * 1,
        };
      }
      return sub;
    });
    decrementCart(item.id, 1)
    .then((response) => {
      setLoading(false);
      dispatch(setBasket(newbasket));
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
  }
  const removeTurkish = (text) => {
    //replace al spaces
    return text.replace('Ü','U').replace('ü','u').replace('İ','I').replace('i','i').replace('O','O').replace('o','o').replace('G','G').replace('g','g').replace(/\s/g,'')
  }
  return (
    <div
      // href={`/urun/${product.slug}`}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/urun/${product.slug}`, {
          prevPage: history.location.pathname,
        });
      }}
      className="product-card"
    >
      <div
        className={
          "absolute-stock-qty " + removeTurkish(product.stockText) 
          // (product.availableStock <= 0 ? " out-of-stock" : " in-stock")
        }
      >
        <span>{product.stockText}</span>
      </div>
      <div
  className={`absolute-stock-qty ${removeTurkish(product.stockText)} ${
    product.availableStock === 0
      ? 'out-of-stock'
      : product.availableStock <= 3
      ? 'low-stock'
      : product.availableStock <= 10
      ? 'medium-stock'
      : product.availableStock <= 18
      ? 'high-stock'
      : 'full-stock'
  }`}
  style={{ display: product.availableStock <= 0 ? 'none' : 'block' }}
>
  {/* <span>{product.availableStock + ' ' + language.qty.toUpperCase()}</span> */}
  
  {parseInt(product.availableStock) > 0 ? (
  <span>{product.availableStock + ' ' + language.qty.toUpperCase()}</span>
) : (
  '0' + ' ' + language.qty.toUpperCase()
)}
  
</div>


      <div
        className="overlay"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setOpenedBox(true));
        }}
      >
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <img
        src={
          product.image != "" && product.image != null
            ? product.image
            : "https://entegroid.com/noimage"
        }
        alt="logo"
        style={{ width: "100%" }}
      />

      <div className="variants">
        {/* <div className="colors">
          <div></div>
          <div></div>
        </div> */}
        <div>{product.variantCount}</div>
      </div>
      <div className="brand">
        <span
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/marka/${product.groupSlug}`);
          }}
        >
          {product.subGroup}
          {product.subSubGroup != "" ? (
            <>
              {" - "}
              {product.subSubGroup}
            </>
          ) : (
            ""
          )}
        </span>
        <span
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/urun/${product.slug}`);
          }}
        >
          {language.stockcode} : {product.id}
        </span>
      </div>

      <div className="name">
        {language.lang != "tr" && product.title2 != null && product.title2 != ""
          ? product.title2
          : product.title}
      </div>

      {localStorage.getItem("bilalototoken") != null ? (
        <div className="action">
          <div className="price" style={{ flexDirection: "column" }}>
            <span className="sale-price">
              <div style={{ fontSize: 20, margin: 0 }}>
                <Currency style={{ fontSize: 30 }}>
                  {product.salePrice}
                </Currency>
                <div style={{ fontSize: 12 }}>{language.plusVat}</div>
              </div>
            </span>
          </div>
          <div className="btn">
            {basket != null &&
            basket.length > 0 &&
            basket.filter((x) => x.productId == product.id).length > 0 ? (
              <div className="btn-groups">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (loading) {
                      return;
                    }
                    if (
                      basket.filter((x) => x.productId == product.id)[0]
                        .quantity == 1
                    ) {
                      removeItem(product);
                    } else {
                      decrease(product);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      basket.filter((x) => x.productId == product.id)[0]
                        .quantity == 1
                        ? faTrash
                        : faMinus
                    }
                  />
                </button>
                <input
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  type="text"
                  value={
                    updatedable
                      ? count
                      : basket.filter((x) => x.productId == product.id)[0]
                          .quantity
                  }
                />
                <span className="unit">Adet</span>
                <button
                  disabled={
                    basket.filter((x) => x.productId == product.id)[0]
                      .quantity ==
                    product.availableStock / product.unit2coefficient
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (loading) {
                      return;
                    }
                    addToBasket(product, e);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (loading) {
                    return;
                  }
                  addToBasket(product, e);
                }}
              >
                <FontAwesomeIcon icon={faCartPlus} />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="no-auth">
          Fiyatları görmek için <strong>giriş</strong> yapın
        </div>
      )}
    </div>
  );
}
