import { createSlice } from "@reduxjs/toolkit";

export const cart = createSlice({
  name:'cart',
  initialState:{
    basket:localStorage.getItem('cart')!=null && localStorage.getItem('cart')!="undefined"?JSON.parse(localStorage.getItem('cart')):null,
  },
  reducers:{

    setBasket: (state,action) => {
      localStorage.setItem('cart',JSON.stringify(action.payload))
      state.basket=action.payload
    },
    emptyCart: (state,action) => {
      state.basket=null
      localStorage.removeItem('cart')
    },

    
  }
})

export const {setBasket,itemIncrement,emptyCart} = cart.actions
export default cart.reducer