import React,{useState} from 'react'
import { LoginBoxContent } from '../../styles/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faEye, faEyeSlash, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuthRequests } from '../../helpers/AuthRequests';
import { useDispatch } from 'react-redux';
import { useCartRequests } from '../../helpers/CartRequests';
import { setBasket } from '../stores/Cart';
import { setCustomer, loginChange } from "../stores/Auth";
import { Bounce, Sentry } from 'react-activity';

import robotkafasi from '../../images/dehasoft-robot-kafasi.png'
import { setLoginPopUp,setRegisterPopUp } from '../stores/Site';
export default function RegisterBox({open,setOpen}) {
    
  const { loginRequest, getCustomer } = useAuthRequests();
  const {getCart}=useCartRequests();

  const dispatch = useDispatch();
  
  const [email,setEmail]=useState('')

  const [password,setPassword]=useState('')

  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorPhoneNumber, setGuarantorPhoneNumber] = useState("");
  const [surname, setSurname] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  
  const [error,setError]=useState('')
  const [success,setSuccess]=useState('')
  const [loading,setLoading]=useState(false)
  const [checked,setChecked]=useState(false)

  const loginIn=async(e)=>{
    e.preventDefault();
    const debug=true
    if(loading){
      return
    }else if(email=='' || email.length<2 && debug==false){
      setError('Email adresinizi giriniz.')
    }else if(password=='' || password.length<6 && debug==false){
      setError('Şifrenizi giriniz.')
    }else{
      setLoading(true)

      loginRequest(email,password)
      .then( async(response)=> {
        setError("");
        setSuccess("Giriş işlemi başarılı lütfen bekleyiniz...");
        setEmail('')
        setPassword('')
        setChecked(false)
        localStorage.removeItem("bilalotoroutes")
        dispatch(loginChange({
          token:response.data.data.authenticationKey,
          login:true,
        }))
        getCustomer(response.data.data.authenticationKey).then(res=>{
          dispatch(setCustomer(res.data.data))
        })
        getCart().then((res) => {
          dispatch(setBasket(res.data.data));
        });
        setTimeout(() => {

          // history.push('/')
          if(window.location.href.indexOf("localhost")!=-1){
            window.location = "http://localhost:3000"
            return
          }
          window.location = process.env.REACT_APP_FRONT_URL;
        }, 1600);

      })
      .catch( (error)=> {
        setError(error.response.data.message)
        setLoading(false)
      })

      

    }

    
  }
  const [hide,setHide]=useState(true)
  const changeLoginPopUp=()=>{
    dispatch(setLoginPopUp());
    dispatch(setRegisterPopUp());
  }

  if(!open){
    return <></>;
  }
  return (
    <LoginBoxContent register={true} onClick={() => setOpen(false)}>
      <div className="box" onClick={(e) => e.stopPropagation()}>
        <div className="close" onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <img
          className={"robotkafasi" + (hide ? " hide" : " show")}
          src={robotkafasi}
        />

        <form autoComplete="off" onSubmit={loginIn}>
          <h1>Kayıt Formu</h1>

          <div className="form-box">
            <input
              type="text"
              placeholder="Ad"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Soyad"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          

          <div className="form-box">
            <input
              type="text"
              placeholder="Adres"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div className="form-box">
            <input
              type="text"
              placeholder="E Posta"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Telefon Numarası"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <div className="form-box">
            <input
              placeholder="Parola"
              type={hide ? "password" : "text"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={() => setHide(!hide)}>
              <FontAwesomeIcon icon={hide ? faEye : faEyeSlash} />
            </span>
          </div>

          {error && (
            <div className="error-area">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ marginRight: 20 }}
              />
              <span>{error}</span>
            </div>
          )}
          {success && (
            <div className="success-area">
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: 20 }} />
              <span>{success}</span>
            </div>
          )}
          {loading == true ? (
            <Sentry size={20} color="green" />
          ) : (
            <button type="submit">Kayıt Ol</button>
          )}
        </form>
        <div
          onClick={changeLoginPopUp}
          style={{
            paddingBottom: 10,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Zaten üye misin ?{" "}
          <div style={{ marginLeft: 5, fontWeight: "bold", cursor: "pointer" }}>
            Giriş Yap
          </div>
        </div>
      </div>
    </LoginBoxContent>
  );
}
