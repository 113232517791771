import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{useEffect,useRef} from 'react'
import Carousel from 'react-elastic-carousel'
import { useDispatch } from 'react-redux';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export default function ProductImage({
  currentImageIndex,setCurrentIndex,
  items,
  openedBox,
  setOpenedBox,
}) {
  const carouselRef = useRef(null);
  const dispatch = useDispatch();
  let resetTimeout;

  React.useEffect(() => {
    carouselRef.current.goTo(currentImageIndex)
  },[currentImageIndex])
  return (
    <>
      <Carousel
        ref={carouselRef}
        pagination={true}
        autoPlaySpeed={3050}
        disableArrowsOnEnd={false}
        enableAutoPlay={true}
        className="carousel-an-con"
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <div
              className="page-item"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {pages.map((page) => {
                const isActivePage = activePage === page;
                return (
                  <div
                    key={page}
                    onClick={() => onClick(page)}
                    active={isActivePage}
                    style={{
                      border:
                        "1px solid " +
                        (isActivePage ? "#F27A1A" : "transparent"),
                      cursor: "pointer",
                      marginRight: 10,
                      marginTop: 10,
                      borderRadius: 7,
                    }}
                    className="product-page-items"
                  >
                    <img
                      src={items[page]}
                      style={{
                        borderRadius: 20,
                        width: 100,
                        minWidth: 100,
                        maxWidth: 100,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        }}
        onChange={() => {
          //console.log(carouselRef.current.state.activeIndex)
        }}
        // itemsToShow={width<1140 ? (width>980 ? 3 : 1) : 5}
      >
        {(items == null || items.length == 0
          ? [
              "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png",
              "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png",
            ]
          : items
        ).map((item, index) => {
          return (
            <React.Fragment
              key={"simage-" + Math.floor(Math.random() * 99999)}
              // className="carousel-an-con"
            >
              <div
                className="carousel-animate"
                onClick={() => setOpenedBox(!openedBox)}
              >
                <FontAwesomeIcon icon={faSearch} color="#fff" />
              </div>

              <img
                onClick={() => setOpenedBox(!openedBox)}
                className="detail-image"
                style={{ cursor: "zoom-in" }}
                src={item}
              />
            </React.Fragment>
          );
        })}
      </Carousel>

      <div
        style={{
          right: openedBox ? 200 : "-200vw",
          transform: `scale(${openedBox ? 1 : 0})`,
        }}
        className="detail-light-pagination"
      >
        {items.map((item, index) => {
          const isActivePage = currentImageIndex == index ? true : false;
          return (
            <div
              key={"item-" + item}
              active={isActivePage}
              onClick={() => {
                setCurrentIndex(index);
              }}
              style={{
                border:
                  "2px solid " + (isActivePage ? "#F27A1A" : "transparent"),
                cursor: "pointer",
                marginTop: 10,
                borderRadius: 20,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${item})`,
                  width: 100,
                  height: 100,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  borderRadius: 20,
                }}
              ></div>
            </div>
          );
        })}
      </div>
    </>
  );
}
