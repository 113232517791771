import React,{useState,useEffect} from 'react'
import {  useParams,useLocation } from 'react-router-dom'

import ProductList from '../components/Content/ProductList'
import {useProductRequests} from '../helpers/ProductRequests'
import { Helmet } from 'react-helmet'
import Footer from '../components/Navigation/Footer'
import { BottomNav } from '../styles/Header'
import Header from '../components/Navigation/Header';
import {ProductContent} from '../styles/Content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SliderContainer from '../components/Slider'
import {SliderSwitch,CarouselContainer,SlideProductContainer} from '../styles/Home'
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faArrowLeft, faArrowRight, faBarcode, faBars, faCheck, faFileExcel, faFilePdf, faFilter, faPalette, faSearch, faSlidersH, faStarHalfAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import CategoryItem from '../components/Content/Category/FilterGroup/CategoryItem';
import ProductItem from '../components/Product/ProductCard'
import Carousel, { consts } from "react-elastic-carousel";
import useWindowDimensions from '../components/useWindowDimensions'
import { Sentry } from 'react-activity'
import ColorItem from '../components/Content/Category/FilterGroup/ColorItem';
import SoleItem from "../components/Content/Category/FilterGroup/SoleItem";
import BrandItem from "../components/Content/Category/FilterGroup/BrandItem";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { SwapButton } from '../styles/Product'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { read, utils, writeFile } from "xlsx";
import * as ExcelJS from "exceljs";
import jsPDF from "jspdf";

import { setProducts, setCategories,setPage,setLoading,setAllData,setSelected,setBrands,setSelectedBrands,setColors,setSoles,setCurrentIndex,setSelectedColors,setSelectedSoles,setMinPrice,setMaxPrice,setFilterOpen,setBrandOpen,setColorOpen,setSort,setBrandSearchTxt,setOpenedBox,setColorSearchTxt } from '../components/stores/Product'
export default function Category(props) {
  const history=useHistory()
  const dispatch=useDispatch()
  const [alwaysStock, setAlwaysStock] = useState(false);
  const { language } = useSelector((state) => state.site);
  const {slider}=useSelector(state=>state.site)
  const [discover,setDiscover]=useState([])
  const [sliders,setSliders]=useState('test1')
  // const [alwaysNoneStock, setAlwaysNoneStock] = useState(false);
  const {
    soles,
    products,
    page,
    brandSearchTxt,
    colorSearchTxt,
    currentImageIndex,
    openedBox,
    filterOpen,
    brandOpen,
    colorOpen,
    logLoad,
    colors,
    brands,
    minPrice,
    maxPrice,
    selectedSoles,
    selectedColors,
    selectedBrands,
    selected,
    loading,
    allData,
    sort,
    categories,
  } = useSelector((state) => state.product);
  const location = useLocation();

  const width = useWindowDimensions().width;
  const { slug } = useParams();



  const { getProducts, getCategories, getBrands,getSliders, getColors, getSpecialCodes } =
    useProductRequests();

  const simpleCount=60
  const [opened, setOpened] = useState("");
  const [open, setOpen] = useState(width<1140 ? false :true);
  const [backed,setBacked]=useState(false)

  const [images,setImages]=useState([])
  useEffect(() => {

    if (images == null || images.length == 0) {
      //REACT_APP_GET_SLIDERS
      localStorage.setItem("slider", "");
      const sliderCheck = localStorage.getItem("sliders");
      if (sliderCheck == null) {
        getSliders().then((res) => {
          setImages(res.data.images);
        });
      }
    }
  }, [sliders]);

  const calculateAvailableNumber=()=>{
    // console.log('HOME',alwaysStock, alwaysNoneStock);
    // if (alwaysStock && alwaysNoneStock) {
    //   return 1;
    // } else if (alwaysStock && !alwaysNoneStock) {
    //   return 2;
    // } else if (alwaysNoneStock && !alwaysStock) {
    //   return 3;
    // } else {
    //   return 1;
    // }

    if(alwaysStock == true){
      return 2;
    }
  }

  useEffect(() => {

    if( (location.state==undefined || (location.state.back==undefined || location.state.back==null)) || (location.state!=undefined && location.state.sub!=undefined) || products.length==0){
      
      dispatch(setPage(1));
      dispatch(setSelectedColors([]));
      dispatch(setSelectedBrands([]));
      getCategories().then((res) => {
        dispatch(setLoading(false));
        dispatch(setSelected([slug]));
        
          dispatch(setCategories(res.data.data));
      });
    
      
    }

  }, [slug,alwaysStock]);
    
  // }, [slug,alwaysNoneStock,alwaysStock]);
  
  const getProductReq=()=>{
    getProducts({
      availableStockFilter:calculateAvailableNumber(),
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:[],
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices:minPrice==null && maxPrice==null ? false:true,
      minPrice: minPrice==null ? -1:minPrice,
      maxPrice: maxPrice==null ? -1:maxPrice,
    })
      .then((res) => {
        dispatch(setAllData(res.data.data));
        if (width < 1000) {
          // setOpen(false)
        }
        dispatch(setLoading(false));
        dispatch(setProducts([...res.data.data.items]));
        dispatch(setPage(1));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }
  useEffect(() => {
    if (loading != false) {
        return;
    }
    dispatch(setLoading(true));
    dispatch(setProducts([]))
    
    getProducts({
availableStockFilter:calculateAvailableNumber(),
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:[],
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        dispatch(setAllData(res.data.data));
        if (width < 1000) {
          // setOpen(false)
        }
        dispatch(setLoading(false));
        dispatch(setProducts([...res.data.data.items]));
        dispatch(setPage(1));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });

    getBrands(typeof selected=="string"?[selected]:selected).then((res) => {
      if (selectedBrands.length != 0) {
        dispatch(
          setSelectedBrands(
            selectedBrands.filter(
              (x) => res.data.data.filter((y) => y.slug == x).length > 0
            )
          )
        );
      }

      dispatch(setBrands(res.data.data));
    });
    

    
  
  },[selected])

  useEffect(() => {
    getColors({
      groupSlugList:[],
    }).then((res) => {
      dispatch(setColors(res.data.data));
    });
  },[selected,selectedBrands,slug])

  useEffect(() => {
    if (loading == true) {
      return;
    }
    dispatch(setProducts([]));
    dispatch(setLoading(true));
    
    getProducts({
availableStockFilter:calculateAvailableNumber(),
      page: 1,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,

      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:[],
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        if (width < 1000) {
          // setOpen(false);
        }
        dispatch(setLoading(false));
        dispatch(setAllData(res.data.data));

        dispatch(setPage(1));
        dispatch(setProducts([...res.data.data.items]));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });

  },[selectedBrands,selectedColors,selectedSoles])

  const getNewPage = (pg) => {
    if(loading==true){
      return
    }
    dispatch(setLoading(true));
    getProducts({
availableStockFilter:calculateAvailableNumber(),
      page: pg,
      count: simpleCount,
      specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
      specialCodeIdList: selectedSoles,
      colorList: selectedColors,
      groupSlugList:[],
      brandSlugList: selectedBrands,
      sort: sort.sort,
      sortedBy: sort.sortedBy,
      betweenPrices: minPrice == null && maxPrice == null ? false : true,
      minPrice: minPrice == null ? -1 : minPrice,
      maxPrice: maxPrice == null ? -1 : maxPrice,
    })
      .then((res) => {
        if (width < 1000) {
          // setOpen(false);
        }
        dispatch(setAllData(res.data.data));
        dispatch(setPage(pg));
        dispatch(setProducts([...products, ...res.data.data.items]));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  }

  useEffect(() => {
    if(loading==false){
      dispatch(setLoading(true));
      dispatch(setProducts([]));
      getProducts({
availableStockFilter:calculateAvailableNumber(),
        page: 1,
        count: simpleCount,
        specialCodeKey: selectedSoles.length == 0 ? 0 : 2,
        specialCodeIdList: selectedSoles,
        colorList: selectedColors,
        groupSlugList:[],
        brandSlugList: selectedBrands,
        sort: sort.sort,
        sortedBy: sort.sortedBy,
        betweenPrices: minPrice == null && maxPrice == null ? false : true,
        minPrice: minPrice == null ? -1 : minPrice,
        maxPrice: maxPrice == null ? -1 : maxPrice,
      })
        .then((res) => {
          if (width < 1000) {
            setOpen(false);
          }
          dispatch(setAllData(res.data.data));
          dispatch(setPage(1));
          dispatch(setProducts(res.data.data.items));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
        });
    }
  },[sort])

  useEffect(() => {
    
    getSpecialCodes(2).then((res) => {
      dispatch(setSoles(res.data.data));
    });
  },[])

  useEffect(() => {
    //
    
    
  }, [selected,selectedBrands,selectedColors,selectedSoles,minPrice,maxPrice,sort,page])
  

  const getSlugToName = (rslug) => {
    if (categories.length == 0) {
      return "";
    }
    for (const category of categories) {
      if (category.slug === rslug) {
        return category.name;
      }
      for (const subGroup of category.subGroups) {
        if (subGroup.slug === rslug) {
          return subGroup.name;
        }
        for (const subSubGroup of subGroup.subSubGroups) {
          if (subSubGroup.slug === rslug) {
            return subSubGroup.name;
          }
        }
      }
    }
    return "";
  };


  const gotoPrevious = () => currentImageIndex > 0 && dispatch(setCurrentIndex(currentImageIndex - 1));

  const gotoNext = () =>{

    currentImageIndex + 1 < products.map((x) => x.image).length &&
    dispatch(setCurrentIndex(currentImageIndex + 1));
  }

  const toDataURL = async(url) => {
    const data=await fetch("https://entegroid.com/base64.php?url=" + url).then(res=>res.json())
    return data
  };
  const [excelLoading,setExcelLoading]=useState(false)
  const exportFile = React.useCallback(() => {
    if(excelLoading){
      return
    }
    setExcelLoading(true)
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 100;
    sheet.columns = [
      // {
      //   header: "",
      //   key: "Numara",
      //   width: 1,
      //   style: {
      //     alignment: {
      //       vertical: "middle",
      //       horizontal: "center",
      //     },
      //   },
      // },
      {
        header: "",
        key: "Resim",
        width: 10,
        image: true,
        style: {
          alignment: {
            vertical: "middle",
            horizontal: "center",
          },
        },
      },
      {
        header: language.stockcode,
        key: "id",
        width: 12,
        style: {
          alignment: {
            vertical: "middle",
            horizontal: "center",
          },
        },
      },
      {
        header: language.name,
        key: "UrunAdi",
        width: 50,
        style: {
          alignment: {
            vertical: "middle",
            horizontal: "center",
          },
        },
      },
      {
        header: language.stockCount,
        key: "stockCount",
        width: 15,
        style: {
          alignment: {
            vertical: "middle",
            horizontal: "center",
          },
        },
      },
      {
        header: language.price,
        width: 15,
        key: "price",
        style: {
          alignment: {
            vertical: "middle",
            horizontal: "center",
          },
        },
      },
    ];
    const promise = Promise.all(
      products?.map(async (product, index) => {
        const rowNumber = index+1 ;
        sheet.addRow({
          Resim: "",
          id: product.id,
          UrunAdi: product.title,
          stockCount: product.availableStock,
          price: product.salePrice,
        });
        if (product.image != null) {
          const result = await toDataURL(
            product?.image == null ? "" :product?.image
          );
          const splitted = product?.image.split(".");
          const extName = splitted[splitted.length - 1];
        
          const imageId2 = workbook.addImage({
            base64: result.base64,
            extension: extName,
          });

          sheet.addImage(imageId2, {
            tl: { col:0, row: rowNumber },
            ext: { width: 100/1.5, height: 100 },
          });
        } else {
        }
      })
    );
    promise.then(() => {
      const priceCol = sheet.getColumn(4);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        const currentDate = new Date().toISOString().slice(0, 10);
        anchor.download = currentDate+"-bilaloto-Aydinlatma-Urun-Listesi.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
      setExcelLoading(false)
    });
    
  }, [products]);
  const [printOn,setPrintOn]=React.useState(false)
  const [printHtml,setPrintHtml]=React.useState("")
  const exportPdf = React.useCallback(async () => {
    let html = `
    <html>
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">    
      <style>
        .head,
        .content {
          display:flex;
          justify-content:flex-start;
          align-items:center;
          width: 100%;
        }
        .head div,
        .content div {
          border: 1px solid #616161;
          font-size: 12px;
          text-align: center;
        }
        .content div {
          min-height: ${65 * 1.5}px;
          display:flex;
          justify-content:center;
          align-items:center;
        }
        .content img {
          width:65px;
          height: ${65 * 1.5}px;
        }
        .content div:nth-child(3),
        .head div:nth-child(3) {
          max-width: 450px;
          text-align: left; /* Adjust alignment for the product name */
        }
      </style>  
    </head>
    <body>
      <div class="head">
        <div style="width: 70px;">###</div>
        <div style="width: 130px;">${language.stockcode}</div>
        <div style="width: 100%;">${language.name}</div>
        <div style="width: 110px;">${language.stockCount}</div>
        <div style="width: 130px;">${language.price}</div>
      </div>
    `;

    const set = await products?.map((product, index) => {
      html += `<div class="content">
      <div style="width: 70px;">${
        product.image != null && product.image != ""
          ? `<img src="${product?.image}" />`
          : ``
      }</div>
      <div style="width: 130px;">${product?.id}</div>
      <div style="width: 100%;">${language!="tr" && (product?.title2!=null && product?.title2!="") ? product?.title2 :product?.title}</div>
      <div style="width: 110px;">${product?.availableStock}</div>
      <div style="width: 130px;">${product?.salePrice}</div>
    </div>`;
    });
    html += `<script>
    setTimeout(() => {
      window.print();
    },400)
    </script></body>
    </html>
    `;
    setPrintOn(true);
    setPrintHtml(html)
    console.log(html)
    setTimeout(() => {
      setPrintOn(false);
      setPrintHtml("")
    }, 5500);
  }, [products]);

  useEffect(() => {
    if(selected!=null && selected.length>1){
      dispatch(setSort({
        sort: "DATE",
        sortedBy: "DESC",
        name: language.newProducts,
      }))
      history.push('/kategori/'+selected[selected.length-1])
    }
  }, [selected]);

  return (
    <>
      {printOn && printHtml != null ? (
        <div
          style={{
            position: "fixed",
            zIndex: -11,
            top: "-1000vh",
            left: "-10000vw",
          }}
        >
          <iframe srcDoc={printHtml}></iframe>
        </div>
      ) : (
        <></>
      )}

      {categories.length > 0 ? (
        <Helmet>
          <title>
            Toptan {categories[0].name} Ürünleri Toptan Masa Lambası , bilaloto
            Toptan Lastik
          </title>
          <meta
            name="description"
            content={" Toptan " + categories[0].name + " "}
          />
          <meta
            name="og:description"
            content={" Toptan " + categories[0].name + " "}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>
            Toptan Lastik Toptan Masa Lambası , bilaloto Toptan Lastik
          </title>
        </Helmet>
      )}

      <Header />
      <div>
      {images != null && images.length > 0 ? (
          <CarouselContainer colors={slider}>
            <SliderSwitch>
              {/* <button onClick={()=>setSliders('test1')} className={sliders=='test1' ? 'active' : ''}>Afişlerimiz</button> */}
              {/* <button onClick={()=>setSliders('test2')} className={sliders=='test2' ? 'active' : ''}>Hepsi</button>  */}
              {/* <button onClick={()=>setSliders('test3')} className={sliders=='test3' ? 'active' : ''}>Hepsi</button>  */}
            </SliderSwitch>

            <SliderContainer images={images} products={discover} />
          </CarouselContainer>
        ) : (
          <></>
        )}
      </div>

      
      
      <h1 className="analizicin">
        {categories.length > 0 && categories[0].name} Kategori Ürünleri -
        bilaloto Toptan Lastik{" "}
      </h1>
      <div style={{ width: "100%" }}>
        <div className="filters">
          <div className="left">
            {!open && (
              <div className="filter-btn" onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faSlidersH} /> {language.filters}
              </div>
            )}
          </div>
          <div className="right">
            {selected.length > 0 ||
            minPrice != null ||
            maxPrice != null ||
            selectedBrands.length > 0 ||
            selectedColors.length > 0 ||
            selectedSoles.length > 0 ? (
              <>
                <div className="filtered-list">
                  <div
                    className="filtered-item all-remover"
                    key={"filtergfsed-x-"}
                    onClick={() => {
                      dispatch(setSelectedBrands([]));
                      dispatch(setSelected([]));
                      dispatch(setSelectedColors([]));
                    }}
                  >
                    {language.clearFilter}
                    <span>x</span>
                  </div>
                  {selectedBrands.map((x) => {
                    return (
                      <div
                        className="filtered-item"
                        key={"filtergfsed-x-" + x}
                        onClick={() => {
                          dispatch(
                            setSelectedBrands(
                              selectedBrands.filter((y) => y != x)
                            )
                          );
                        }}
                      >
                        {brands.filter((y) => y.slug == x).length < 1
                          ? ""
                          : brands.filter((y) => y.slug == x)[0].name}{" "}
                        <span>x</span>
                      </div>
                    );
                  })}
                    
                  {selected.map((x) => {
                    return (
                      <div
                        key={"filtereddfgd-y-" + x}
                        onClick={() => {
                          dispatch(setSelected(selected.filter((y) => y != x)));
                        }}
                        className="filtered-item"
                      >
                        {getSlugToName(x)} <span>x</span>
                      </div>
                    );
                  })}
                  {selectedColors.map((x) => {
                    return (
                      <div
                        key={"filtered-color-sdfgc" + x}
                        onClick={() => {
                          dispatch(
                            setSelectedColors(
                              selectedColors.filter((y) => y != x)
                            )
                          );
                        }}
                        className="filtered-item"
                      >
                        {x}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {selectedSoles.map((x) => {
                    return (
                      <div
                        key={"filtered-sodlfdge-" + x.id}
                        onClick={() => {
                          setSelectedSoles(selectedSoles.filter((y) => y != x));
                        }}
                        className="filtered-item"
                      >
                        {soles.filter((y) => y.id == x).length < 1
                          ? ""
                          : soles
                              .filter((y) => y.id == x)[0]
                              .specialCode.replace(/Taban/g, " ")
                              .replace(/:/g, " ")}{" "}
                        <span>x</span>
                      </div>
                    );
                  })}
                  {minPrice != null && (
                    <div
                      onClick={() => {
                        dispatch(setMinPrice(null));
                      }}
                      className="filtered-item"
                    >
                      En Az {minPrice}₺<span>x</span>
                    </div>
                  )}
                  {maxPrice != null && (
                    <div
                      onClick={() => {
                        dispatch(setMaxPrice(null));
                      }}
                      className="filtered-item"
                    >
                      En Fazla {maxPrice}₺<span>x</span>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {/* <button onClick={exportFile}>
              {excelLoading ? (
                <Sentry />
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faFileExcel}
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                      color: "green",
                    }}
                  />
                  Excel
                </>
              )}
            </button>
            <button onClick={exportPdf}>
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{ marginRight: 10, cursor: "pointer", color: "red" }}
              />
              PDF
            </button> */}
            <button
              className="sort-btn"
              onClick={() => {
                dispatch(setFilterOpen(!filterOpen));
              }}
            >
              <FontAwesomeIcon
                icon={sort.sortedBy == "DESC" ? faAngleDown : faAngleUp}
                style={{ marginRight: 10 }}
              />{" "}
              {sort.name}
              <div
                className="filter-list"
                style={{ display: filterOpen != true ? "none" : "unset" }}
              >
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "DESC",
                        name: language.newProducts,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.newProducts}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "ASC",
                        name: language.oldProducts,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.oldProducts}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "ASC",
                        name: language.increasedPrice,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.increasedPrice}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "DESC",
                        name: language.decreasingPrice,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.decreasingPrice}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "DESC",
                        name: language.decreasingAvailable,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.decreasingAvailable}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "ASC",
                        name: language.increasedAvailable,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.increasedAvailable}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TOPSALES",
                        sortedBy: "DESC",
                        name: language.topSeller,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TOPSALES" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.topSeller}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TITLE",
                        sortedBy: "ASC",
                        name: language.aZ,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "TITLE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.aZ}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TITLE",
                        sortedBy: "DESC",
                        name: language.zA,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TITLE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.zA}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "HOME",
                        sortedBy: "",
                        name: language.discovery,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sort == "HOME" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.discovery}
                </div>
              </div>
            </button>

            <div className="total">
              {allData != null &&
              allData.currentPage != null &&
              allData.count != null ? (
                <div className="count">
                  {/* {allData.count * allData.currentPage} /{" "} */}
                  {/* {products.length}
                    <strong>/</strong> */}
                  {/* {allData.totalCount} {language.product} */}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <ProductContent open={open}>
        <div className="filter">
          {width < 1140 && (
            <div className="overlay" onClick={() => setOpen(false)}></div>
          )}
          <div className="categories">
            <div className="category-item head" onClick={() => setOpen(!open)}>
              <span>
                <FontAwesomeIcon icon={faBars} />
              </span>
              <span>{language.categories}</span>
              <span>x</span>
            </div>
            {categories.map((x) => {
              return (
                <CategoryItem
                  slug={slug}
                  dispatch={dispatch}
                  key={"categorydfscv-item-" + x.slug}
                  category={x}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}
          </div>

          {brands.length > 0 && (
            <>
              <div
                className={"category-item alt-head "}
                onClick={() => {
                  dispatch(setBrandOpen(!brandOpen));
                }}
                style={{ position: "relative" }}
              >
                <span
                  style={{
                    transition: "600ms all",
                    transform: brandOpen ? "rotateX(180deg)" : "rotateX(0deg)",
                  }}
                >
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
                <span>Marka</span>

                <span style={{ fontSize: 11 }} onClick={() => {}}>
                  (
                  {
                    brands.filter((x) =>
                      x.name
                        .toLocaleLowerCase("tr")
                        .startsWith(brandSearchTxt.toLocaleLowerCase("tr"))
                    ).length
                  }
                  )
                </span>
              </div>
              {/* <div
                className="search-box category-sub-item"
                style={{ display: brandOpen ? "" : "none" }}
              >
                <input
                  type="text"
                  value={brandSearchTxt}
                  onChange={(e) => dispatch(setBrandSearchTxt(e.target.value))}
                  placeholder="Marka Ara"
                />
                {brandSearchTxt.length > 0 && (
                  <span
                    onClick={() => dispatch(setBrandSearchTxt(""))}
                    className="search-trash"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                )}
              </div> */}
              <div
                className="colors"
                style={{ display: brandOpen ? "" : "none" }}
              >
                {brands
                  .filter((x) => selectedBrands.includes(x.slug))
                  .filter((x) =>
                    x.name
                      .toLocaleLowerCase("tr")

                      .startsWith(brandSearchTxt.toLocaleLowerCase("tr"))
                  )
                  .map((x) => {
                    return (
                      <BrandItem
                        key={"sole-item-dffgdhgh-" + x.id}
                        dispatch={dispatch}
                        slug={slug}
                        brand={x}
                        selectedBrands={selectedBrands}
                        setSelectedBrands={setSelectedBrands}
                        categoryMode={false}
                      />
                    );
                  })}
                {brands
                  .filter((x) => !selectedBrands.includes(x.slug))
                  .filter((x) =>
                    x.name
                      .toLocaleLowerCase("tr")
                      .startsWith(brandSearchTxt.toLocaleLowerCase("tr"))
                  )
                  .map((x) => {
                    return (
                      <BrandItem
                        key={"sole-item-dffgdhgh-" + x.id}
                        dispatch={dispatch}
                        slug={slug}
                        brand={x}
                        selectedBrands={selectedBrands}
                        setSelectedBrands={setSelectedBrands}
                        categoryMode={false}
                      />
                    );
                  })}
              </div>
            </>
          )}

          <div className="categories">
            <div
              // onClick={() => setOpen(!open)}
              className={"category-item " + (true ? "open" : "")}
            >
              <span onClick={() => setOpen(!open)}>
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
              <span>
                {language.product} {language.status}
              </span>
              <span></span>
            </div>
            <div
              onClick={() => {
                setAlwaysStock(!alwaysStock);

                // setAlwaysNoneStock(!alwaysNoneStock);
              }}
              className={"category-sub-item "}
              style={{}}
            >
              <span className={"check-box " + (alwaysStock ? "checked" : "")}>
                <span>
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </span>
              <div className="name">
                <span>{language.inStock}</span>
                <span style={{ fontSize: 12 }}></span>
              </div>
            </div>
            {/* <div
              onClick={() => {
                setAlwaysNoneStock(!alwaysNoneStock);
              }}
              className={"category-sub-item "}
              style={{}}
            >
             <span
                className={"check-box " + (alwaysNoneStock ? "checked" : "")}
              >
                 <span>
                  <FontAwesomeIcon icon={faCheck} />
                </span> 
              </span>
              <div className="name">
                <span>{language.stockOff}</span>
                <span style={{ fontSize: 12 }}></span>
              </div> 
              
            </div> */}
          </div>
        </div>

        <div className="list">
          <div className="filter-mobil-group">
            <div className="filter-btn mobil" onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={faSlidersH} /> {language.filters}
            </div>
            <button
              className="sort-btn-mobil"
              onClick={() => dispatch(setFilterOpen(!filterOpen))}
            >
              <FontAwesomeIcon
                icon={sort.sortedBy == "DESC" ? faAngleDown : faAngleUp}
                style={{ marginRight: 10 }}
              />{" "}
              {sort.name}
              <div
                className="filter-list"
                style={{ display: filterOpen != true ? "none" : "unset" }}
              >
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "HOME",
                        sortedBy: "",
                        name: language.discovery,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sort == "HOME" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.discovery}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "DESC",
                        name: language.newProducts,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.newProducts}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "DATE",
                        sortedBy: "ASC",
                        name: language.oldProducts,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "DATE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.oldProducts}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "ASC",
                        name: language.increasedPrice,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.increasedPrice}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "PRICE",
                        sortedBy: "DESC",
                        name: language.decreasingPrice,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "PRICE" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.decreasingPrice}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "DESC",
                        name: language.decreasingAvailable,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.decreasingAvailable}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "STOCK",
                        sortedBy: "ASC",
                        name: language.increasedAvailable,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "ASC" && sort.sort == "STOCK" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.increasedAvailable}
                </div>
                <div
                  onClick={() =>
                    dispatch(
                      setSort({
                        sort: "TOPSALES",
                        sortedBy: "DESC",
                        name: language.topSeller,
                      })
                    )
                  }
                  className="item"
                >
                  {sort.sortedBy == "DESC" && sort.sort == "TOPSALES" ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {language.topSeller}
                </div>
              </div>
            </button>
          </div>

          {products.map((x) => {
            return (
              <ProductItem
                key={x.id + x.slug + "asda"}
                product={x}
                setOpenedBox={setOpenedBox}
              />
            );
          })}
          <div style={{ width: "100%", textAlign: "center" }}>
            {allData != null &&
            products.length > 0 &&
            allData.currentPage != null &&
            allData.count != null ? (
              <div className="count">
                {products.length == allData.totalCount ? (
                  <>
                    {language.total} {products.length} {language.product}
                  </>
                ) : (
                  <>
                    {/* {products.length}
                    <strong
                      style={{
                        marginRight: 5,
                        marginLeft: 5,
                        fontWeight: "bold",
                      }}
                    >
                      /
                    </strong>
                    {allData.totalCount} {language.product} */}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {allData != null &&
          products.length > 0 &&
          allData.currentPage != null &&
          allData.count != null &&
          allData.totalCount != products.length ? (
            <div
              onClick={() => {
                getNewPage(page + 1);
              }}
              style={{
                color: "#ffff",
                padding: "5px 55px",
                marginBottom: 40,
                width: "100%",
                borderRadius: 100,
                textAlign: "center",
                cursor: "pointer",
                fontSize: 22,
              }}
            >
              {!loading && (
                <div
                  style={{
                    display: "inline-block",
                    background: "#F27A1A",
                    padding: "15px 55px",
                    marginTop: 20,
                    borderRadius: 100,
                    fontSize: 18,
                  }}
                >
                  {language.showMore}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && <Sentry color="#FBA800" size={180} />}
          </div>
        </div>
      </ProductContent>
      <div style={{ zIndex: 1009090 }}>
        {products.length > 0 && (
          <Lightbox
            isOpen={openedBox}
            onPrev={gotoPrevious}
            className="lightbox-image-box"
            onNext={gotoNext}
            images={products.map((x) => {
              return { src: x.image, loading: "lazy", alt: "deha" };
            })}
            currentIndex={currentImageIndex}
            onClose={() => dispatch(setOpenedBox(false))}
            style={{ background: "#fff", zIndex: 1009090 }}
            renderNextButton={() => (
              <SwapButton onClick={gotoNext} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleRight} />
              </SwapButton>
            )}
            renderPrevButton={() => (
              <SwapButton onClick={gotoPrevious} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleLeft} />
              </SwapButton>
            )}
            renderFooter={() => (
              <div
                style={{
                  width: "40vw",
                  // background: "rgba(0,0,0,0.5)",
                  height: "40vh",
                  top: "30vh",
                  left: "30vw",
                  position: "fixed",
                  zIndex: 100,
                }}
                onClick={() => {
                  history.push("/urun/" + products[currentImageIndex].slug);
                }}
              ></div>
            )}
            renderHeader={() => (
              <button
                onClick={() => dispatch(setOpenedBox(false))}
                className="light-close"
              >
                X
              </button>
            )}
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
        )}
      </div>
      <BottomNav />
      <Footer />
    </>
  );
}
