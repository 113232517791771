import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function SubItem({category,parent,open,slug,selected,setSelected,dispatch}) {
  const history = useHistory();
  
  useEffect(() => {
    console.log(selected, slug);
    if (selected.length == 1 && slug != undefined) {
      if (selected[0] !== slug) {
        history.push('/kategori/' + selected[0]);
      }
    }
  }, [selected]);


  return (
    <div
      onClick={() => {
        if (selected.includes(category.slug)) {
          dispatch(
            setSelected(selected.filter((item) => item != category.slug))
          );
        } else {
          dispatch(
            setSelected([
              ...selected.filter((x) => x != parent.slug),
              category.slug,
            ])
          );
        }
      }}
      className={"category-sub-item "}
      style={{ display: open ? "flex" : "none" }}
    >
      <span
        className={
          "check-box " + (selected.includes(category.slug) ? "checked" : "")
        }
      >
        <span>
          <FontAwesomeIcon icon={faCheck} />
        </span>
      </span>
      <div className="name">
        <span>
          {category.name}
        </span>
        <span style={{ fontSize: 12 }}>
          
        </span>
      </div>
    </div>
  );
}
