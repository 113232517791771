let LightTheme = {
  background: "#fff",
  color: "#000",
  opacityColor: "#333",
  header: "#f2f2f2",
  altHeader: "#eee",
  altHeaderBorder: "#000",
  notifyCircleBackground: "#341f21",
  notifyCircle: "#000",
  elementBackground: "#eee",
  element: "#000",
  elementShadow: "#bdbdbd",
  footerBackground: "#111",
  rgbaOpacity: "1",
  productsContainerBackground: "#fff",
  mainThemeColor: "#fff",
  mainThemeDarkColor: "#000",
  mainThemeBackground: "#eee",
  mainThemeOpacityBackgroundHover: "#4f87ac",
  dropDownBackground: "#fff",
  dropDownHoverBackground: "#eee",
  success: "#badc58",
  successText: "#fff",
  error: "#ff7979",
  errorText: "#fff",
  warning: "#5758BB",
  warningText: "#fff",
  info: "#8c8c8c",
  infoText: "#fff",
  adminHeaderMenuItem: "rgba(255,255,255,.1)",
  adminHeaderMenuItemActive: "rgba(255,255,255,7)",
  mainThemeBackgroundOpacity: "rgb(255,47,22,.77345)",
};

let DarkTheme = {
  background: "#000",
  color: "#fff",
  opacityColor: "#bbb",
  header: "#424242",
  altHeader: "#333",
  altHeaderBorder: "#555",
  notifyCircleBackground: "#666",
  notifyCircle: "#fff",
  elementBackground: "#000",
  element: "#fff",
  elementShadow: "#fff",
  footerBackground: "#333",
  rgbaOpacity: ".6",
  productsContainerBackground: "#333",
  mainThemeColor: "#ffbc00",
  mainThemeDarkColor: "#1c6aa7",
  mainThemeBackground: "rgba(255,217,110,.7)",
  mainThemeOpacityBackgroundHover: "rgba(255,217,110,.2)",
  dropDownBackground: "#111",
  dropDownHoverBackground: "#222",
  error: "#f44336",
  errorText: "#fff",
  success: "#4caf50",
  successText: "#fff",
  warning: "#ff9800",
  warningText: "#fff",
  info: "#8c8c8c",
  infoText: "#fff",
  adminHeaderMenuItem: "rgba(255,255,255,.1)",
  adminHeaderMenuItemActive: "rgba(255,255,255,7)",
  mainThemeBackgroundOpacity: "rgb(255,47,22)",
};

export { DarkTheme, LightTheme };
