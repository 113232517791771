import { createSlice } from "@reduxjs/toolkit";

import { LightTheme,DarkTheme } from "../../styles/Theme";
import {tr,en} from "../../languages/lang";
const getLangFile = (lang) => {
  if(lang==undefined || lang==null ){
    localStorage.setItem("language", "tr");
    return tr
  }
  switch (lang) {
    case "tr":
      localStorage.setItem("language", "tr");
      return tr;
    case "en":
      localStorage.setItem("language", "en");
      return en;
    default:
      localStorage.setItem("language", "tr");
      return tr;
  }
}
export const site = createSlice({
  name: "site",
  initialState: {
    theme:
      localStorage.getItem("theme") != undefined
        ? localStorage.getItem("theme")
        : "LightTheme",
    slider: {
      front: "22,88,103",
      center: "253,29,29",
      centerlast: "41,126,231",
      end: "221,18,46",
    },
    loginPopUp: localStorage.getItem("bilalototoken") == null ? true : false,
    registerPopUp: false,
    language: getLangFile(localStorage.getItem("language")),
  },
  reducers: {
    changeSlider: (state, action) => {
      state.slider = action.payload;
    },
    lightMode: (state) => {
      state.theme = LightTheme;
    },
    darkMode: (state) => {
      state.theme = DarkTheme;
    },
    changeTheme: (state) => {
      state.theme = state.theme == "LightTheme" ? "DarkTheme" : "LightTheme";
      localStorage.setItem("theme", state.theme);
    },
    turkish: (state) => {
      state.language = "tr";
    },
    changeLanguage: (state,action) => {
      state.language=getLangFile(action.payload)

      console.log(state.language)
    },
    setLoginPopUp: (state) => {
      state.loginPopUp = !state.loginPopUp;
    },
    setRegisterPopUp: (state) => {
      state.registerPopUp = !state.registerPopUp;
    },
  },
});

export const {
  lightMode,
  darkMode,
  changeTheme,
  turkish,
  english,
  changeLanguage,
  changeSlider,
  setLoginPopUp,
  setRegisterPopUp,
} = site.actions;
export default site.reducer