import React,{useEffect,useRef} from 'react'
import Carousel, { consts } from "react-elastic-carousel";
import { prominent,average } from 'color.js';
import { changeSlider } from '../stores/Site';
import { SlideProductItem } from '../../styles/Slide';
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useCartRequests } from '../../helpers/CartRequests';
import { setBasket } from "../stores/Cart";
import useWindowDimensions from '../useWindowDimensions';


export default function Slider({images,products}) {
  const { width } = useWindowDimensions();

  const history=useHistory()
  const carouselRef2 = useRef(null);
  const carouselRef = useRef(null);
  const dispatch = useDispatch()
  const { basket } = useSelector((state) => state.cart);
  let resetTimeout;

  useEffect(() => {
    
  })

  useEffect(() => {
    clearInterval(resetTimeout)
    carouselRef.current.goTo(0)
    setTimeout(() => {
      getColors()
    }, 10);
  }, [images])
  const getColors=()=>{
    if(images.length>0 && carouselRef!=null && carouselRef.current!=null && carouselRef.current.state!=null){
      prominent(images[carouselRef.current.state.activeIndex],{
        format:'rgba',
        amount:4
      }).then((colors)=>{
        updateColors(colors)
      })
      
    }
  }

  const updateColors=colors=>{
    if(typeof(colors[0])=='number'){
      const newColors={
      front:colors.join(','),
      center:colors.join(','),
      centerlast:colors.join(','),
      end:colors.join(',')}
      dispatch(changeSlider(newColors))
    }else{
      let newColors={
      front:(colors[0]!=undefined && colors[0]!=null ? colors[0] : [0,0,0]).join(','),
      center:(colors[1]!=undefined && colors[1]!=null ? colors[1] : [0,0,0]).join(','),
      centerlast:(colors[2]!=undefined && colors[2]!=null ? colors[2] : [0,0,0]).join(','),
      end:(colors[3]!=undefined && colors[3]!=null ? colors[3] : [0,0,0]).join(',')}

      dispatch(changeSlider(newColors))
      // console.log((colors[0]!=undefined && colors[0]!=null ? colors[0] : [0,0,0]).join(','))
      // console.log((colors[1]!=undefined && colors[1]!=null ? colors[1] : [0,0,0]).join(','))
      // console.log((colors[2]!=undefined && colors[2]!=null ? colors[2] : [0,0,0]).join(','))
      // console.log((colors[3]!=undefined && colors[3]!=null ? colors[3] : [0,0,0]).join(','))
    }
  }
  const { addCart, getCart } = useCartRequests();
  const getBasket = () => {
    getCart().then((res) => {
      dispatch(setBasket(res.data.data));
    });
  };

  const addToBasket = async (product) => {
  const loader = toast.loading("Ürün Sepete Ekleniyor...", {
    position: toast.POSITION.BOTTOM_RIGHT,
    isLoading: true,
    closeOnClick: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    draggable: false,
  });
  addCart([{ id: product.id, count: 1 }])
    .then((res) => {
      getBasket();
      toast.update(loader, {
        render: res.data.message,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
      });
    })
    .catch((err) => {
      if (
        err.response != undefined &&
        err.response.data.data != undefined
      ) {
        toast.update(loader, {
          render: err.res.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
      }
    });
  };
  return (
    <div className='home-slider' style={{ display: "flex", width: "100%" }}>
      <Carousel
        ref={carouselRef}
        enableAutoPlay
        autoPlaySpeed={3000}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <div style={{ display: "flex" }} className="slider-page-lists">
              {pages.map((e) => {
                const isActivePage = activePage === e;
                return (
                  <div
                    key={Math.floor(Math.random() * 99999)}
                    style={{ cursor: "pointer" }}
                    onClick={() => onClick(e)}
                  >
                    <img
                      onContextMenu={() => false}
                      className={isActivePage ? "active-slider-page" : ""}
                      style={{
                        width: 50,
                        height: 25,
                        margin: 10,
                        borderRadius: "100px",
                      }}
                      src={images[e]}
                    />
                  </div>
                );
              })}
            </div>
          );
        }}
        onChange={() => {
          getColors();
        }}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index === images.length - 1) {
            resetTimeout = setTimeout(() => {
              try {
                carouselRef.current.goTo(0);
              } catch (error) {}
            }, 1400); // same time
          }
        }}
        onPrevStart={({ index }) => {
          clearTimeout(resetTimeout);
          if (index === 0) {
            resetTimeout = setTimeout(() => {
              carouselRef.current.goTo(images.length - 1);
            }, 1400); // same time
          }
        }}
        disableArrowsOnEnd={false}
        itemsToShow={1}
      >
        {images.map((e, index) => {
          return (
            <img
              onContextMenu={() => false}
              key={Math.floor(Math.random() * 99999)}
              src={e}
              alt={"banner-" + index}
            />
          );
        })}
      </Carousel>
      
    </div>
  );
}
