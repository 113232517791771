import React,{useLayoutEffect,useState} from 'react'
import { ThemeProvider } from "styled-components";
import {GlobalStyle} from './styles/Global';
import {LightTheme,DarkTheme} from './styles/Theme'
import { Route,Switch} from "react-router-dom";
import {Routes} from './Routes'
import { useSelector, useDispatch } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCartRequests } from './helpers/CartRequests'

import { setBasket } from './components/stores/Cart'


import ScrollButton from './components/Content/ScrollButton';

import NotFound from './views/NotFound'
import { useAuthRequests } from './helpers/AuthRequests';
import { setCustomer } from './components/stores/Auth';
import { Helmet } from 'react-helmet';

import ScrollToTop from 'react-scroll-to-top';
import { ReactComponent as MyTestSvg } from "./MyTestSvg.svg";


function App() {
  const {  getCustomer } = useAuthRequests();
  const {language}=useSelector(state=>state.site)
  const [mainRoutes,setMainRoutes]=useState(null)

  const getMainRoutes=()=>{
    Routes.then(res=>{
      
      setMainRoutes(res)
        
  })}

    
  useLayoutEffect(() => {
    if (mainRoutes == null) {
      getMainRoutes();
    }

  }, [mainRoutes]);
  

  const dispatch=useDispatch()

  const {login}=useSelector(state=>state.auth)

  const {theme}=useSelector(state=>state.site)

  const {basket}=useSelector(state=>state.cart)

  const {getCart} = useCartRequests()

  React.useEffect(() => {
    if (login && basket == null) {
      getCustomer(localStorage.getItem("bilalototoken")).then((res) => {
        dispatch(setCustomer(res.data.data));
      });
      getCart().then((res) => {
        dispatch(setBasket(res.data.data));
      });
    }
  }, [basket,   login, dispatch, getCustomer,getCart]);

  const listRoutes=(routeList)=>{
      return routeList.map((route,index)=>{
                
        return(

          <Route key={`route-${route.path}`} path={route.path} exact={route.exact} component={route.component} />
        )

      })

  }

  return (
    <ThemeProvider theme={theme === "DarkTheme" ? DarkTheme : LightTheme}>
      <GlobalStyle />
      <ToastContainer />
      <Helmet>
       
        <meta property="og:type" content="website" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta property="og:url" content="https://b2b.bilalotomotiv.com" />
        <meta
          property="og:site_name"
          content="b2b.bilalotomotiv.com"
        />
        <meta
          name="keywords"
          content="bilaloto, bilaloto toptan, "
        />
      </Helmet>
      <ScrollButton />

      <Switch>
        {mainRoutes != null ? listRoutes(mainRoutes) : <></>}

        <Route path="*" render={() => <NotFound />} />
      </Switch>
      <ScrollToTop
        smooth
        className="go-top-btn"
        component={
          <>
            <MyTestSvg />
            <span>{language.goTop}</span>
          </>
        }
      />
    </ThemeProvider>
  );
}

export default App;
