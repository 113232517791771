import styled from "styled-components";
import { rgba } from 'polished';

const ProductItem = styled.div`
  @media only screen and (max-width: 1140px) {
    & + div {
      width: 100%;
    }
    width: 100%;
  }

  &:hover {
    box-shadow: 0px 0px 41px #000;
  }
  margin: 10px;
  width: 15%;

  padding: 10px;
  border-radius: 10px;
  min-height: 300px;
  max-height: 300px;
  cursor: pointer;
  transition: 500ms all;
  h3 {
    font-size: 13px;
    font-weight: normal;
    max-height: 60px;
    padding: 5px 10px 10px 5px;
  }
  img {
    padding: 5px;
    width: 100%;
    height: 170px;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .action {
    position: relative;

    .price {
      display: flex;
      opacity: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 5px 5px;
      transition: 600ms all;
      h4 {
        font-size: 15px;
        color: ${({ theme }) => theme.mainThemeColor};
      }
      h5 {
        font-size: 12px;
        text-decoration: line-through;
      }
    }

    .list-price div {
      position: relative;
      opacity: 0.7;
      &:before {
        bottom: 50%;
        transform: rotate(-10deg);
        content: "";
        position: absolute;
        height: 3px;
        left: -5%;
        width: 110%;
        background: ${({ theme }) => theme.altHeader};
      }
    }
    .basket {
      transition: 800ms all;
      top: 10px;
      button {
        border: 0;
        outline: 0;
        width: 200px;
        padding: 10px 0px;
        background-color: ${({ theme }) => theme.mainThemeBackground};
        transition: 600ms all;
        color: ${({ theme }) => theme.mainThemeColor};
        border-radius: 10px;
        &:hover {
          background-color: ${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    
  }
`;

const ProductList = styled.div`
  width: 100%;
  @media only screen and (max-width: 1140px) {
    width: 100%;
  }
  margin-bottom: 40px;
  background-color: ${({ theme }) => theme.dropDownBackground};
  background: rgba(242, 242, 242, 0.4);
  border-radius: 20px;
  display: flex;

  &.product-list {
    flex-direction: column;
    .product-item {
      transition: 500ms all;
      box-shadow: 0px 2px 4444px #cccccc;
      background-color: ${({ theme }) => theme.mainThemeBackground};

      &:hover {
        box-shadow: 0px 0px 41px ${({ theme }) => theme.mainThemeColor};
        cursor: pointer;
      }

      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 20px;
      .price {
        font-size: 17px;
      }
      .name {
        font-size: 14px;
        min-width: 40%;
      }
      .basket {
        transition: 800ms all;
        opacity: 1;
        button {
          border: 0;
          outline: 0;
          width: 200px;
          padding: 10px 0px;
          background-color: #f27a1a;
          transition: 600ms all;
          color: ${({ theme }) => theme.mainThemeColor};
          border-radius: 10px;
          &:hover {
            background-color: ${({ theme }) =>
              theme.mainThemeOpacityBackgroundHover};
          }
        }
      }
      position: relative;
      .product-count {
        position: absolute;
        right: 10px;

        transition: 600ms all;
        font-size: 15px;
        z-index: 3;

        color: #fff;
        padding: 5px;
      }
      .image {
        min-width: 10%;
        height: 100px;
        transition: 500ms all;
        background-size: cover;
        background-position: center;
      }
      &:hover {
        .product-count {
          opacity: 1;
        }
        .image {
        }
      }
    }
    @media only screen and (max-width: 1140px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .product-item {
        background-color: ${({ theme }) => theme.mainThemeBackground};
        flex-direction: column;
        &:hover {
          .basket,
          .product-count {
            opacity: 100%;
          }
        }
        position: relative;
        .product-count {
          position: absolute;
          opacity: 0;
          top: 10px;
          right: 10px;
          transition: 600ms all;
          font-size: 22px;
          z-index: 1;
          background-color: ${({ theme }) => theme.mainThemeBackground};
          border-radius: 50%;
          padding: 5px;
        }
        .basket {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 800ms all;
          top: 10px;
          opacity: 100%;
          button {
            border: 0;
            outline: 0;
            width: 200px;
            padding: 10px 0px;
            background-color: ${({ theme }) => theme.mainThemeBackground};
            transition: 600ms all;
            color: ${({ theme }) => theme.mainThemeColor};
            border-radius: 10px;
            &:hover {
              background-color: ${({ theme }) =>
                theme.mainThemeOpacityBackgroundHover};
            }
          }
        }
        min-width: 300px;
        margin-top: 40px;
        padding: 2%;
        margin: 0.1%;
        width: 16%;
        min-height: 400px;
        border-radius: 20px;
        cursor: pointer;
        max-height: 400px;
        transition: 500ms all;
        .price {
          font-size: 22px;
          font-weight: bold;
        }
        .name {
          font-size: 14px;
          margin-top: 30px;
        }
        .image {
          width: 100%;
          transition: 500ms all;
          height: 200px;
          background-size: cover;
          background-position: center;
        }
        &:hover {
          .image {
            transform: scale(0.9);
          }
          box-shadow: 0px 0px 53px #000;
        }
      }

      @media only screen and (max-width: 1140px) {
        .product-item {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          .image {
            width: 100%;
          }
        }
      }
    }
  }
  &.product-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .product-item {
      box-shadow: 0px 2px 4px #cccccc;
      &:hover {
        box-shadow: 0px 5px 10px #cccccc;
      }
      h3 {
        font-size: 15px;
        font-weight: normal;
        color: #000;
        padding: 5px 15px 10px 15px;
      }
      .image-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 180px;
        margin-bottom: 20px;
        overflow: hidden;
        object-fit: cover !important;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover !important;
          user-select: none;
          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }

      min-width: 300px;
      margin-bottom: 40px;
      padding: 2%;
      width: 16%;
      min-height: 500px;
      border-radius: 20px;
      cursor: pointer;
      max-height: 550px;
      transition: 500ms all;
      color: #000;
      .price {
        font-size: 22px;
        font-weight: bold;
      }
      .name {
        font-size: 14px;
        margin-top: 30px;
      }
      .basket {
        transition: 800ms all;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          border: 0;
          color: #000;
          outline: 0;
          padding: 5px 10px;
          background-color: ${({ theme }) => theme.mainThemeBackground};
          transition: 600ms all;
          color: ${({ theme }) => theme.mainThemeColor};
          border-radius: 1000px;
          &:hover {
            color: ${({ theme }) => theme.mainThemeBackground};
            background-color: ${({ theme }) => theme.mainThemeColor};
          }
        }
      }
      .image {
        width: 100%;
        transition: 500ms all;
        height: 200px;
        background-size: cover;
        background-position: center;
      }
      &:hover {
        .image {
          transform: scale(0.9);
        }
        box-shadow: 0px 0px 53px #000;
      }
    }

    @media only screen and (max-width: 1140px) {
      align-items: center;
      justify-content: center;
      .product-item {
        min-height: 60vh;
        max-height: 60vh;
        .image {
          height: 40vh;
        }
      }
    }
  }
`;
const FilterContainer = styled.div`
  width:100%;
  background-color:#fff;
  padding:20px;
  border-radius:20px;
  transition:800ms all;
  h1{
    font-size:20px;
    margin-bottom:20px;
    border-bottom:1px solid ${({ theme }) => theme.mainThemeBackground};
  }
  
  .filter-item{
    
    .brand-list{
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      margin-block-start: 0px!important;
      margin-block-end: 0px!important;
      padding-inline-start: 0px!important;
      list-style-type:none!important;
      overflow:scroll;
      margin-left:0px;
      ul,li{
        margin-left:0px;
        display:flex;
        color:#222;
        *{
          font-family: 'Overpass', sans-serif!important;
        }

      }
      li{
        *{
          padding:8px 20px;
          inline-size: max-content;
        }

        margin:5px;
        text-align:center;
        *{
          user-select:none;
        }

        display:flex;justify-content:center;align-items:center;
      }
      li:hover{
        cursor:pointer;
        border:1px solid #000;
      }
      .active{
        font-weight:bold;
      }
    }
    display:flex;
    flex-direction:column;
    margin-bottom:20px;
    margin-top:20px;
    h3{
      font-size:16px;
      width:100%;
      font-weight:bold;
      padding:5px;
      border-radius:10px;
      &:hover{
        background-color:${({ theme }) => theme.mainThemeBackground};
      }
    }
    ul ul:last-child{
      padding-left:13%;
      
    }
    .active > a{
      text-align:center;
      color:#333;
    }
    ul{
      &::-webkit-scrollbar {
        display: none;
      }
      max-width:100%;
      overflow:scroll;
      padding-left:80px;
      display:flex;
      flex-direction:row;
      li{
        border:1px solid #616161;
        border-radius:20px;
      }
      li.items{
        a{
          inline-size: max-content;
        }
        border:1px solid ${({ theme }) => theme.color};
        background-color:#fefeff;
        text-align:center;
        border-radius:30px;
        border:1px solid #616161;
        padding:8px 20px ;
        margin:10px 6px;
        font-size:10px;
        display:flex;
        justify-content:center;
        align-items:center;
        font-family: 'Overpass', sans-serif!important;
        transition:300ms all;
        &:hover{
          border:1px solid #F27A1A;
          a{
            color:#333!important;
          }
        }
        b{
          cursor:pointer;
        }
      }
      li.active{
        border:none;
        color:#000;
        border:1px solid #F27A1A;
        
        background-color:rgba(242,122,26,.3);
        img{
          margin-right:20px;
        }
      }
      .active-brand{
        background-color:#fafafa!important;
        
        border:2px solid ${({ theme }) => theme.altHeader}!important;
      }
      li a{
        text-decoration:none;
        font-family: 'Overpass', sans-serif!important;
        color:#000;
        transition:300ms all;
        font-size:12px;
        font-weight:bold;
        
        cursor:pointer;
      }
      padding-inline-start:0px;
      margin-inline-start:0px;
    }
    &>ul{
    }
  }
  .head{
    position:relative;
    svg{
      position:absolute;
      top:25%;
      right:10px;
      transform:translateY(-25%);
    }
    padding-bottom:20px;
    border-bottom:1px solid ${({ theme }) => theme.mainThemeBackground};
    input{
      border:0;
      outline:0;
      width:100%;
      padding:10px;
      border-radius:10px;
      &:hover,&:focus{
      background-color:${({ theme }) => theme.mainThemeBackground};
      }
    }
  }
  @media only screen and (max-width:1140px){
    position:relative;
    width:100vw;
    overflow:scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .filter-item li{
      min-width:40%!important;
    } 
  }

`;

const ProductListTitle = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  flex-wrap: wrap;
  
  .items {
    display: flex;
    justify-content: product;
    align-items: center;
    .sortDrop {
      position: absolute;
      top: 100%;
      width: 100%;
      background-color: ${({ theme }) => theme.productsContainerBackground};
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      a:last-child:hover {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      a {
        text-decoration: none;
        color: ${({ theme }) => rgba(theme.mainThemeOpacityBackgroundHover, 1)};
        display: block;
        padding: 5px;
        width: 100%;
        &:hover {
          background-color: ${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    .productSort {
      button {
        background-color: rgba(0, 0, 0, 0.05);
        border: 0;
        outline: 0;
        padding: 5px 20px;
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    padding: 20px;
    .productSort {
      button {
      }
    }
  }

  .listNav {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    h1 {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px;
      font-weight: bold;
      transition: 300ms all;
      color: ${({ theme }) => theme.mainThemeColor};
      &:hover {
        color: ${({ theme }) => theme.mainThemeOpacityBackgroundHover};
      }
    }
    h2 {
      font-size: 15px;
    }
  }
`;

const DetailContainer = styled.div`
  .detail-image {
    height:calc(40vh )!important;
    max-width: 40vh!important;
    img{
      width:unset!important;
      height:40vh!important;
      max-width: 40vh!important;
    }
  }
  @media only screen and (max-width: 1140px) {
    .lightbox-container{
      display:none!important;
      opacity:0!important;
      background-color:#fff!important;
      img{
        max-width:90%!important;
      }
    }
  }


  .product-page-items{
    @media only screen and (max-width: 1140px) {
      display: none!important;
    }
  }
  
  .detail-light-pagination {
    position: fixed;
    transition: all 0.5s ease;
    top: 0;
    width: 100px;
    height: 100vh;
    z-index: 9999;
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    @media only screen and (max-width: 1140px) {
      height: 140px;
      div div{
        border-radius: 0;
        height:32vw!important;
        width:32vw!important;
      }
      width: 100%;
      bottom: 30px;
      top: unset;
      flex-direction: row;
      left: 0;
    }
  }


  .counter-top-price {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 0px;
    margin-bottom: 10px;
    border-left: 10px solid #cab800;
    background-color: #fddd00;
    font-weight: bold;
    font-size: 22px;
    transition: 300ms all;
    text-shadow: 1px 1px 2px #000;
    cursor: pointer;
    position: relative;
    color:red;
    span {
      position: absolute;
      font-size: 14px;
      left: 5px;
      top: 5px;
      text-shadow: none;
    }
    .listPrice{
      font-size: 26px;
      margin-top: 4px;
      margin-left: 10px;
      position:relative
    }

    .listPrice::before{
      content: "";
      color: red;
      position: absolute;
      width: 100%;
      top: 46%;
      height: 1px;
      background: red;
      left: -15%;
      width: 125%;
    }
  }
  .counter-bottom-price{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    overflow:hidden;
    padding: 10px 0px;
    margin-bottom: 10px;
    border-left: 10px solid #e3b182;
    background-color: #FEBE83;
    font-weight: bold;
    font-size: 22px;
    -webkit-transition: 300ms all;
    transition: 300ms all;
    text-shadow: 1px 1px 2px #000;
    cursor: pointer;
    position: relative;


    span{
      position: absolute;
      font-size: 11px;
      text-shadow: none;
      background: rgb(234, 22, 22);
      width: 100%;
      color: yellow;
      transform: rotate(-47deg);
      text-transform: uppercase;
      left: -73px;
      top: 12px;
      padding-top: 3px;
      padding-bottom: 3px;  
    }

    .salePrice{
    font-size: 26px;
    margin-top: 4px;
    margin-left: 10px;
    }
}
  }

  .varitants {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin:170px 0px;
    @media only screen and (max-width: 1140px) {
      margin: 20px 0px;
    }
    .variant-item {
      background: #fff;
      margin-right: 10px;
      margin-bottom: 10px;
      .image {
        width: 70px;
        height: calc(70px * 1.5);
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      .title {
        margin-right: 10px;
        font-size: 13px;
      }
      transition: 300ms all;
      &:hover {
        box-shadow: 0px 0px 4px #f27a4a;
      }
    }
    .variant-item.active {
      border-radius: 10px;
      border: 1px solid #f27a4a;
    }
    @media only screen and (max-width: 1140px) {
      .variant-item {
        .image {
          width: 90px;
          height: 90px;
        }
      }
    }
  }

  .carousel-an-con {
    .carousel-animate {
      position: absolute;
      font-size: 44;
      * {
        color: #000;
        font-size: 80px;
      }
      transition: 600ms all;
      cursor: pointer;
      opacity: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      font-size: 950%;
    }
    &:hover {
      .carousel-animate {
      }
    }
  }

  .detail-header {
    width: 98%;
    background-color: ${({ theme }) => theme.productsContainerBackground};
    display: flex;
    justify-content: product;
    flex-wrap: wrap;
    .go-back-btn{
      display:flex;
      align-items:center;
      justify-content:space-between;
      width:170px;
      color:#666;
      cursor:pointer;
    }
    .title {
      width: 70%;
      text-align: right;
      h1 {
        font-size: 24px;
      }
    }
    .detail-nav {
      color: ${({ theme }) => theme.mainThemeBackground};

      a {
        text-decoration: none;
        font-weight: bold;
        color: #000;
        transition: 300ms all;
        &:hover {
          color: ${({ theme }) => theme.mainThemeBackground};
        }
      }
      span {
        color: ${({ theme }) => theme.mainThemeBackground};
      }
      width: 25%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .product-information {
    width: 90%;
    padding: 1% 2%;
    color: #000;
    p {
      font-family: "Overpass", sans-serif !important;
    }
    margin-top: 15px;
    background-color: ${({ theme }) => theme.productsContainerBackground};
  }
  padding: 10px 0vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product {
    padding-top: 30px;
    .product-store {
      position: relative;
      min-height: 90vh;
      @media only screen and (max-width: 1140px) {
        min-height: unset;
      }
    }
    .product-informations {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0px;
      .info-item {
        width: 100%;
        display: flex;
        align-items: center;
        .title {
          width: 80px;
          font-weight: bold;
        }
        margin-top: 8px;
        .desc {
          &::before {
            content: ":";
            color: #000;
            font-weight: bold;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }
    .product-store.notstock {
      & > div {
        opacity: 22%;
      }
      &::after {
        content: "TÜKENDİ";
        position: absolute;
        z-index: 100;
        bottom: calc(50%);
        right: 0;
        background: ${({ theme }) => theme.mainThemeBackground};
        width: 100%;
        font-family: "Overpass", sans-serif !important;
        padding: 5px 0px;
        opacity: 90%;
        text-align: center;
        box-shadow: 0px 0px 5px 0px red;
        font-weight: bold;
        font-size: 48px;
        transform: rotate(-40deg);
      }
    }
    .product-title {
      text-align: left;
      margin-top: 45px;
      color: #000;
      font-size: 24px;
      font-weight: 550;
      font-family: "Roboto", sans-serif !important;
      @media only screen and (max-width: 1140px) {
        font-size:17px;
        font-weight: 400;
      }
    }
    .product-brand {
      color: #000;
      font-weight: bold;
      cursor: pointer;
    }
    .product-colors {
      text-align: left;
      color: #000;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 40px 0px;

      font-weight: bold;
      a {
        color: #000;
        display: inline-block;
        background: #ffef;
        margin: 10px;
        padding: 10px 25px;
        max-width: 50%;
        text-decoration: none;
        border-radius: 15px;
      }
      a.active {
        background: ${({ theme }) => theme.mainThemeBackground};
        color: #fff;
        font-weight: bold;
      }
      a.no-stock {
        background: gray;
        color: #fff;
        opacity: 50%;
      }
    }
    .price-list {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .product-price {
      min-width: 30%;
      display: inline-block;
      background-color: ${({ theme }) => theme.mainThemeBackground};
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      &.adet {
        background-color: #933;
        min-width: 30%;
        font-size: 18px;
        color: #fefe;
      }
      margin-top: 10px;
      padding: 3px 12px;
      border-radius: 5px;
      text-shadow: 1px 1px 1px #000;
      font-size: 35px;
      font-weight: bold;
      font-family: "Overpass", sans-serif !important;
      box-shadow: 6px 6px 0px 0px #000;
      color: ${({ theme }) => theme.color};
      text-align: left;
    }
    .price-box {
      position: absolute;
      top: 120px;
      width: 200px;
      right: 0px;

      @media only screen and (max-width: 1100px) {
        position: relative;
        top: 0;
        width: 100%;
        display: none;
      }
    }
    .mobile-price-box {
      display:none;

      @media only screen and (max-width: 1100px) {
        display: block;
        position: fixed;
        z-index: 100;
        bottom:49px;
        width: 100%;
        background-color: #ffff;
        box-shadow: 0px 0px 10px #ddd;
        left:0;
        padding:10px;
        display:flex;
        justify-content:space-around;
        .mobile-action{
          width:50%;
          display:flex;
          input,button{
            width:100%;
            border:0;
            font-weight:bold;
            font-size:20px;
            outline;
            height:50px;
            text-align:center;
            background-color:#eee;
            border-radius:0px;
            margin:0;
            padding:0;

          }
          button{
            width:50%;
            background-color:#FF9F43;
            color:#fff;
            &:first-child{
              border-top-left-radius:10px;
              border-bottom-left-radius:10px;
            }
            &:last-child{
              border-top-right-radius:10px;
              border-bottom-right-radius:10px;
            }
            &:disabled{
              background-color:#ccc;
            }
          }
          .mobile-add{
            height:50px;
            width:100%;
            button{
              display:flex;
              align-items:center;
              justify-content:space-around;
              width:100%;
              margin-left:20px;
              height:50px;
              font-size:18px;
              font-weight:400;
              background-color:#FF9F43;
              color:#fff;
              border-radius:10px;
              font-weight:bold;

            }
          }
        }
        
        .prices{
          display:flex
          width:50%;
          flex-direction:column;
          .simple-price{
            display:flex;
            justify-content:space-between;
            width:100%;
            &:first-child{
              margin-bottom:10px;
            }
            color:#000;
          }
          span{
            padding-left:10px;
            &:last-child{
              color:#FF9F43;
              font-weight:bold;
            }
          }
        }
      }
    }
    .go-back-btn {
    }
    .product-stock {
      float: right;
      padding: 3px 12px;
      border-radius: 5px;
      text-shadow: 3px 3px 3px #000;
      font-size: 20px;
      font-weight: bold;
      font-family: "Overpass", sans-serif !important;
      text-align: center;
      color: #ffefef;
      background: #333;
      display: inline-block;
    }
    position: relative;
    .go-back-btn {
      float: right;
      span {
        transition: 500ms all;
        margin-left: 10px;
      }
      &:hover {
        span {
          margin-left: 35px;
        }
      }
      padding: 3px 12px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      font-family: "Overpass", sans-serif !important;
      color: #333;
      position: absolute;
      right: -40px;
      top: 5px;
      padding: 10px 25px;
      background: rgba(253, 221, 0, 0.9);
      transition: 1500ms all;
      &:hover {
        cursor: pointer;
        background: #ddd;
      }
      display: inline-block;
      @media only screen and (max-width: 1100px) {
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
        float: none;
        width: 150px;
        margin-left: auto;
      }
    }
    .counter {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: space-between;
      button {
        min-width: 25%;
        background-color: ${({ theme }) => theme.mainThemeBackground};
      }
      button:not(:last-child) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      button:not(:first-child) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .input,
      .input input {
        width: 100%;
        height: 100%;
        position: relative;
        border: 0;
        outline: 0;
        text-align: center;
        font-weight: bold;
        font-family: "Overpass", sans-serif !important;
        color: #000;
        font-size: 28px;
        span {
          position: absolute;
          bottom: 0px;
          font-size: 11px;
          left: 44%;
          user-select: none;
        }
        &:hover,
        &:focus {
          // background-color:${({ theme }) =>
            theme.mainThemeOpacityBackgroundHover};
        }
      }
    }
    .add-basket {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-right: 20px;
      }
      button {
        background-color: ${({ theme }) => theme.mainThemeBackground};
        width: 100%;
        &:hover {
          background-color: #fbf04a;
        }
      }
    }
    .list-price {
      box-shadow: none;
      background: transparent;
      color: #000;
      font-size: 34px;
      &:before {
        content: "";
        width: 170px;
        transform: rotate(-9deg);
        background: ${({ theme }) => theme.altHeader};
        height: 3px;
        z-index: 1000;
        position: absolute;
      }
    }
    width: 90%;
    background-color: ${({ theme }) => theme.productsContainerBackground};
    padding: 50px;
    @media only screen and (max-width: 1100px) {
      padding:20px;
    }
    padding-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    .product-asorti{
      display:flex;
      justify-content:center;
      align-items: center;
      @media only screen and (max-width: 1140px) {
        display:none;
      }
    }
    .product-asorti-mobile{
      display:none;
      @media only screen and (max-width: 1140px) {
        display:flex;
      }
    }
    .product-asorti-mobile{
      justify-content:center;
      align-items: center;
      @media only screen and (max-width: 1140px) {
        max-width:230px!important;
        margin:auto;
        div div{
          width:330px!important;
          max-width:330px!important;
        }
        div div div{
          width:30px!important;
          &:first-child{
            width:100px!important;
          }
          &:last-child{
            width:100px!important;
          }
          font-size:14px;
        }
      }
    }
    
    .product-image {
      margin-top: 0px;
      width: 50%;

      .stock-qty{
        background:#353582;
        padding:8px;
        color:white;
        position:absolute;
        right:5px;
        top:5px;
      }
    }
    
    background-color: rgba(0, 0, 0, 0.015);
    .product-store {
      margin-top: 0px;
      width: 50%;
    }
    @media only screen and (max-width: 1140px) {
      width: 100vw;
      flex-wrap: wrap;
      .product-image {
        min-width: 100vw;
        min-height: 100vw;


        img {
          min-width: 100vw;
          min-height: 100vw;
          scale:0.6;
        }
      }
      .product-store {
        width: 100vw;
      }
      .varitants {
        margin: auto;
        width: 100%;
        .variant-item div {
          width: 150px;
          height: 150px;
          @media only screen and (max-width: 1140px) {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1140px) {
    .detail-image {
      width: 100%;
      img {
        user-select: none;
        user-drag: none;
        width: 100%;
      }
    }
    .detail-header {
      .detail-nav {
        width: 100%;
      }
    }
  }

  .variant-mobile{
    display:none
  }
  @media only screen and (max-width: 1140px) {
    .variant-mobile{
      display:flex;
    }
    .variant-web{
      display:none!important;
    }

  }
`;

const InvoiceContainer = styled.div`
  padding: 0.1% 15%;
  @media only screen and (max-width: 1080px) {
    padding: 0;
  }
  .page-item {
    max-width: 100px!important;
    min-width: 100px!important;
    width: 100px!important;
    img {
      max-width: 100px!important;
      min-width: 100px!important;
      width: 100%!important;
    }
  }
  .head {
    display: flex;
    color: #000;
    justify-content: space-between;
    .leftHead {
      width: 20%;
      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          min-width: 40%;
        }
      }
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    color: #000;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        border: none;
        padding: 5px;
      }
      border: 1px solid #000;
    }
    span {
      padding: 10px;
    }
  }
`;
const SwapButton = styled.button`
  z-index: 999!important;
  border: none !important;
  outline: none !important;
  height: 100% !important;
  font-size: 33px !important;
  transition: 300ms all;
  background:rgba(0,0,0,.1);
  color:#000;
  width:5%;
  &:hover{
    color:#fff;
    background-color:${({theme})=>theme.altHeader};
  }
  @media only screen and (max-width:840px) {
    display:none;
  }
`;

const BrandList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  background-color: rgba(242, 242, 242, 1);
  .brand-item {
    text-decoration: none;
    color: #333;
    width: 240px;
    height: 240px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5px;
    background-color: #fff;
    p {
      font-weight: normal;
      font-size: 16px;
      font-family: "Lato", sans-serif;
    }
    padding-top: 15px;
    @keyframes scaleAnimation {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }

    @keyframes scaleAnimation {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }

    .overlay {
      position: absolute;
      z-index: 999;
      color: #fff;
      background: darkred;
      width: 50%;
      top: 15%;
      transform: scale(0);
      animation: scaleAnimation 1s infinite;
    }
    .image {
      width: 240px;
      height: 240px;
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 1000px;
      transition: 600ms transform;
      &.overlayimg {
      }
      margin-bottom: 5px;
    }
    .imageFlip {
      opacity: 0;
      transform: rotateY(180deg);
      width: 240px;
      height: 0px;
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 1000px;
      transition: 600ms transform;
      &.overlayimg {
      }
      margin-bottom: 5px;
    }
    &:hover {
      .image {
        height: 0px;
        position: absolute;
        opacity: 0;
        transform: rotateY(180deg);
        background-size: 80%;
      }
      .imageFlip {
        height: 240px;
        position: relative;
        opacity: 1;
        transform: rotateY(0deg);
        background-size: 80%;
      }
    }
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
    transition: 300ms box-shadow;
    position: relative;
    .mevcut {
      margin-bottom: 5px;
      font-size: 13px;
      transition: 200ms all;
      span {
        background-color: #000;
        color: #fff;
        border-radius: 15px;
        padding: 2px 4px;
        transition: 200ms all;
      }
    }
    @media only screen and (max-width: 1140px) {
      width: calc(50% - 4px);
      height: 250px;
      margin: 2px;
      .image {
        width: 100%;
      }
    }
    &:hover {
      color: #000;
      box-shadow: 0px 5px 1px 0px #000;
      .mevcut {
        transform: scale(1.2);
        span {
        }
      }
    }
  }
`;
export {
  BrandList,ProductItem,
  DetailContainer,
  ProductList,
  FilterContainer,
  ProductListTitle,
  InvoiceContainer,
  SwapButton,
};