
export const tr = {
  lang: "tr",
  login: "Giriş Yap",
  register: "Kayıt Ol",
  logout: "Çıkış Yap",
  forgotPassword: "Sifrenizi mi unuttunuz?",
  userLogin: "Üye Girişi",
  email: "E Posta",
  password: "Şifre",
  productSearch: "Ürün ara",
  search: "ARA",
  balance: "Bakiye",
  welcome: "Hosgeldiniz",
  discovery: "Ana Sayfa",
  homepage: "ANA SAYFA",
  vatIncluded: "Kdv Dahildir",
  stockcode: "Stok Kodu",
  price: "Fiyat",
  addBasket: "Sepete Ekle",
  categories: "Kategoriler",
  myCart: "Sepetim",
  goCart: "Sepete Git",
  goPrevious: "Önceki Sayfaya Dön",
  confirmOrder: "Siparişi Onayla",
  total: "Toplam",
  clearCart: "Sepeti Boşalt",
  continueBuying: "Alışverişe Devam Et",
  confirmCart: "Siparişi Onayla",
  currentExtract: "Cari Ekstre",
  myOrders: "Siparişlerim",
  productReport: "Ürün Raporu",
  print: "Yazdır",
  dateSearch: "Tarih Ara",
  noDataCollections: "Hareket kaydı bulunamadı",
  noDataOrders: "Sipariş kaydı bulunamadı",
  date: "Tarih",
  description: "Açıklama",
  amount: "Tutar",
  status: "Durum",
  detail: "Detay",
  inspect: "İncele",
  orderNo: "Sipariş No",
  name: "Ad",
  unit: "Birim",
  quantity: "Miktar",
  vat: "Kdv",
  vatInclude: "Kdv Dahil",
  totalAmount: "Toplam Tutar",
  cancel: "İptal Et",
  confirmCancel: "İptali Onaylıyorum",
  account: "Hesabım",
  product: "Ürün",
  type: "Tür",
  receivable: "Alacak",
  debt: "Borç",
  invoice: "Fatura",
  noData: "Veri bulunamadı",
  emptyCart: "Sepetiniz Boş",
  loading: "Yükleniyor",
  color: "Renk",
  category: "Kategori",
  categories: "Kategoriler",
  stockOn: "Stokta var",
  stockOff: "Stokta yok",
  showMore: "Daha Fazla Ürün Göster",
  clearFilter: "Filtreyi Temizle",
  newProducts: "Yeni Ürünler",
  oldProducts: "Eski Ürünler",
  increasedPrice: "Artan Fiyat",
  decreasingPrice: "Azalan Fiyat",
  increasedAvailable: "Artan Mevcut",
  decreasingAvailable: "Azalan Mevcut",
  topSeller: "En Çok Satan",
  aZ: "Ürüne Göre (A-Z)",
  zA: "Ürüne Göre (Z-A)",
  filters: "Filtreler",
  goTop: "Yukarı Çık",
  stockCount: "Stok Miktarı",
  inStock: "Sadece Stoktakileri Görüntüle",
  outOfStock: "Stokta Yok",
  vatIncludePrice: "Kdv Dahil",
  vatExcludePrice: "Kdv Hariç",
  // plusVat:"+KDV"
  plusVat: "",
  qty: "Adet Kaldı",
  document:'Belgeler',
  specialForYou: 'Size Özel',
};