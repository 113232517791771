import React, { useState, useEffect } from 'react';
import Header from '../components/Navigation/Header';
import BottomNav from '../components/Navigation/Bottom';
import Footer from '../components/Navigation/Footer';
import axios from 'axios';

export default function Belgeler() {
  const [images, setImages] = useState([]); // Resimleri saklamak için state
  const [pdf, setPdf] = useState([]);

  useEffect(() => {
  const fetchImages = async () => {
    try {
      // REACT_APP_API ve REACT_APP_GET_SLIDERS için olan kod
      const slidersUrl = `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_SLIDERS}`;
      console.log("Sliders API URL:", slidersUrl);
      const slidersResponse = await axios.get(slidersUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: localStorage.getItem("bilalototoken"),
        },
      });
      console.log("Sliders API Response:", slidersResponse.data);
      setImages(slidersResponse.data.images); // Slider resimlerini state'e ayarla

      // REACT_APP_GET_IMAGES için yeni kod
      const imagesUrl = `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_IMAGES}`;
      console.log("Images API URL:", imagesUrl);
      const imagesResponse = await axios.get(imagesUrl, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: localStorage.getItem("bilalototoken"),
        },
      });
      console.log("Images API Response:", imagesResponse.data);
      setPdf(imagesResponse.data.images); // Diğer resimleri state'e ayarla
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  fetchImages(); // Sayfa yüklendiğinde resimleri getir
}, []);

  return (
    <>
      <Header/>

      <div style={{padding:"20px"}}>
        <h2>BELGELER</h2>
  
        <div className="image-grid" style={{ display: "flex", flexDirection: "column"}}>
          {/* Resimlerin listesi */}
          {images.map((imageUrl, index) => (
            <div key={index} className="image-row" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ccc" }}>
              <div className="image-column">
                <img src={imageUrl} alt={`Image ${index + 1}`} style={{ width: "150px",marginRight:'20px', marginBottom: "10px",marginTop: "10px" }} />
              </div>
              <div className="button-column garanti-belgesi">
                {/* Sabit bir PDF dosyasını indirme bağlantısı */}
                <a href={pdf} download target='_blank' style={{ color: "white", backgroundColor: "blue", outline: "none", border: "none", padding: "5px", textDecoration: "none" }}>Garanti Belgesini İndir</a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <BottomNav/>

      <Footer/>
    </>
  );
}
