import React, { useEffect, useState } from "react";
import { useProductRequests } from "../../../helpers/ProductRequests";
import { Link } from "react-router-dom";

import CategoryItems from "./CategoryItems";
import BrandItems from "./BrandItems";
import { useHistory } from "react-router";
import Carousel, { consts } from "react-elastic-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faCheck,
  faSearch,
  faSearchPlus,
  faTrashRestore,
} from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../useWindowDimensions";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
export default function CategoryList({
  slug,
  slugPrefix,
  setSelectedBrand,
  selectedBrand,
}) {
  const carouselRef = React.useRef();

  const { getCategories, getBrands } = useProductRequests();
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const history = useHistory();
  const width=useWindowDimensions().width

  useEffect(() => {
    getCategories(selectedBrand)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
      });
  }, [selectedBrand]);

  return (
    <div
      style={{
        borderTopWidth: 2,
        borderColor: "#ddd",
        paddingTop: 5,
        borderStyle: "solid",
        position: "sticky",
        top: 0,
        zIndex: 9999999,
        backgroundColor: "white",
        zIndex: 100,
      }}
    >
      {/* <ul style={{ display: "none" }}>
        {categories.map((category) => {
          return (
            // <CategoryItems slug={slug} slugPrefix={slugPrefix} category={category} key={category.slug} />
            <div
              key={category.slug}
              onClick={() => {
                history.push(
                  (slugPrefix != undefined ? slugPrefix : "") + category.slug
                );
              }}
              className={
                (category.slug == slug ||
                category.subGroups.filter((x) => x.slug == slug).length > 0
                  ? "active"
                  : "") + " items"
              }
            >
              <Link
                to={(slugPrefix != undefined ? slugPrefix : "") + category.slug}
              >
                {category.name}
              </Link>
            </div>
          );
        })}
      </ul> */}
      <div
        style={{
          width: "100%",
          paddingLeft: 30,
          fontSize: 16,
          fontWeight: "bold",
          marginTop: 5,
          zIndex: 1000,
          marginBottom: -35,
        }}
      >
        <>Kategori</>
      </div>
      {/* <Carousel
        ref={carouselRef}
        disableArrowsOnEnd={true}
        pagination={false}
        style={{ borderBottom: "2px solid rgba(221,221,221)" }}
        itemsToShow={10}
      >
        {categories.map((category) => {
          return category.subGroups.map((sub_category) => {
            return (
              <div
                key={sub_category.slug}
                onClick={() =>
                  history.push(
                    (slugPrefix != undefined ? slugPrefix : "") +
                      sub_category.slug
                  )
                }
                style={{
                  display: "flex",
                }}
                className={
                  "category-item " +
                  (sub_category.slug == slug ? "active" : "") +
                  " "
                }
              >
                <Link
                  to={
                    (slugPrefix != undefined ? slugPrefix : "") +
                    sub_category.slug
                  }
                >
                  <div
                    className="img"
                    style={{ backgroundImage: `url(${sub_category.image})` }}
                  ></div>

                  {sub_category.name.replace(/\//g, " ")}
                </Link>
              </div>
            );
          });
        })}
      </Carousel> */}
      {categories.find((x) => x.slug == slug) == undefined &&
        categories.filter((x) => x.subGroups.find((y) => y.slug == slug))
          .length == 0 && (
          <Carousel
            
            // breakPoints={[
            //   {
            //     width: 1,
            //     itemsToShow: 1,
            //   },
            //   {
            //     width: 550,
            //     itemsToShow: 2,
            //   },
            //   {
            //     width: 768,
            //     itemsToShow: 3,
            //   }
            // ]}
            disableArrowsOnEnd={true}
            pagination={false}
            style={{
              borderBottom: "2px solid rgba(221,221,221)",
            }}
            itemsToShow={width < 800 ? 3 : width<1200 ? 3 : 10}
          >
          
            {categories.map((category) => {
              return category.subGroups.map((sub_category) => {
                return (
                  <div
                    key={sub_category.slug + "adsxx"}
                    onClick={() => {
                      history.push({
                        pathname: "/kategori/" + sub_category.slug,
                        state: {
                          selectedBrand: selectedBrand,
                        },
                      });
                      if (sub_category.slug == slug) {
                      //  setSelectedBrand("");
                      }
                    }}
                    className="category-item"
                  >
                    <a
                      // to={
                      //   (slugPrefix != undefined ? slugPrefix : "") +
                      //   sub_category.slug
                      // }
                    >
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${sub_category.image})`,zIndex:-1
                        }}
                      ></div>

                      {sub_category.name.replace(/\//g, " ")}
                    </a>
                  </div>
                );
              });
            })}
          </Carousel>
        )}
      {categories.map((category) => {
        return (
          <Carousel
            // breakPoints={[
            //   {
            //     width: 1,
            //     itemsToShow: 1,
            //   },
            //   {
            //     width: 550,
            //     itemsToShow: 2,
            //   },
            //   {
            //     width: 768,
            //     itemsToShow: 3,
            //   }
            // ]}
            key={category.slug}
            disableArrowsOnEnd={true}
            renderArrow={({ type, onClick, isEdge }) => {
              const pointer = type === consts.PREV ? faAngleLeft : faAngleRight
              return true ? (
                <div
                >
                  <button
                    onClick={onClick}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      outline: "none",
                      padding: 10,
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 10000,
                      left: type === consts.PREV ? 0 : "unset",
                      right: type === consts.NEXT ? 0 : "unset",

                    }}>
                    <FontAwesomeIcon

                      icon={pointer}
                    />
                  </button>
                </div>
              ) : (
                <></>


              )

            }}
            pagination={false}
            style={{
              borderBottom: "2px solid rgba(221,221,221)",
              display:
                category.slug == slug ||
                category.subGroups.find((sub) => sub.slug === slug) != undefined
                  ? "block"
                  : "none",
            }}
            itemsToShow={width < 800 ? 3 : width < 1200 ? 3 : 10}
          >
            {categories.filter((x) => x.slug == slug).length == 0 && (
              <div
                onClick={() => {}}
                style={{
                  height: 130,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={"category-item "}
              >
                <Link
                  to={
                    (slugPrefix != undefined ? slugPrefix : "") + category.slug
                  }
                >
                  <div
                    className="img"
                    style={{
                      background: "#F27A1A",
                      color: "white",
                      borderRadius: 105,
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 30,
                      fontWeight: "bold",
                      marginTop: 20,
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashRestore} color="#fff" />
                  </div>
                  Filtreyi Temizle
                </Link>
              </div>
            )}
            {category.subGroups.map((sub_category) => {
              return (
                <div
                  key={sub_category.slug}
                  onClick={() => {
                    history.push(
                      (slugPrefix != undefined ? slugPrefix : "") +
                        sub_category.slug
                    );
                    if (sub_category.slug == slug) {
                      setSelectedBrand("");
                    }
                  }}
                  style={{
                    display: "flex",
                  }}
                  className={
                    "category-item " +
                    (sub_category.slug == slug ? "active" : "") +
                    " "
                  }
                >
                  <Link
                    to={
                      (slugPrefix != undefined ? slugPrefix : "") +
                      sub_category.slug
                    }
                  >
                    <div
                      className="img"
                      style={{ backgroundImage: `url(${sub_category.image})` ,zIndex:-1}}
                    ></div>

                    {sub_category.name.replace(/\//g, " ")}
                  </Link>
                </div>
              );
            })}
          </Carousel>
        );
      })}
    </div>
  );
}
