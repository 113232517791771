import React,{useState,useLayoutEffect, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { DetailContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Currency } from 'react-intl-number-format/dist/index.js';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../components/useWindowDimensions'

export default function Basket(props) {
  
  const {getDiscover} = useProductRequests()
  const {getProductReports} = useOrderRequests()
  
  const { basket } = useSelector((state) => state.cart);
  const { language } = useSelector((state) => state.site);

  const [discover,setDiscover]=useState([])
  const [reports,setReports]=useState(null)
  
  useLayoutEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:1,count:20,sort:'RANDOM',sortedBy:''}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])

  useLayoutEffect(() => {
    
    //if(orders.length===0){

    //getOrders().then((response)=>{
    //  setOrders(response.data.data)
    //}).catch((error)=>{
    //  console.log("error",error.response)
    //})

    //}
  }, [])
    const columns = [
      {
        name: "#",
        selector: (row) => <img src={row.image} height={60}  />,
        sortable: true,
      },
      {
        name: language.name,
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: language.unit,
        selector: (row) => row.unit,
        sortable: true,
      },
      {
        name: language.amount,
        selector: (row) => (
          <span style={{ color: "green", fontWeight: "bold" }}>
            <Currency>{row.totalAmount}</Currency>
          </span>
        ),
        sortable: true,
      },
    ];
  const today = new Date();
  today.setDate(today.getDate() - 30)
  const [filterText, setFilterText] = React.useState('');
  const [startDate, setStartDate] = React.useState(today.toISOString().slice(0, 10));
  const [endDate, setEndDate] = React.useState((new Date().toISOString().slice(0, 10)));
  const width=useWindowDimensions().width
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


  const subHeaderComponentMemo = React.useMemo(() => {
    if(width<1140){
      return (
        <div style={{ color: "#000" }}>
          <div
            style={{
              color: "#000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 0px 0px #333",
              width: "100%",
            }}
          >
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              style={{
                border: "none",
                boxShadow: "0px 0px 2px #333",
                height: 35,
                textAlign: "center",
              }}
            />
            <span
              style={{ marginRight: 10, marginLeft: 10, fontWeight: "bold" }}
            >
              -
            </span>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              style={{
                border: "none",
                boxShadow: "0px 0px 2px #333",
                marginRight: 20,
                height: 35,
                textAlign: "center",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              boxShadow: "0px 0px 1px #333",
              padding: 5,
              justifyContent: "center",
              marginRight: "12.5%",
              width: "80%",
              marginTop: 20,
              marginBottom: 50,
            }}
          >
            <FontAwesomeIcon icon={faSearch} color={"#000"} />
            <input
              style={{
                border: "none",
                outline: "none",
                textAlign: "left",
                paddingLeft: 5,
              }}
              placeholder={language.search}
              className="input-group"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
          <div style={{ width: "100%", color: "#000" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  fontSize: 25,
                  fontWeight: "bold",
                  fontFamily: "Overpass",
                }}
              >
                {language.total}{" "}{language.amount}
              </span>
              <div
                style={{
                  backgroundColor: "#a30711",
                  display: "inline-block",
                  marginLeft: 20,
                  padding: "3px 12px",
                  borderRadius: 5,
                  fontSize: 25,
                  color: "#fff",
                  boxShadow: "5px 5px 0px 0px #000",
                  fontFamily: "Overpass",
                }}
              >
                <Currency>{reports != null ? reports.totalAmount : 0}</Currency>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <span style={{ fontSize: 25, fontWeight: "bold" }}>
                {language.quanity} {" "}
              </span>
              <div
                style={{
                  backgroundColor: "#a30711",
                  display: "inline-block",
                  marginLeft: 20,
                  padding: "3px 12px",
                  borderRadius: 5,
                  fontSize: 25,
                  color: "#fff",
                  boxShadow: "5px 5px 0px 0px #000",
                }}
              >
                {reports != null ? reports.totalQuantity : 0}
              </div>
            </div>
          </div>
        </div>
      );
    }
		return (
      <>
        <div style={{ color: "#000" }}>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            style={{
              border: "none",
              boxShadow: "0px 0px 2px #333",
              height: 35,
              textAlign: "center",
            }}
          />
          <span style={{ marginRight: 10, marginLeft: 10, fontWeight: "bold" }}>
            -
          </span>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            style={{
              border: "none",
              boxShadow: "0px 0px 2px #333",
              marginRight: 20,
              height: 35,
              textAlign: "center",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 1px #333",
            padding: 5,
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faSearch} color={"#000"} />
          <input
            style={{
              border: "none",
              outline: "none",
              textAlign: "left",
              paddingLeft: 5,
            }}
            placeholder={language.search}
            className="input-group"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div style={{ width: "100%", color: "#000" }}>
          <div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "bold",
                fontFamily: "Overpass",
              }}
            >
              {language.total} {language.amount}{" "}
            </span>
            <div
              style={{
                backgroundColor: "#a30711",
                display: "inline-block",
                marginLeft: 20,
                padding: "3px 12px",
                borderRadius: 5,
                fontSize: 25,
                color: "#fff",
                boxShadow: "5px 5px 0px 0px #000",
                fontFamily: "Overpass",
              }}
            >
              <Currency>{reports != null ? reports.totalAmount : 0}</Currency>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <span style={{ fontSize: 25, fontWeight: "bold" }}>
              {language.quantity}{" "}
            </span>
            <div
              style={{
                backgroundColor: "#a30711",
                display: "inline-block",
                marginLeft: 20,
                padding: "3px 12px",
                borderRadius: 5,
                fontSize: 25,
                color: "#fff",
                boxShadow: "5px 5px 0px 0px #000",
              }}
            >
              {reports != null ? reports.totalQuantity : 0}
            </div>
          </div>
        </div>
      </>
    );
	}, [filterText, resetPaginationToggle,handleStartDateChange,handleEndDateChange,startDate,endDate,width]);

  const getReport=()=>{
    getProductReports(startDate,endDate).then(res=>{
      console.log("1",res.data)
      setReports(res.data.data)
    })
  }

  const filteredItems=(items) =>{
    return items.filter(
      item=>{
        return (
        ((item.productId+"").toLowerCase().includes(filterText.toLowerCase())) || 
        (item.title.toLowerCase().includes(filterText.toLowerCase())) || 
        ((item.unit+"").toLowerCase().includes(filterText.toLowerCase())) || 
        ((item.totalQuantity+"").toLowerCase().includes(filterText.toLowerCase())) || 
        ((item.totalAmount+"").toLowerCase().includes(filterText.toLowerCase())) 
        )
      }
    );
  }

  
  useEffect(()=>{
    if(startDate!=null && endDate!=null){
      getReport()
    }
  },[startDate,endDate])
  const history=useHistory()
  const handleRowClick = (row) => {
    history.push('/urun/'+row.slug)
  }
  return (
    <>
      
      <Header/>

      <div>
        
        <SlideProductContainer>
        
          {
            false ? 
            
            <>
            <div className="loader">
                <ContentLoader 
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" /> 
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" /> 
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
                
              </div>
              <div className="loader" style={{marginTop:-100}}>
                <ContentLoader 
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" /> 
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" /> 
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
                
              </div>
            </>

            :
            
            <>
            <DataTable
              pagination
              subHeader
              noDataComponent={language.noData}
              subHeaderComponent={subHeaderComponentMemo}
              columns={columns}
              onRowClicked={handleRowClick}
              data={filteredItems(reports!=null ? reports.items : [])}
            />
            </>
          }
          
        </SlideProductContainer>

        <SlideProductContainer>
          
          <h1>{language.discovery}</h1>

          {
            discover.length > 0 ?
            <SlideProducts items={discover} />
            :
            <div className="loader">
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '20px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /></ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              
            </div>
          }
        </SlideProductContainer> 

      
      </div>

      <BottomNav/>

      <Footer/>

    </>
  )
}
