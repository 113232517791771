import React,{useState,useEffect} from 'react'
import Badge from '@mui/material/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faPlus,faTimes, faArrowLeft, faArrowRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Currency } from 'react-intl-number-format/dist/index.js';
import { useCartRequests } from '../../helpers/CartRequests';
import { toast } from 'react-toastify';
import { useSelector,useDispatch } from 'react-redux';
import {setBasket} from '../stores/Cart'
import { useHistory } from 'react-router-dom'
import { SlideProductItem } from '../../styles/Slide';
import { Sentry } from "react-activity";
import { useProductRequests } from '../../helpers/ProductRequests';
import Carousel from "react-elastic-carousel";
import useWindowDimensions from '../useWindowDimensions';
import Variant from '../../views/Variant'
export default function Productproduct({product,variant=false,grid="grid"}) {

  const dispatch=useDispatch()

  const history=useHistory()

  const {addCart,getCart}=useCartRequests()
  
  const {basket}=useSelector(state=>state.cart)
  const {login}=useSelector(state=>state.auth)

  const [popupOpen, setPopupOpen] = useState(null);
  const [variants, setVariants] = useState(null);
  const { getProducts } = useProductRequests();


  const getBasket=()=>{
    if(variant==false){
      getCart().then((res) => {
        dispatch(setBasket(res.data.data));
      });
    }
  }

  const addToBasket=async(prod,e=null)=>{
    e.stopPropagation()
    
    if(login==true ){
      
      const loader=toast.loading('Ürün Sepete Ekleniyor...',{position:toast.POSITION.BOTTOM_RIGHT,isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false})
      addCart([{id:prod.id,count:1}]).then(res=>{
        //
        if(popupOpen==null || !popupOpen){
          getBasket();
        }
        const newBasket=
        toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      })
      .catch(err=>{
        if(err.response!=undefined && err.response.data.data!=undefined){
          toast.update(loader, { render: err.res.data.message, type: "error", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        }
      })
    
    }else{
      history.push('/giris-yap')
    }
  }
  
  React.useEffect(() => {
    if (popupOpen != null && !popupOpen) {
      setTimeout(() => {
        getBasket();
      }, 300);
    }
  }, [popupOpen]);

  
  return (
    <SlideProductItem grid={grid}>
      <div
        className="popup"
        // onClick={() => setPopupOpen(false)}
        style={{
          transform: popupOpen != null && popupOpen ? "scale(1)" : "scale(0)",
          opacity: popupOpen != null && popupOpen ? 1 : 0,
        }}
      >
        <div className="box" onClick={(e) => e.stopPropagation()}>
          <div className="close" onClick={() => setPopupOpen(false)}>
            x
          </div>
          <div onClick={(e) => e.stopPropagation()} className="list">
            {popupOpen != null && popupOpen && (
              <Variant
                onClick={(e) => e.stopPropagation()}
                slug={product.code}
              />
            )}
          </div>
        </div>
      </div>
      <div className="image-box">
        <img
          onContextMenu={() => false}
          onClick={() => {
            history.push("/urun/" + product.slug);
          }}
          src={
            product.image == null
              ? "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png"
              : product.image
          }
          alt={product.title}
        />
      </div>
      {product.variantCount > 0 ? (
        <div
          className="variant-list"
          onClick={
            () => setPopupOpen(true)
            // history.push("/varyant/" + product.code.replace(".", "-"))
          }
        >
          <div className="colors">
            <div></div>
            <div></div>
          </div>
          <div>{product.variantCount}</div>
        </div>
      ) : (
        <></>
      )}
      <h3> {product.title} </h3>
      <div className={"in-stock " + product.stockText}>
        {" "}
        {product.stockText.toUpperCase()}{" "}
      </div>
      {basket != null &&
      basket.find((sub) => sub.productId == product.id) != undefined ? (
        <div className="product-count">
          <div
            style={{
              color: "#ff9f43",
              paddingTop: 10,
              fontSize: 15,
              paddingBottom: 10,
              background: "#fff",
              borderRadius: 1000,
              fontWeight: "bold",
            }}
          >
            <FontAwesomeIcon
              icon={faShoppingCart}
              style={{ marginRight: 0, fontSize: 30 }}
            />
            <span
              style={{
                position: "absolute",
                right: -7,
                top: 10,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 1000,
                color: "#fff",
                background: "red",
              }}
            >
              {basket.find((sub) => sub.productId == product.id).quantity}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      {localStorage.getItem("bilalototoken") != null ? (
        <div className="action">
          {product.listPrice != product.salePrice ? (
            <div className="price list-price">
              {/* <h5> <Currency>0</Currency> </h5> */}
              <div>
                {" "}
                <Currency>{product.listPrice}</Currency>{" "}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="price">
            {/* <h5> <Currency>0</Currency> </h5> */}
            <div>
              {" "}
              <Currency>{product.salePrice}</Currency> <span></span>
            </div>
          </div>
          <div className="basket">
            <button
              style={{
                cursor: product.inStock ? "pointer" : "not-allowed",
                opacity: product.inStock ? "100%" : "10%",
                fontSize: 14,
              }}
              disabled={!product.inStock}
              onClick={(e) => {
                e.stopPropagation();
                addToBasket(product, e);
              }}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
              Sepete Ekle
            </button>
          </div>
        </div>
      ) : (
        <div
          onClick={() => history.push("/giris-yap")}
          style={{ fontSize: 12, textAlign: "center" }}
        >
          Fiyatları görmek için <strong>giriş</strong> yapın
        </div>
      )}
    </SlideProductItem>
  );
}
