import React,{useState,useEffect,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SliderSwitch,CarouselContainer,SlideProductContainer} from '../styles/Home'
import SliderContainer from '../components/Slider'
import SlideProducts from '../components/Slider/Products'
import SlideBrands from '../components/Slider/Brands'

import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";

import {useProductRequests} from '../helpers/ProductRequests'
import { useHistory } from 'react-router-dom'

import {Helmet} from "react-helmet";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { ProductContent } from '../styles/Content'
import ProductItem from '../components/Product/ProductCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { SwapButton } from '../styles/Product'
import Lightbox from 'react-spring-lightbox'
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Sentry } from 'react-activity'

export default function Home(props) {
  
  const { getProducts, getDiscover, getAnotherProducts, getSliders } =
    useProductRequests();
  
  const {slider,language}=useSelector(state=>state.site)
  const [sliders,setSliders]=useState('test1')
  const [images,setImages]=useState([])

  const history=useHistory()

  const [discover,setDiscover]=useState([])
  const [man,setMan]=useState([])
  const [women, setWomen] = useState([]);
  const [garson, setGarson] = useState([]);
  const [filet, setFilet] = useState([]);
  const [bebe, setBebe] = useState([]);
  const [patik, setPatik] = useState([]);
  const [products,setProducts]=useState([])
  const [mainPageProducts, setMainPageProducts] = useState(
    []
  );
  const getHomePageCategoryItems = async () => {
    const list = [
    ];
    list.map(x=>{
      getProducts({ page: 1, count: 1, groupSlug: x.slug })
        .then((response) => {
          x.set(response.data.data.items);
        })
        .catch((error) => {});
    })
    
    
  }
  useLayoutEffect(() => {
    
    if(products.length===0){
      getAnotherProducts({type:0})
        .then((response) => {
          setProducts(response.data.data.items);
          getProducts({
            page: Math.floor(Math.random() * 5) + 6,
            count: 12,
            sort: "RANDOM",
            sortedBy: "",
          })
            .then((response2) => {
              setProducts([
                ...response.data.data.items,
                ...response2.data.data.items,
              ]);
              getProducts({
                page: Math.floor(Math.random() * 5) + 11,
                count: 20,
                sort: "RANDOM",
                sortedBy: "",
              })
                .then((response3) => {
                  setProducts([
                    ...response.data.data.items,
                    ...response2.data.data.items,
                    ...response3.data.data.items,
                  ]);
                  getProducts({
                    page: Math.floor(Math.random() * 5) + 16,
                    count: 20,
                    sort: "RANDOM",
                    sortedBy: "",
                  })
                    .then((response4) => {
                      setProducts([
                        ...response.data.data.items,
                        ...response2.data.data.items,
                        ...response3.data.data.items,
                        ...response4.data.data.items,
                      ]);
                    })
                    .catch((error) => {});
                })
                .catch((error) => {});
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  }, [])
 

  useEffect(() => {

    if (images == null || images.length == 0) {
      //REACT_APP_GET_SLIDERS
      localStorage.setItem("slider", "");
      const sliderCheck = localStorage.getItem("sliders");
      if (sliderCheck == null) {
        getSliders().then((res) => {
          setImages(res.data.images);
        });
      }
    }
  }, [sliders]);

  const [openedBox, setOpenedBox] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);


  const gotoPrevious = () =>
    currentImageIndex > 0 && (setCurrentIndex(currentImageIndex - 1));

  const gotoNext = () =>
    currentImageIndex + 1 < products.map((x) => x.image).length &&
    (setCurrentIndex(currentImageIndex + 1));

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no"
        />
        <title>
          Bilal Otomotiv
        </title>
      </Helmet>
      {/* <FloatingWhatsApp phoneNumber="+905415242405" buttonStyle={{bottom:60,left:15}}  statusMessage='Her zaman aktif' accountName="Güven Avize Aydınlatma" avatar="https://b2b.bilalotomotiv.com/favicon.ico"  placeholder='İletişime Geç!' chatMessage='Merhaba sitemize hos geldiniz. Size nasıl yardımcı olabiliriz?' /> */}
      {/* <CookieConsent
        location="bottom"
        buttonText="Tümünü Kabul Et"
        cookieName="dehasoft"
        style={{
          background: "#DB2C1D",
          minHeight: "10vh",
          alignItems: "center",
        }}
        buttonStyle={{ color: "#000", fontSize: "15px" }}
        expires={250}
      >
        Bu web sitesi, kullanıcı deneyimini geliştirmek için tanımlama
        bilgilerini kullanır.{" "}
        <span style={{ fontSize: "10px" }}> &copy; dehasoft 2023</span>
      </CookieConsent> */}
      <Header />

      <div>
        {images != null && images.length > 0 ? (
          <CarouselContainer colors={slider}>
            <SliderSwitch>
              {/* <button onClick={()=>setSliders('test1')} className={sliders=='test1' ? 'active' : ''}>Afişlerimiz</button> */}
              {/* <button onClick={()=>setSliders('test2')} className={sliders=='test2' ? 'active' : ''}>Hepsi</button>  */}
              {/* <button onClick={()=>setSliders('test3')} className={sliders=='test3' ? 'active' : ''}>Hepsi</button>  */}
            </SliderSwitch>

            <SliderContainer images={images} products={discover} />
          </CarouselContainer>
        ) : (
          <></>
        )}
        
        <ProductContent open={false}>
          <div className="list">
            <div className="list-title-border">
              <h4>{language.discovery}</h4>
            </div>
            {products == null || products.length == 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Sentry color="#FC9203" size={200} />
                <span>{language.discovery} {language.loading}..</span>
              </div>
            ) : (
              <></>
            )}
            {products.map((x) => {
              return (
                <ProductItem
                  key={x.id + x.slug + "asda"}
                  product={x}
                  setOpenedBox={setOpenedBox}
                />
              );
            })}
          </div>
        </ProductContent>
      </div>
      <div style={{ zIndex: 1009090 }}>
        {products.length > 0 && (
          <Lightbox
            isOpen={openedBox}
            onPrev={gotoPrevious}
            className="lightbox-image-box"
            onNext={gotoNext}
            images={[
              ...products,
              ...man,
              ...women,
              ...garson,
              ...filet,
              ...patik,
              ...bebe,
            ].map((x) => {
              return { src: x.image, loading: "lazy", alt: "deha" };
            })}
            currentIndex={currentImageIndex}
            onClose={() => setOpenedBox(false)}
            style={{ background: "#fff", zIndex: 1009090 }}
            renderNextButton={() => (
              <SwapButton onClick={gotoNext} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleRight} />
              </SwapButton>
            )}
            renderPrevButton={() => (
              <SwapButton onClick={gotoPrevious} className="image-swap-button">
                <FontAwesomeIcon icon={faAngleLeft} />
              </SwapButton>
            )}
            renderFooter={() => (
              <div
                style={{
                  width: "40vw",
                  // background: "rgba(0,0,0,0.5)",
                  height: "40vh",
                  top: "30vh",
                  left: "30vw",
                  position: "fixed",
                  zIndex: 100,
                }}
                onClick={() => {
                  history.push(
                    "/urun/" +
                      [
                        ...products,
                        ...man,
                        ...women,
                        ...garson,
                        ...filet,
                        ...patik,
                        ...bebe,
                      ][currentImageIndex].slug
                  );
                }}
              ></div>
            )}
            renderHeader={() => (
              <button
                onClick={() => setOpenedBox(false)}
                className="light-close"
              >
                X
              </button>
            )}
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
        )}
      </div>
      <BottomNav />

      <Footer />
    </>
  );
}
