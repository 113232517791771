import React,{useState} from 'react'
import SubItem from './SubItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faCheck, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
export default function CategoryItem({ category, slug, selected, setSelected, categoryMode =true,dispatch}) {
  const [open, setOpen] = useState(
    slug == category.slug ||
      category.subGroups.filter((x) => x.slug == slug).length > 0
      ? true
      : (slug==undefined || slug==null ? true:false)
  );

  React.useEffect(() => {
    if (open) {
      if (
        selected.filter(
          (x) => category.subGroups.filter((y) => y.slug == x).length > 0
        ).length == 0 &&
        !selected.includes(category.slug)
      ) {
//        dispatch(setSelected([...selected, category.slug]));
      } else if (selected.includes(category.slug)) {
      //  dispatch(setSelected(selected.filter((x) => x != category.slug)));
      }
    } else {
      if (
        selected.filter(
          (x) => category.subGroups.filter((y) => y.slug == x).length > 0
        ).length == 0 &&
        selected.includes(category.slug)
      ) {
       // dispatch(setSelected(selected.filter((x) => x != category.slug)));
      }
    }
  }, [open]);

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className={"category-item " + (open ? "open" : "")}
      >
        <span onClick={() => setOpen(!open)}>
          <FontAwesomeIcon icon={faAngleDown} />
        </span>
        <span>{category.name}</span>
        <span></span>
      </div>
      {/* <div onClick={() => setSelected(category.subGroups.map((sub) => sub.slug))}  className="category-sub-item" style={{ display: open ? "flex" : "none" }}> 
        <span className="check-box">
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <div className="name">Tümünü Seç</div>
      </div> */}
      <div style={{maxHeight: open ? 200 : 0,overflowX:"auto"}}>
        {category.subGroups
          .filter((x) => selected.includes(x.slug))
          .map((sub) => {
            return (
              <SubItem
                key={"sub-item-" + sub.slug}
                dispatch={dispatch}
                selected={selected}
                setSelected={setSelected}
                slug={slug}
                open={open}
                category={sub}
                parent={category}
              />
            );
          })}
        {category.subGroups
          .filter((x) => !selected.includes(x.slug))
          .map((sub) => {
            return (
              <SubItem
                key={"sub-item-" + sub.slug}
                selected={selected}
                dispatch={dispatch}
                setSelected={setSelected}
                open={open}
                category={sub}
                parent={category}
              />
            );
          })}
      </div>
    </>
  );
}
