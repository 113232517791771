import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ProductList from "../components/Content/ProductList";
import { useProductRequests } from "../helpers/ProductRequests";
import { Helmet } from "react-helmet";
import { Sentry } from "react-activity";

export default function Variant({slug}) {
  const [search, setSearch] = useState("");

  const [products, setProducts] = useState([]);
  const [totalPage, setTotalPage] = useState(-1);
  const [sort, setSort] = useState({ sort: "DATE", sortedBy: "DESC" });
  const [selectedBrand, setSelectedBrand] = useState("");
  const [allData, setAllData] = useState(null);

  const { getProducts } = useProductRequests();

  const getNewProducts = async (
    newpage,
    setLoading,
    search,
    setPage,
    sort = "",
    sortedBy = ""
  ) => {
    if (totalPage != -1 && totalPage < newpage) {
      setLoading(false);
      return;
    }
    setLoading(true);
    getProducts({
      sort: sort,
      sortedBy: sortedBy,
      count: 100,
      variantsOfCode:true,
      productCode:slug,
      page: 1,
    })
      .then(async (response) => {
        setTotalPage(response.data.totalPage);
        setLoading(false);
        if (response.data.data.items && response.data.data.items.length < 1) {
        } else {
          setPage(newpage);
          setProducts([...products, ...response.data.data.items]);
          setAllData(response.data.data);
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (err.response.data.data == null) {
            setLoading(false);
          }
        }
      });
  };

  
  return (
    <>
      
        <ProductList
        variant={true}
        setSelectedBrand={setSelectedBrand}
        selectedBrand={selectedBrand}
        slug={slug}
        getNewProducts={getNewProducts}
        getSearchedProducts={()=>{}}
        products={products}
        search={search}
        allData={allData}
        setAllData={setAllData}
        setSearch={setSearch}
        setProducts={setProducts}
        getProducts={getProducts}
        sort={sort}

        setSort={setSort}
      />
     
    </>
  );
}
