import React, { useState } from "react";
import SubItem from "./SubItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faCheck,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
export default function BrandItem({ brand, slug, selectedBrands, setSelectedBrands ,dispatch}) {
  return (
    <>
      <div
        onClick={() => {
          if (selectedBrands.includes(brand.slug)) {
            dispatch(
              setSelectedBrands(
                selectedBrands.filter((item) => item != brand.slug)
              )
            );
          } else {
            dispatch(setSelectedBrands([...selectedBrands, brand.slug]));
          }
        }}
        className={"category-sub-item "}
      >
        <span
          className={
            "check-box " +
            (selectedBrands.includes(brand.slug) ? "checked" : "")
          }
        >
          <span>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </span>
        <div className="name">
          <span>{brand.name}</span>
          <span className="brand-img">
            {/* ({brand.productCount}) */}
          </span>
        </div>
      </div>
    </>
  );
}
