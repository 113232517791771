import { createSlice } from "@reduxjs/toolkit";
export const product = createSlice({
  name: "site",
  initialState: {
    products: [],
    page: 1,
    brandSearchTxt: "",
    colorSearchTxt: "",
    currentImageIndex: 0,
    openedBox: false,
    filterOpen: false,
    brandOpen: true,
    colorOpen: false,
    logLoad: false,
    colors: [],
    brands: [],
    minPrice: null,
    maxPrice: null,
    soles: [],
    selectedSoles: [],
    selectedColors: [],
    selectedBrands: [],
    selected: [],
    loading: false,
    allData: null,
    sort: { sort: "PRICE", sortedBy: "ASC", name: localStorage.getItem("language") == "tr" ? "Artan Fiyat" : "Increased Price" },
    categories: [],
  },
  reducers: {
    
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setBrandSearchTxt: (state, action) => {
      state.brandSearchTxt = action.payload;
    },
    setColorSearchTxt: (state, action) => {
      state.colorSearchTxt = action.payload;
    },
    
    setOpenedBox: (state, action) => {
      state.openedBox = action.payload;
    },
    setFilterOpen: (state, action) => {
      state.filterOpen = action.payload;
    },
    setBrandOpen: (state, action) => {
      state.brandOpen = action.payload;
    },
    setColorOpen: (state, action) => {
      state.colorOpen = action.payload;
    },
    setLogLoad: (state, action) => {
      state.logLoad = action.payload;
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setSelectedSoles: (state, action) => {
      state.selectedSoles = action.payload;
    },
    setSelectedColors: (state, action) => {
      state.selectedColors = action.payload;
    },
    setSelectedBrands: (state, action) => {
      state.selectedBrands = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllData: (state, action) => {
      state.allData = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSoles: (state, action) => {
      state.soles = action.payload;
    },
    setCurrentIndex: (state, action) => {
      state.currentImageIndex = action.payload;
    }
  },
});

export const {
  
  setPage,
  setCategories,
  setProducts,
  setBrandSearchTxt,
  setColorSearchTxt,
  setOpenedBox,
  setFilterOpen,
  setBrandOpen,
  setColorOpen,
  setLogLoad,
  setColors,
  setBrands,
  setMinPrice,
  setMaxPrice,
  setSelectedSoles,
  setSelectedColors,
  setSelectedBrands,
  setSelected,
  setLoading,
  setAllData,
  setSort,
  setSoles,
  setCurrentIndex,
} = product.actions;
export default product.reducer