import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { SlideProductContainer } from "../styles/Home";
import SlideProducts from "../components/Slider/Products";
import Header from "../components/Navigation/Header";
import BottomNav from "../components/Navigation/Bottom";
import Footer from "../components/Navigation/Footer";
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader";

import { useProductRequests } from "../helpers/ProductRequests";
import { useOrderRequests } from "../helpers/OrderRequests";
import { DetailContainer } from "../styles/Product";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Sentry } from "react-activity";
import { Currency } from "react-intl-number-format/dist/index.js";

export default function Collections(props) {
  const { getDiscover } = useProductRequests();
  const { getCollections } = useOrderRequests();

  const { basket } = useSelector((state) => state.cart);
  const { language} = useSelector((state) => state.site);

  const [loading, setLoading] = useState(true);
  const [discover, setDiscover] = useState([]);
  const [collections, setCollections] = useState([]);

  useLayoutEffect(() => {
    if (discover.length === 0) {
      // getDiscover({ page: 1, count: 20, sort: "RANDOM", sortedBy: "" })
      //   .then((response) => {
      //     setDiscover(response.data.data.items);
      //   })
      //   .catch((error) => {
      //     console.log("error", error.response);
      //   });
    }
  }, []);
  const [printOn, setPrintOn] = React.useState(false);
  const printPdf = () => {
    setTimeout(() => {
      setPrintOn(false);
    }, 4500);
  };

  useLayoutEffect(() => {
    if (collections.length === 0) {
      getCollections()
        .then((response) => {
          setLoading(false);
          setCollections(response.data.data.collections);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error.response);
        });
    }
  }, []);
  
  const columns = [
    {
      name: language.date,
      selector: (row) =>
        row.date != null && row.date.length > 10
          ? row.date.slice(0, 10)
          : row.date,
      // sortable: true,
      width: "100px",
    },
    {
      name: language.type,
      // selector: row => <> <Link to={"/siparisler/"+row.orderId} > <FontAwesomeIcon icon={faEye} /> </Link> </>,
      selector: (row) => (
        <div
          onClick={() => {
            if (printOn == false) {
              setPrintOn(row.collectionId);
              printPdf();
            }
          }}
          className={row.collectionType.replace(/ /g, "-") + " invoices"}
        >
          {printOn != false && printOn == row.collectionId ? (
            <Sentry />
          ) : (
            row.collectionType
          )}
        </div>
      ),
      // sortable: true,
      width: "250px",
    },
    {
      name: language.description,
      selector: (row) => row.explanation,

      // sortable: true,
    },
    // {
    //   name: "Evrak No",
    //   selector: (row) => row.documentNumber,
    //   sortable: true,
    // },

    {
      name: language.receivable,
      selector: (row) => (
        <span style={{ color: "black", fontWeight: "bold" }}>
          {row.currency != false ? row.currencyCredit : row.credit}
          {row.currency != false ? row.currencyUnit : "₺"}
        </span>
      ),
      // sortable: true,
      width: "250px",
    },
    {
      name: language.debt,
      selector: (row) => (
        <span style={{ color: "black", fontWeight: "black" }}>
          {row.currency != false ? row.currencyDebt : row.debt}
          {row.currency != false ? row.currencyUnit : "₺"}
        </span>
      ),
      // sortable: true,
      width: "250px",
    },
    {
      name: language.balance,
      selector: (row) => (
        <span style={{ color: "black", fontWeight: "bold" }}>
          {row.currency != false ? row.balance : row.balance}
          {row.currency != false ? row.currencyUnit : "₺"}
        </span>
      ),
      // sortable: true,
      width: "250px",
    },
    {
      name: language.detail,
      width: "80px",
      selector: (row) => (
        <>
          {row.collectionType.indexOf("Fatura") != -1 ? (
            <Link
              className="invoices Beklemede"
              to={"/faturalar/" + row.collectionId}
            >
              {language.invoice} {language.detail}
            </Link>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = collections.filter((item) => {
    return (item.date == null ? "" : item.date).includes(
      filterText.toLowerCase()
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        {printOn != false && printOn == true ? (
          <>
            <iframe
              src={"/yazdir/hareketlerim"}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: -1,
                height: 0,
                width: 0,
              }}
            />
          </>
        ) : (
          <></>
        )}
        <div
          style={{
            color: "#000",
            border: "none",

            backgroundColor: "rgba(242,242,242,.4)",
            marginRight: 20,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 250,
          }}
        >
          {language.balance} :{"   "}
          <Currency>
            {(JSON.parse(localStorage.getItem("customer")).balance)}
          </Currency>
        </div>
        <button
          style={{
            color: "#000",
            border: "none",
            outline: "1px solid #eee",
            marginRight: 20,
            height: 30,
            width: 120,
          }}
          onClick={() => {
            if (printOn == false) {
              setPrintOn(true);
              printPdf();
            }
          }}
        >
          {printOn == true ? (
            <Sentry />
          ) : (
            <>
              <FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
              {language.print}
            </>
          )}
        </button>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 6px #000",
            padding: 5,
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            style={{ border: "none", outline: "none", textAlign: "right" }}
            placeholder={language.date+" "+language.search}
            className="input-group"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </>
    );
  }, [filterText, printOn, resetPaginationToggle,language]);

  return (
    <>
      {printOn != false && printOn == true ? (
        <>
          <iframe
            src={"yazdir/hareketler"}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      {printOn != false && printOn != true ? (
        <>
          <iframe
            src={"yazdir/hareket/" + printOn}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Header />

      <div>
        <SlideProductContainer>
          {collections.length < 1 && loading == true ? (
            <>
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
              <div className="loader" style={{ marginTop: -100 }}>
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
            </>
          ) : (
            <DataTable
              pagination
              subHeader
              paginationPerPage={50}
              paginationRowsPerPageOptions={[50, 100, 200]}
              subHeaderComponent={subHeaderComponentMemo}
              columns={columns}
              noDataComponent={<>{language.noDataCollections}</>}
              data={filteredItems}
            />
          )}
        </SlideProductContainer>

        <SlideProductContainer>
          {/* <h1>Keşfet</h1> */}

          {/* {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )} */}
        </SlideProductContainer>
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
