export const en = {
  lang: "en",
  login: "Log in",
  register: "Register",
  logout: "Log out",
  forgotPassword: "Forgot Password?",
  userLogin: "User Login",
  email: "Email",
  password: "Password",
  productSearch: "Search for a product",
  search: "SEARCH",
  balance: "Balance",
  welcome: "Welcome",
  discovery: "Discovery",
  homepage: "HOMEPAGE",
  vatIncluded: "VAT Included",
  stockcode: "Stock Code",
  price: "Price",
  addBasket: "Add to Basket",
  categories: "Categories",
  myCart: "My Cart",
  goCart: "Go to Cart",
  goPrevious: "Go to Previous Page",
  confirmOrder: "Confirm Order",
  total: "Total",
  clearCart: "Clear Cart",
  continueBuying: "Continue Shopping",
  confirmCart: "Confirm Cart",
  currentExtract: "Current Extract",
  myOrders: "My Orders",
  productReport: "Product Report",
  print: "Print",
  dateSearch: "Date Search",
  noDataCollections: "No transaction records found",
  noDataOrders: "No order records found",
  date: "Date",
  description: "Description",
  amount: "Amount",
  status: "Status",
  detail: "Detail",
  inspect: "Inspect",
  orderNo: "Order No",
  name: "Name",
  unit: "Unit",
  quantity: "Quantity",
  vat: "VAT",
  vatInclude: "VAT Included",
  totalAmount: "Total Amount",
  cancel: "Cancel",
  confirmCancel: "Confirm Cancellation",
  account: "Account",
  product: "Product",
  type: "Type",
  receivable: "Receivable",
  debt: "Debt",
  invoice: "Invoice",
  noData: "No data",
  emptyCart: "Empty Cart",
  loading: "Loading",
  color: "Color",
  category: "Category",
  categories: "Categories",
  stockOn: "Not Available",
  stockOff: "Available",
  showMore: "Show More Products",
  clearFilter: "Clear Filter",
  newProducts: "New Products",
  oldProducts: "Old Products",
  increasedPrice: "Increasing Price",
  decreasingPrice: "Decreasing Price",
  increasedAvailable: "Increasing Available",
  decreasingAvailable: "Decreasing Available",
  topSeller: "Top Seller",
  aZ: "By Product (A-Z)",
  zA: "By Product (Z-A)",
  filters: "Filters",
  goTop: "Go to Top",
  stockCount: "Stock Count",
  inStock: "View Stock Only",
  outOfStock: "Out of Stock",
  vatIncludePrice: "Vat Included",
  vatExcludePrice: "Vat Excluded",
  plusVat: "+VAT",
  qty : "Left In Stock",
  document:'Document',
  specialForYou : 'For You'
};
