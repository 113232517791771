import React, { useState } from "react";
import SubItem from "./SubItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faCheck,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
export default function ColorItem({ color, slug, selectedColors, setSelectedColors ,dispatch}) {
  return (
    <>
      <div
        onClick={() => {
          if (selectedColors.includes(color.color)) {
            dispatch(
              setSelectedColors(
                selectedColors.filter((item) => item != color.color)
              )
            );
          } else {
            dispatch(setSelectedColors([...selectedColors, color.color]));
          }
        }}
        className={"category-sub-item "}
      >
        <span
          className={
            "check-box " +
            (selectedColors.includes(color.color) ? "checked" : "")
          }
        >
          <span>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </span>
        <div className="name">
          <span>{color.color}</span>
          <span style={{fontSize:12}}>({color.count})</span>
        </div>
      </div>
    </>
  );
}
