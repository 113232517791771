import React,{useEffect,useState} from 'react'
import { useProductRequests } from '../../helpers/ProductRequests';
import { useHistory } from 'react-router-dom';
import Carousel from "react-elastic-carousel";
import useWindowDimensions from '../useWindowDimensions';

export default function BrandList({ setSelectedBrand, selectedBrand, slug, slugPrefix }) {
  const carouselRef = React.useRef();
  const { getBrands } = useProductRequests();
  const [brands, setBrands] = useState([]);
  const history = useHistory();
  const width=useWindowDimensions().width;
  useEffect(() => { 
    getBrands(window.location.href.indexOf("/marka")==-1?slug:"")
    .then((response) => {
      setBrands(response.data.data);
    })
    .catch((error) => {
    });
  }, [slug]);
  return (
    <>
      <div
        style={{
          width: "100%",
          borderTopWidth: 2,
          borderColor: "#ddd",
          paddingTop: 10,
          borderStyle: "solid",
          paddingLeft: 30,
          fontSize: 16,
          fontWeight: "bold",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <>Markaya göre alışverişe başla</>
      </div>
      <Carousel
        ref={carouselRef}
        disableArrowsOnEnd={true} 
        pagination={false}
        itemsToShow={width < 1140 ? 3 : width < 1300 ? 4 : 10}
      >
        {brands.map((item, index) => {
          return (
            <div
              key={item.id}
              onClick={() => {
                setSelectedBrand(item.slug);
              }}
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                height: 150,
                width: 140,
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundSize: "80%",
                  backgroundRepeat: "no-repeat",
                  border:
                    selectedBrand === item.slug
                      ? "3px solid #F27A1A"
                      : "1px solid #F5F5F5",
                  borderRadius: 1000,
                  background: "#F5F5F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundPosition: "center",
                  width: 110,
                  height: 110,
                  boxShadow:
                    "0px 0px " +
                    (selectedBrand === item.slug ? "4" : "0") +
                    "px #F27A1A",
                }}
              >
                <span
                  className="brand-name"
                  style={{
                    fontSize: 16,
                    backgroundImage:'url('+item.image+')',
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    border: "1px solid #F5F5F5",
                    height: "100%",
                    backgroundColor: "#fff",
                    borderRadius: 1000,
                    backgroundSize: "90%",
                    backgroundPosition: "center",
                    color: selectedBrand === item.slug ? "#F27A1A" : "#000",
                    fontWeight: selectedBrand === item.slug ? "bold" : "bold",
                  }}
                >
                </span>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
}
