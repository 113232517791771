import { faHome, faList, faSearch, faShoppingBag, faShoppingCart, faThLarge, faThList, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import {BottomNav} from '../../styles/Header'
import { useHistory } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import CategoryPopUp from './PopUp'
import { setLoginPopUp ,setRegisterPopUp} from '../stores/Site'
export default function Bottom() {

  const history=useHistory()

  const {basket}=useSelector(state=>state.cart)
  const dispatch=useDispatch()
  const [open,setOpen]=useState(false)
  const changeLoginPopUp = (value) => {
    dispatch(setLoginPopUp());
  }
  const changeRegisterPopUp = (value) => {
    dispatch(setRegisterPopUp());
  }
  return (
    <>
      <BottomNav>
        <CategoryPopUp open={open} setOpen={setOpen} />

        <Link
          to="/"
          className={history.location.pathname == "/" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faHome} />
          Ana Sayfa
        </Link>
        <Link
          to="/yeni-urunler"
          className={
            history.location.pathname == "/yeni-urunler" ? "active" : ""
          }
        >
          <FontAwesomeIcon icon={faSearch} />
          Ürünler
        </Link>

        {/* <a onClick={()=>setOpen(!open)} ><FontAwesomeIcon icon={faThList} />Kategoriler</a> */}

        {localStorage.getItem("bilalototoken") != null ? (
          <>
            <Link
              to="/sepetim"
              className={
                history.location.pathname == "/sepetim" ? "active" : ""
              }
            >
              <FontAwesomeIcon icon={faShoppingCart} />
              <div>
                Sepetim{basket != null ? <span>{basket.length}</span> : <></>}
              </div>
            </Link>

            <Link
              to="/siparisler"
              className={
                history.location.pathname == "/siparisler" ? "active" : ""
              }
            >
              <FontAwesomeIcon icon={faShoppingBag} />
              Siparişlerim
            </Link>
          </>
        ) : (
          <>
            <a onClick={changeLoginPopUp}>
              <FontAwesomeIcon icon={faUser} />
              Giriş Yap
            </a>
            <a onClick={changeRegisterPopUp}>
              <FontAwesomeIcon icon={faUserPlus} />
              Kayıt Ol
            </a>
            <Link to="/"></Link>
          </>
        )}
        {/* <Link
          to="/markalar"
          className={history.location.pathname == "/markalar" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faThLarge} />
          Markalar
        </Link> */}
        {/* <Link to="/urun-raporu" ><FontAwesomeIcon icon={faList} />Hareketler</Link> */}

        {/* <Link to="/" ><FontAwesomeIcon icon={faUser} />Hesabım</Link> */}
      </BottomNav>
    </>
  );
}
