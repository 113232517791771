import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Currency } from "react-intl-number-format/dist/index.js";
import { Fab } from "@mui/material";
import { setBasket } from "../stores/Cart";
import { useDispatch, useSelector } from "react-redux";
import { useCartRequests } from "../../helpers/CartRequests";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function Item({ item, cart }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [count, setCount] = useState(item.quantity);

  const {language} = useSelector(state => state.site)

  const { decrementCart, incrementCart, extractionCart } = useCartRequests();

  const increment = async (item, count = 1) => {
    const basket = await cart.map((sub) => {
      if (sub.id === item.id) {
        return {
          ...sub,
          quantity: sub.quantity + count,
          total: sub.total + sub.price * count,
        };
      }
      return sub;
    });

    incrementCart(item.productId, count)
      .then((response) => {
        dispatch(setBasket(basket));
        setCount(item.quantity + count);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const decrement = async (item, remove = false, count = 1) => {
   
    if (remove) {
      const basket = await cart.filter((sub) => {
        return sub.productId !== item.productId;
      });
      
      extractionCart(item.productId)
        .then((response) => {
          dispatch(setBasket(basket));
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      const basket = await cart.map((sub) => {
        if (sub.productId === item.productId) {
          return {
            ...sub,
            quantity: sub.quantity - count,
            total: sub.total - sub.price * count,
          };
        }
        return sub;
      });
      decrementCart(item.productId, count)
        .then((response) => {
          dispatch(setBasket(basket));
          setCount(item.quantity - count);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleChange = async (e) => {
    if (e.target.value < item.quantity) {
      decrement(
        item,
        e.target.value == 0 ? true : false,
        item.quantity - e.target.value
      );
    } else {
      increment(item, e.target.value - item.quantity);
    }
  };

  return (
    <li key={"cart-item-" + item.id}>
      <div>
        <img
          onClick={() => history.push("/urun/" + item.slug)}
          onContextMenu={() => false}
          src={
            item.image == null
              ? "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png"
              : item.image
          }
          alt={item.title}
          style={{ cursor: "pointer" }}
        />{" "}
      </div>
      <div className="removeItem" onClick={() => decrement(item, true)}>
        <FontAwesomeIcon icon={faTrash} />
      </div>
      <div className="item-name">
        <h1>
          {language.lang!="tr" && (item.title2!=null && item.title2!="" ) ? item.title2:item.title}
          <span>
            {/* Mevcut : {item.availableStock > 80 ? "80+" : item.availableStock} */}
          </span>
        </h1>
        <h2>
          <Currency>{item.salePrice}</Currency> 
        </h2>
      </div>
      <div className="item-quantity">
        {/* <div>
          Koli : <span>x{item.quantity}</span>
        </div> */}
        <div>
           <span>x{item.quantity * item.unit2coefficient}</span>
        </div>
      </div>
      <div className="button-list-web">
        <div
          onClick={() => decrement(item, item.quantity == 1 ? true : false)}
          className={`btn-web ${item.quantity == 1 ? " " : ""}`}
        >
          <FontAwesomeIcon icon={item.quantity == 1 ? faTrashAlt : faMinus} />{" "}
        </div>
        <input type="text" onChange={handleChange} value={count} />
        <div
          onClick={() => {
            if (true || count < item.availableStock / item.unit2coefficient) {
              increment(item, 1);
            }
          }}
          className="btn-web"
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              color:
                count == item.availableStock / item.unit2coefficient
                  ? "#ddd"
                  : "unset",
            }}
          />{" "}
        </div>
      </div>
    </li>
  );
  
}
