import styled from "styled-components";
import { rgb, rgba } from 'polished';

const CartContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  .title-control {
    min-width: 500px !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    button,
    .removeAll {
    }
  }
  @media only screen and (max-width: 1140px) {
    .title-control {
      min-width: unset !important;
      button,
      .removeAll {
        margin: 0;
        padding: 0;
        max-width: 50% !important;
        min-width: 50% !important;
        width: 50%;
        font-size: 14px;
        svg {
          font-size: 16px;
        }
      }
      button {
      }
    }
  }
  .payment-card {
    color: #000;
    min-height: 110vh;
    iframe {
      min-height: 110vh;
      width: 100%;
      height: 100%;
    }
    @media only screen and (max-width: 1140px) {
      iframe {
        min-height: 300vh;
      }
    }
    .paymentError {
      padding: 20px;
      font-weight: bold;
      font-size: 22px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 240px;
      box-shadow: 0px 0px 10px #e53935;
      background: #ffcdd2;
      button {
        margin-top: 30px;
        border: none;
        outline: none;
        background: ${({ theme }) => theme.altHeader};
        color: #fff;
        padding: 10px 5%;
        font-weight: bold;
        transition: all 1s;
        &:hover {
          transform: scale(1.1) rotate(1deg);
          background: #e57373;
        }
      }
    }
  }
  .bank-cards {
    color: #000;
    .payment-input-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      label {
        font-weight: bold;
        margin-bottom: 10px;
      }
      textarea {
        padding: 10px;
        border-radius: 5px;
        outline: none;
        resize: none;
      }
    }
    .bank-card {
      padding: 10px;
      border-radius: 5px;
      background: #ddffe5;
      margin-top: 20px;
      &:hover {
        background: #a8f0b8;
        color: #555;
        cursor: pointer;
      }
      h1 {
        font-size: 22px;
        font-weight: bold;
      }
      h2 {
        font-size: 16px;
      }
      div {
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .payment-tabs {
    width: 100%;
    display: flex;
    .payment-tab {
      color: #000;
      padding: 7px 30px;
      margin: 3px;
      font-weight: bold;
      cursor: pointer;
    }
    .payment-tab.active {
      background: ${({ theme }) => theme.altHeader};
      color: #000;
    }
  }
  .list {
    width: 75%;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;

    ul {
      display: block;
      box-shadow: 0px 0px 16px 0px #ddd;
      width: 90%;
      padding: 25px;
      border-radius: 15px;
      position: relative;
      .title {
        .payment {
          display: flex;
          align-items: center;
          background: transparent;
          justify-content: space-between;
          border: none;
          outline: none;
          height: 40px;
          padding: 0px 10px;
          border-radius: 5px;
          transition: 500ms background;
          &:hover {
            color: #fff;
            background: green;
          }
        }
        background-color: ${({ theme }) => theme.primary};
        h1 {
          color: #333;
          font-size: 24px;
          font-weight: bold;
        }
        .removeAll {
          right: 0;
          top: 0;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          background-color: ${({ theme }) => theme.primary};
          cursor: pointer;

          span {
            background-14lor svg {
              font-size: 16px;
            }
            :rgba(242, 242, 242, 0.4) ;
            transition: 200ms all;
            padding: 5px 10px;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 40px;
            border-radius: 5px;
            span {
              font-size: 15px;
            }
            &:hover {
              color: #fff;
              background-color: red;
            }
          }
          font-size: 15px;
        }
      }
      .empty-cart {
        h1 {
          font-size: 27px;
          text-align: center;
          padding-top: 60px;
          font-weight: bold;
          color: #333;
          width: 100%;
        }
        display: flex;
        justify-content: center;

        button {
          padding: 10px;
          margin-top: 5px;
          border-radius: 10px;
          font-size: 16px;
          font-weight: bold;
          transition: 600ms all;
          background-color: ${({ theme }) => rgba(theme.mainThemeColor, 0.5)};
          &:hover {
            background-color: ${({ theme }) => rgba(theme.mainThemeColor, 0.7)};
          }
          color: #fff;
          border: 0;
          outline: 0;
          cursor: pointer;
        }
      }
      overflow-x: auto;
      height: auto;
      @media only screen and (max-width: 1140px) {
        width: 100%;
        .title {
          width: 100%;
        }
      }
      li {
        display: flex;

        justify-content: space-between;
        .item-quantity {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 100px;
          font-size: 20px;
          div {
            margin-bottom: 10px;
            width: 100px;
            span {
              font-weight: bold;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        position: relative;
        align-items: center;
        &:not(:last-child) {
          padding-bottom: 15px;
          margin-bottom: 15px;

          // border-bottom: 1px solid #eee;
          &:not(:first-child) {
            box-shadow: 0px 0px 5px 0px #ddd;
          }
        }
        @media only screen and (max-width: 1140px) {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: auto;
            margin-top: 15px;
            img {
              width: 40px !important;
              height: 40px !important;
              margin-bottom: 40px;
            }
          }
        }
        .buttons {
          @media only screen and (max-width: 1140px) {
            width: 50%;
          }
          border-radius: 100%;
          font-size: 10px;
          margin-bottom: 15px;
          margin-top: 15px;
        }
        .delete {
          background-color: rgba(255, 0, 0, 0.5);
          &:hover {
            background-color: rgba(255, 0, 0, 0.7);
          }
        }
        .removeItem {
          position: absolute;
          bottom: 0px;
          right: -25px;
          height: 0px;
          width: 0px;
          border-bottom-left-radius: 0%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #fff;
          font-size: 0px;
          background-color: rgba(255, 0, 0, 0.5);
          transition: 600ms all;
        }
        &:hover {
          .removeItem {
            font-size: 20px;
            height: 55px;
            width: 55px;
            border-top-left-radius: 100%;
          }
        }
        h3 {
          font-size: 17px;
          font-weight: bold;
          color: red;
        }
        .item-name {
          min-width: 50%;
          max-width: 50%;
          display: flex;
          flex-direction: column;
          position: relative;
          span {
            position: absolute;
            top: 160%;
            left: 0;
          }
          h1 {
            font-size: 17px;
            font-weight: bold;
            color: #000;
          }
          h2 {
            font-size: 24px;
            font-weight: bold;
            color: red;
            width: 100%;
            display: flex;
            text-align: left;
          }

          @media only screen and (max-width: 1140px) {
          }
        }
        div:last-child {
          min-width: 130px;
          max-width: 130px;
          display: flex;
          @media only screen and (max-width: 1140px) {
            min-width: 100%;
            max-width: 100%;
            .buttons {
              height: auto !important;
            }
            input {
              width: 70% !important;
              margin: 0 !important;
            }
          }
          justify-content: space-around;
          align-items: center;
          input {
            width: 55%;
            border: 0;
            outline: 0;
            margin: 0px 10px;
            padding: 10px;
            background-color: #eee;
            border-radius: 10%;
            font-size: 14px;
            font-weight: bold;
          }
          .buttons {
            width: 40px;
            height: 40px;
          }
        }
        div:first-child {
          img {
            border: 1px solid #333;
            width: 160px;
            border-radius: 5%;
          }
        }
      }
      @media only screen and (max-width: 1140px) {
        div {
          width: 100%;
        }
      }
    }
  }

  .finally {
    img {
      margin-bottom: 40px;
    }
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .prod-list {
      padding: 25px;
      margin-bottom: 20px;
      color: #000;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-color: ${({ theme }) => rgba(theme.altHeader, 0.021)};
      border: 1px solid #1111;
      box-shadow: 8px 8px 0px 0px #1111;
      flex-direction: column;
      width: 100%;
      img{
          width: clamp(120px, 20vw, 220px);
      }
      .prod-item:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.altHeader};
      }
      .prod-item {
        width: 100%;
        padding: 5px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          max-width: 45%;
          min-width: 45%;
          width: 45%;
        }
        .price {
          max-width: 45%;
          min-width: 45%;
          width: 45%;
        }
        .qty {
          max-width: 10%;
          min-width: 10%;
          width: 10%;
        }
      }
    }
    .payment.send {
      background: #78e08f;
      color: #000;
      &:hover {
        background: #41c75f;
      }
    }
    .calculate {
      padding: 25px;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      background-color: ${({ theme }) => theme.altHeader};
      box-shadow: 8px 8px 0px 0px #000;
      flex-direction: column;
      width: 100%;

      .head {
        font-size: 20px;
        font-weight: bold;
        font-family: "Overpass", sans-serif !important;
        margin-bottom: 15px;
      }
      .total {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        color: #000;
        font-family: "Roboto", sans-serif !important;
      }
      button {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        transition: 600ms all;
        background-color: ${({ theme }) => rgba(theme.mainThemeColor, 1)};
        color: #000;
        &:hover {
          color: #333;
          background-color: ${({ theme }) => rgba(theme.mainThemeColor, 0.4)};
        }
        border: 0;
        outline: 0;
        cursor: pointer;
        &.confirm {
          background: #ff7f13;
          &:hover {
            color: #fff;
            background-color: #ff9f43;
          }
        }
      }
    }
  }
  .list {
    .button-list-web {
      width: 100%;
      border: 1px solid #ddd;
      display: flex;

      .btn-web {
        width: 100%;
      }
      border-radius: 10px;
      input,
      div {
        display: flex;
        align-items: center;
        justify-content: center;

        widh: 30px;
        min-width: 30px !important;
        cursor: pointer;
        max-width: 30px !important;
        color: #ff7f13;
      }
      input {
        background-color: #fff !important;
        color: #000;
        text-align: center;
        width: 40px !important;
        min-width: 40px !important;
        max-width: 40px !important;
      }
    }
  }
  .list-mobile {
    display: none;
    width: 100%;
    flex-direction: row;

    ul {
      margin-inline-start: 0;
      padding-inline-start: 0;
      list-style: none;
      padding: 10px;
      width: 100%;
      padding-bottom: 40px;
      .empty-cart {
        text-align: center;
        .payment {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          outline: none;
          height: 40px;
          padding: 0px 10px;
          position: relative;
          svg {
            position: absolute;
            right: 10px;
            color: #ff9f43;
          }
          box-shadow: 0 0 5px #ddd;
          margin-top: 20px;
        }
      }
      li {
        &.title {
          width: 100%;
          display: flex;
          flex-direction: row !important;
          justify-content: space-between;
          position: sticky;
          top: 0;
          .title-btn {
            color: #ff9f43;
          }
        }
        display: flex;
        box-shadow: 0 0 5px #ddd;
        background-color: #fff;
        margin-bottom: 20px;
        padding: 10px;
        flex-direction: column;
        .item {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .img {
            width: 25%;
            img {
              border-radius: 10px;
              border: 1px solid #eee;
              width: 100%;
            }
          }
          .name {
            padding: 10px;
            width: 70%;
            font-size: 12px;
            .available,
            .desc {
              margin-top: 5px;
              font-size: 10px;
              color: #333;
            }
          }
        }

        .price-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
          .button-list {
            .btn {
              width: 35%;

              display: flex;
              align-items: center;
              width: 120px;
              .increment,
              .decrement,
              input {
                width: 40px;
                height: 30px;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                border: none;
                outline: none;
                color: #ff9f43;
              }
              input {
                color: #000;
              }

              border: 1px solid #eee;
              border-radius: 15px;
              .decrement {
              }
              display: flex;
            }
          }
          .price {
            text-align: right;
            width: 50%;
          }
        }
      }
    }
  }
  .finally-mobile {
    position: fixed;
    z-index: 100;
    bottom: 50px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px #ddd;
    .calculate {
      display: flex;
      align-items: center;

      width: 100%;
      .head {
        font-size: 22px;
        flex-direction: column;
        display: flex;
        width: 50%;
        text-align: center;
      }
      .total {
        display: flex;
        align-items: center;

        justify-content: center;
        width: 50%;
      }
      button {
        width: 50%;
        border: 0;
        outline: 0;
        height: 40px;
        background-color: #ff7f13;
        color: #fff;
        border-radius: 5px;
        margin: 5px 5px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background-color: #ff7f13;
        }
      }
    }
  }
  .finally-mobile {
    display: none;
  }
  @media only screen and (max-width: 1140px) {
    .finally {
      display: none;
    }
    .list {
      display: none;
    }
    .list-mobile {
      display: flex;
    }
    .finally-mobile {
      display: flex;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .finally {
      width: 100%;
    }
    .calculate {
      width: 80%;
    }
    .list {
      width: 100%;
    }
  }
`;

export {CartContainer}