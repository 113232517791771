import styled from "styled-components";
import { rgba } from "polished";
const Header = styled.nav`
  *::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .balance-mobile {
    display: none !important;
  }
  .buttons {
    min-width: 30%;

    @media only screen and (max-width: 1140px) {
      min-width: unset;
    }
  }
  .search-box {
    margin-left: 20% !important;
    @media only screen and (max-width: 1380px) {
      margin-left: 12% !important;
    }
    @media only screen and (max-width: 1180px) {
      margin-left: 8% !important;
    }
  }
  @media only screen and (max-width: 1140px) {
    z-index: 99;
    .balance {
      display: none !important;
    }
    .loginDrop {
      left: -100px !important;
      width: 100% !important;
      top: 60px !important;
    }
    .balance-mobile {
      display: block !important;
    }
  }
  .balance {
    position: relative;
    min-width: 120px;

    span {
      position: absolute;
      top: 5px;
      font-weight: 700;
      font-size: 13px;
      left: calc(22%);
    }
    margin-right: 10px;

    border-radius: 15px;
    padding: 22px 20px 5px 15px;
    color: #fff;
    box-shadow: 0px 0px 12px #ddd;
    cursor: no-select;
    user-select: none;
  }
  .balance.plus {
    background-color: green;
  }
  .balance.times {
    background-color: red;
  }
  height: 100px;
  background-color: ${({ theme }) => theme.header};
  .loginDrop {
    box-shadow: 0px 0px 4px #555;
    position: absolute;
    top: 106%;
    min-width: 180px;
    left: -50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ theme }) => theme.altHeader};
    color: ${({ theme }) => theme.color};
    align-items: center;
    z-index: 200;
    width: 96%;
    a {
      &:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      width: 100%;
      background-color: ${({ theme }) => theme.altHeader};
      display: block;
      transition: 300ms all;
      text-decoration: none;
      font-size: 13px;
      color: ${({ theme }) => theme.color}!important;
      padding: 10px 0px;
      text-align: center;
      &:hover {
        color: #000 !important;
        background-color: #fff;
      }
    }
  }
  .bars {
    display: none;
    z-index: 99;
  }
  .logo {
    display: flex;
    flex-wrap: nowrap;

    img {
      &:not(:first-child) {
      }
      height: 200px;
      &:hover {
        filter: drop-shadow(0px 0px 1px ${({ theme }) => theme.elementShadow});
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .bars {
      display: block;
      color: red;
      font-size: 43px;
      padding-left: 0px;
    }
    .logo {
      img:not(:last-child) {
      }
      img:not(:first-child) {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 1570px) {
    padding: 0px 5px;
    .search-box {
      margin-right: 30px;
      margin-left: 30px;
      @media only screen and (max-width: 1140px) {
        margin-right: 0px;
      }
    }
    .logo {
      height: 100px !important;
      img {
        height: 100px !important;
        width: unset !important;
        &:not(:first-child) {
        }
      }
    }
  }

  .search-box {
    position: relative;
    margin-right: 8vw;
    margin-left: 8vw;
    .search-result {
      position: absolute;
      transition: 500ms all;
      top: 110%;
      left: 0;
      width: 100%;
      background-color: #fff;
      border: 1px solid ${({ theme }) => theme.elementBackground};
      border-top: 0;
      border-bottom-left-radius: 10px;

      border-bottom-right-radius: 10px;
      z-index: 1000;
      ul {
        list-style: none;
        padding-inline-start: 0px;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        li {
          padding: 10px;
          border-bottom: 1px solid ${({ theme }) => theme.elementBackground};
          cursor: pointer;
          &:hover {
            background-color: rgba(242, 242, 242, 0.5);
            color: #fff;
            border-bottom: 1px solid ${({ theme }) => theme.elementBackground};
            border-top: 1px solid ${({ theme }) => theme.elementBackground};
            border-right: 1px solid ${({ theme }) => theme.elementBackground};
            border-left: 1px solid ${({ theme }) => theme.elementBackground};
          }
          &:last-child {
            border-bottom: 0;
          }
          a {
            text-decoration: none;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            span {
              font-size: 12px;
              margin-left: 10px;
            }
            img {
              width: 30px;
              height: ${30*1.5}px;
              border-radius: 10px;
            }
          }
        }
      }
    }
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.elementBackground};
    background-color: #fff;
    border-radius: 10px;
    padding-left: 20px;
    color: #000;
    &:focus-within,
    &:hover {
      -webkit-box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.elementShadow};
      -moz-box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.elementShadow};
      box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.elementShadow};
    }
    svg {
      border: 0;
      outline: 0;
    }
    input,
    button {
      margin-left: 5px;
      border: 0;
      padding: 12px 0px;
      color: #000;
      outline: 0;
    }
    input {
      width: 300px;
      background-color: #fff;
      color: #000;
      padding-left: 15px;
      @media only screen and (max-width: 1140px) {
        width: 150px;
      }
    }
    button {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 80px;
      font-weight: 900;
      margin: 0px;
      background-color: #fafafa;
      transition: 300ms all;
      &:hover {
        background-color: ${({ theme }) => theme.altHeader};
        color: #333;
        box-shadow: 0px 0px 4px 0px #616161;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    .mobile-search {
      display: none !important;
    }
    @media only screen and (max-width: 1140px) {
      .mobile-search {
        display: flex !important;
      }
      margin-top: -14px;
    }
    a {
      color: #ffff;
    }
    button {
      margin-right: 10px;
      border: 0;
      outline: 0;
      background-color: ${({ theme }) => theme.elementBackground};
      border-radius: 15px;
      color: #000;
      // box-shadow: 0px 0px 2px #000;
      font-weight: 700;
      height: 50px;
      font-size: 14px;
      display: flex;
      align-items: center;
      position: relative;
      .cart-box {
        position: absolute;
        background: red;
        top: 98%;
        box-shadow: 0px 0px 15px #ff9f43;
        border-radius: 10px;
        z-index: 100;
        right: -60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        margin-right: 0px;

        .title {
          width: 100%;
          margin-right: 0px;
          text-align: center;
          font-size: 20px;
          background: #ff9f43;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          color: #fff;
        }
        .btn-list {
          width: 100%;
          display: flex;
          padding: 0;
          margin: 0;
          .btn {
            height: 40px;
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
            &:first-child {
              background: #ff9f43;
              color: #fff;
              border-top-right-radius: 0px;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 0px;
            }
            &:last-child {
              background: #ffcc80;
              color: #000;
              border-top-left-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 10px;
            }
            font-weight: 700;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .finish {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: #fff;
          margin: 0;
          .finish-item {
            div:first-child {
              text-align: left;
              padding-left: 20px;
            }
            padding: 5px 0px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
        }
        .items {
          margin-right: 0px;
          width: 100%;
          background-color: rgb(242, 242, 242);
          min-height: 260px;
          max-height: 260px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 10px 7px;
          margin: 0;
          max-width: 300px;
          .item {
            width: 100%;
            &:not(:last-child) {
              border-bottom: 3px solid #fff;
            }

            padding: 0;
            margin: 0;
            padding-left: 0px;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              width: 50px;
            }
            .remove {
              width: 10px;
            }
            .details {
              width: calc(100% - 20px);
            }
          }
        }
      }
      padding: 10px 25px 10px 20px;
      svg {
        font-size: 22px;

        margin: 5px;
      }
      text-align: right;
      div {
        position: relative;
        span {
          position: absolute;

          bottom: -4px;
          right: -3px;
          font-size: 10px;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #dedede;
          background-color: ${({ theme }) => theme.notifyCircleBackground};
          border: 1px solid #fff;
          font-weight: bold;
          color: #fff;
          padding: 1.3px 5.5px;
          border-radius: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 10;
        }
        margin-right: 10px;
      }

      &:focus-within,
      &:hover {
        -webkit-box-shadow: 0px 0px 3px 1px
          ${({ theme }) => theme.elementShadow};
        -moz-box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.elementShadow};
        box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.elementShadow};
        @media only screen and (max-width: 1140px) {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }

    svg {
      cursor: pointer;
      margin-right: 20px;
      color: ${({ theme }) => theme.color};
      font-size: 22px;
      &:hover {
        filter: drop-shadow(0px 0px 3px ${({ theme }) => theme.elementShadow});
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .box-none {
      display: none;
    }
    .buttons > a {
      display: none;
    }
    .buttons .closer {
      display: none;
    }
    .search-box {
      width: 100%;
      input {
        width: 100%;
      }
    }
    justify-content: space-between;
    padding: 0px;
    .buttons {
      button {
        margin: 0px;
      }
    }
    padding: 0px 10px;
    .logo {
      img {
        height: 65px;
      }
    }
  }
`;

const BottomNav = styled.nav`
  width: 100%;
  display: none;
  z-index: 100;
  box-shadow: 0px 0px 10px #ddd;
  background-color: ${({ theme }) => theme.header};
  position: fixed;
  bottom: 0;
  height: 50px;
  align-items: center;
  justify-content: center;
  .pop-up {
    .close * {
      font-size: 30px;
    }
    .close button {
      padding: 20px 60px;
      position: absolute;
      top: 0;
      right: 0;
    }
    .category-list {
      overflow-y: scroll;
      max-height: 80vh;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
      justify-content: center;
      ul {
        width: 70vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        justify-content: center;
      }
      li {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 80px;
        justify-content: center;
        ul {
          display: none;
        }
      }
      li:nth-child(2n + 1) {
        background-color: ${({ theme }) => theme.dropDownBackground};
      }
      li:nth-child(2n + 0) {
        background-color: ${({ theme }) => theme.elementBackground};
      }
      button {
        border: 0;
        font-size: 24px;
        margin-top: 20px;
        background-color: transparent;
      }
    }
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 200;
    width: 100%;
    height: calc(100% - 51px);
    transition: 400ms all;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => rgba(theme.background, 0.9)};
  }
  a {
    height: 50px;
    cursor: pointer;
    transition: 800ms all;
    color: #000;
    border-radius: 0px;
    &.active {
      box-shadow: 0px 0px 5px #ddd;
      background: #fff;
      transform: scale(1.1);
      border-radius: 10px;
    }
    width: 20%;
    display: flex;
    position: relative;
    z-index: 10;

    font-size: 10px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div {
      position: relative;
    }
    div span {
      position: absolute;
      z-index: 10;

      border-radius: 100%;
      padding: 1.2px 3.4px;
      background-color: #faa700;
      color: #000;
      top: -25px;
      right: -5px;
    }
    &:focus-within,
    &:hover {
      -webkit-box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.elementShadow};
      -moz-box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.elementShadow};
      box-shadow: 0px 0px 3px 1px ${({ theme }) => theme.elementShadow};
    }
    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.elementBackground};
    }
    &:hover {
      background-color: ${({ theme }) => theme.elementBackground};
    }
    svg {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 1140px) {
    display: flex;
  }
`;

const HeaderAlt = styled.div`
  background-color:#ffff;
  width:100%;
  color:${({ theme }) => theme.color};
  position:relative;
  @media only screen and (max-width:1140px){
    
  }
  button{
    color:${({ theme }) => theme.color};
    font-size:14px;
    line-height:18px;
    font-family:'Overpass', sans-serif!important;
  }
  .active {
    background-color: rgba(242,242,242,1)!important;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
  }
  &>ul{
    
    .sub-category{
      
      margin-top:0px;
      box-shadow:0px 0px 22px #F2F2F2;
      background-color:rgba(255,255,255,1)!important;
      button{
        border-radius:10px;
        display:flex;
        max-width:300px;
        @media only screen and (max-width:1440px){
          max-width:300px;
        }
        max-height:120px;
        padding-right:10px;
        align-items:center;
        .ct-image{
          width:50px;
          margin-right:10px;
          height:${50*1.5}px;
          border-radius:1000px;
          background-size:contain;
          background-repeat:no-repeat;
          background-position:center;
          transition:500ms all;
        }
        span{
          margin-top:5px;
        }
        font-size:11px;
        color:${({ theme }) => theme.color};
        font-family: 'Overpass', sans-serif!important;
        transition:200ms all;
        margin:5px;
        padding:3px 10px;
        &:hover{
          box-shadow:0px 0px 22px #F2F2F2;
          color:#333;

          border-radius:10px;
        }
      }
      align-items.center;
      padding:30px 0px;
      flex-wrap:wrap;
    }
    padding-inline-start:0px;
    margin-block-start:0px;
    margin-block-end: 0px;
    list-style:none;
    display:flex;
    @media only screen and (max-width:1140px){
      display:none;
    }
    justify-content:center;
    align-items:center;
    height:60px;
    
    &>li{
      &:hover{
        background:#fff;
        border:none;
        &>button{
          color:#000;
        }
      }
      
      height:60px;
      color:#000!important;      
      display:flex;
      justify-content:center;
      align-items:center;
      font-size:12px;
      max-width:250px;
      text-align:center;
      cursor:pointer;
      div{
        position:absolute;
        top:calc(100% - 5px);
        display:none;
        width:70%;
        left:15%;
        @media only screen and (max-width:1440px){
          left:0%;
          width:100%;
        }
        @media only screen and (max-width:1140px){
          width:100%;
          left:0%;
        }
        z-index:100;
        
      }
      &>button{
        padding:0px 25px;
        color:${({ theme }) => theme.color};

      }
      button{
        border:0;outline:0;
        width:100%;
        background:transparent;
      }
      ul{
        display:none;
        position:absolute;
        top:calc(100% + 1px);
        flex-direction:column;
        min-width:5%;
        // max-height:15vh;
        // overflow-y:auto;
        background-color:#fff;
        justify-content:center;
        align-items:center;       
        padding-inline-start:0px;
        margin-block-start:0px;
        z-index:100; 

        li{
          width:100%;
          
          text-shadow:0px 0px 10px ${({ theme }) => theme.element};
          border-bottom:.2px solid ${({ theme }) => theme.altHeaderBorder};
          &:hover{
            text-shadow:0px 0px 1px ${({ theme }) => theme.element};
          }
          padding:0px 25px;
          display:flex;
          justify-content:center;
          align-items:center;
          font-size:12px;
          max-width:150px;
          text-align:center;
          cursor:pointer;
          min-height:50px;
        }

      }
      &:hover{
        ul{
          display:flex;
        }
        div{
          display:flex;
        }
        text-shadow:0px 0px 1px ${({ theme }) => theme.element};
      }
      &:hover{
        border-bottom:2px solid ${({ theme }) => theme.color};
      }
      &:not(:last-child):before{
        content:'';
        display:block;
        top:25%;
        width:1px;
        height:30px;
        right:0px;
      }
    }

  }

`;

const HeaderTop = styled.div`
  background-color: ${({ theme }) => theme.altHeader};

  width: 100%;
  display: flex;
  justify-content: space-between;
  & > ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    padding: 0px 10%;

    button {
      position: relative;
      height: 30px;
      border: 0;
      outline: 0;
      svg {
        width: 25px;
        height: 15px;
        font-size: 15px;
      }
      & > div {
        display: none;
        position: absolute;

        z-index: 100;
        flex-direction: column;
        top: 100%;
        left: 0px;
        & > div {
          display: flex;
          justify-content: center;
          font-weight: bold;
          font-size: 14px;
          align-items: center;
          min-width: 70px;
          max-width: 70px;
          background-color: ${({ theme }) => theme.altHeader};

          color: #fff;
          padding: 10px;
          &:hover {
            background-color: ${({ theme }) => theme.opacityColor};
            color: ${({ theme }) => theme.background};
          }
        }
      }
      &:focus,
      &:hover {
        div {
          display: flex;
        }
      }
    }
    li {
      height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      margin-right: 20px;
      a {
        color: ${({ theme }) => theme.opacityColor};
        text-decoration: none;
        &:hover {
          color: ${({ theme }) => theme.color};
        }
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    width: 100%;
    ul {
      width: 100%;
      height: unset;
    }
    height: unset;
    ul li {
      width: 100%;
      padding: 0;
      margin: 0;
      height: unset;
    }
    position: relative;
  }
`;

const FooterContainer = styled.footer`

  width: 100%;
  margin-top: 50px;
  background-color: #1f1f1f;
  background-color: #04509F;
  .black-footer {
    width: 60%;
    display: flex;
    margin: 0 auto;
    .logo {
      padding-top: 30px;
      height: 100%;
      img {
        width: 45%;
        transform: scale(1.5);

      }
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    padding: 20px 20px;
    .foot-item {
      height: 100%;
      display: flex;
      margin-left: 10px;
      flex-direction: column;
      justify-content: center;
      .c-item {
        display: flex;
        flex-direction: column;
        h1 {
          margin-bottom: 10px;
        }
        span {
          margin-bottom: 5px;
        }
      }
      width: 100%;
      div {
        margin: 20px 0px;
        span {
          color: darkgray;
          font-weight: 800;
          font-size: 14px;
        }
      }
      h1 {
        font-size: 14px;
        font-weight: bold;
        color: #ddd;
      }
    }
    .logo {
      width: 150%;
    }
  @media only screen and (min-width:2400px)
  {
   img{
    margin-top:-45px;
   }
  }


    @media only screen and (max-width: 1140px) {
      .logo {
        width: 120%;
        img {
          width: 45%;
          transform: scale(2.5);
        }
      }
    }
    @media only screen and (max-width: 918px) {
      text-align: center;
      flex-direction: column;
      .logo {
        width: 100%;
        img {
          width: 80%;
          transform:scale(1);
          margin: 0 auto;
        }
      }
    }
  }
  .copyright {
    background-color: #fff;
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #555 !important;
    padding: 5px 0px;

    div {
      margin-right: 20px;
      a {
        text-decoration: none;
        color: #555 !important;
        &:hover {
          color: ${({ theme }) => theme.color};
        }
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .copyright {
      height: 130px;
      padding-bottom: 60px;
    }
  }
  @media only screen and (max-width: 1140px) {
    .ads img {
      margin-top: 15px;
    }
  }
`;

const HeaderAltMobile = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    width: 20%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${(props) => (props.open == true ? "100%" : "0%")};
    right: ${(props) => (props.open == true ? "0" : "-200vw")};
    transition: 600ms all;
  }
  position: fixed;
  bottom: 0;
  width: 80%;
  left: ${(props) => (props.open == true ? 0 : -200)}vw;
  transition: 600ms all;
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: 9999999999;

  background-color: #fff;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 0px;
  }
  .close-btn {
    position: absolute;
    top: -4px;
    right: 8px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    font-size: 33px !important;
    color: #555;
    text-shadow: none;
  }
  overflow-y: auto;
  * {
    text-decoration: none;
    color: #000;
  }
  z-index: 1001;
  ul {
    padding-inline-start: 0px;
    width: 100%;
    z-index: 1001;
    padding-bottom: 50px;
    display: block;
    flex-direction: column;
    justify-content: center;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    align-items: center;
    li {
      width: 100%;
      display: block;
      margin-top: 10px;

      button {
        background-color: #fff;
        width: 100%;
        outline: 0;
        border: 0;
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }

        span {
          padding-left: 5px;
          text-align: left;
        }
        div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        padding: 10px 0px;
      }
    }
  }
  .sub-category {
    transition: 300ms all;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 14px;
      padding: 10px 0px;
      span {
        width: 100%;
      }
      .ct-image {
        width: 50px !important;
        background:red;
        height: ${50*1.5}px !important;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
      }
    }
  }
`;

const MobileSearchContainer = styled.div`
  display: none;
  @media only screen and (max-width: 1140px) {
    display: ${(props) => (props.open ? "flex" : "none")};
    z-index: 99999 !important;
  }
  transition: 500ms opacity, 500ms transform;
  opacity: ${(props) => (props.open ? "100%" : "0%")};
  transform: scale(${(props) => (props.open ? " 1" : "0")});
  z-index: 99999 !important;
  position: absolute;
  top:140px;
  width: 100%;
  left:0;
  .overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: -15px;
    left:0;
    z-index:100;
    background-color:rgba(0,0,0,.4);
  }
  .search-box {
    position: relative;
    margin-right: 8vw;
    margin-left: 8vw;
    z-index: 99999 !important;
    width: 100%;
    .search-result {
      position: absolute;
      transition: 500ms all;
      top: 110%;
      z-index: 99999 !important;
      left: 0;
      width: 100%;
      background-color: #fff;
      border: 1px solid ${({ theme }) => theme.elementBackground};
      border-top: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      ul {
        list-style: none;
        padding-inline-start: 0px;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        li {
          padding: 10px;
          border-bottom: 1px solid ${({ theme }) => theme.elementBackground};
          cursor: pointer;
          &:hover {
            background-color: rgba(242, 242, 242, 0.5);
            color: #fff;
            border-bottom: 1px solid ${({ theme }) => theme.elementBackground};
            border-top: 1px solid ${({ theme }) => theme.elementBackground};
            border-right: 1px solid ${({ theme }) => theme.elementBackground};
            border-left: 1px solid ${({ theme }) => theme.elementBackground};
          }
          &:last-child {
            border-bottom: 0;
          }
          a {
            text-decoration: none;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            span {
              font-size: 12px;
              margin-left: 10px;
            }
            img {
              width: 50px;
              height: 50px;
              border-radius: 10px;
            }
          }
        }
      }
    }
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.elementBackground};
    background-color: #fff;
    border-radius: 10px;
    padding-left: 20px;
    color: #000;
    &:focus-within,
    &:hover {
      -webkit-box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.elementShadow};
      -moz-box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.elementShadow};
      box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.elementShadow};
    }
    svg {
      border: 0;
      outline: 0;
    }
    input,
    button {
      margin-left: 5px;
      border: 0;
      padding: 12px 0px;
      color: #000;
      outline: 0;
    }
    input {
      width: 100%;
      background-color: #fff;
      color: #000;
      padding-left: 15px;
    }
    button {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 80px;
      font-weight: 900;
      margin: 0px;
      background-color: #fafafa;
      transition: 300ms all;
      &:hover {
        background-color: ${({ theme }) => theme.altHeader};
        color: #333;
        box-shadow: 0px 0px 4px 0px #616161;
      }
    }
  }
`;
export { Header, BottomNav, HeaderAlt, HeaderTop, FooterContainer,HeaderAltMobile ,MobileSearchContainer };
