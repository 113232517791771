import {
  faBars,
  faBell,
  faFrown,
  faFrownOpen,
  faMoon,
  faSearch,
  faShoppingBasket,
  faSmile,
  faSun,
  faTags,
  faTimes,
  faFile,
  faUser,
  faUserAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Header, HeaderTop } from "../../styles/Header";
import Flags,{US,TR,RU,ES} from "country-flag-icons/react/3x2";
import Switch from "react-switch";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage, changeTheme, setRegisterPopUp } from "../stores/Site";
import { useHistory, useLocation } from "react-router";
import { Menu } from "@headlessui/react";
import Categories from "./Categories";

import logo from "../../mainlogo3.png";
import { Currency } from "react-intl-number-format/dist/index.js";
import { useAuthRequests } from "../../helpers/AuthRequests";
import { newCurrencySet, setCustomer } from "../stores/Auth";
import { useCartRequests } from "../../helpers/CartRequests";
import { setBasket } from "../stores/Cart";
import { toast } from "react-toastify";
import { useProductRequests } from "../../helpers/ProductRequests";
import useWindowDimensions from "../useWindowDimensions";
import LoginBox from "../Content/LoginBox";
import MobileSearchBox from "./MobileSearchBox";
import { setLoginPopUp } from "../stores/Site";
import RegisterBox from "../Content/RegisterBox";
export default function HeaderComponent() {
  const dispatch = useDispatch();

  const {loginPopUp,registerPopUp}=useSelector(state=>state.site)  
  const history = useHistory();
  const location = useLocation();
  const width = useWindowDimensions().width;
  const [open, setOpen] = useState(false);

  const { language } = useSelector((state) => state.site);
  const { login, currency } = useSelector((state) => state.auth);

  const { admin } = useSelector((state) => state.auth);

  const { basket } = useSelector((state) => state.cart);

  const { setCurrency } = useAuthRequests();
  const { getCart, extractionCart } = useCartRequests();
  const { getSearchComplete } = useProductRequests();

  const [search, setSearch] = useState("");

  React.useEffect(() => {
    if (
      localStorage.getItem("bilalototoken") == null &&
      (location.pathname == "/sepetim" ||
        location.pathname == "/siparisler" ||
        location.pathname == "/odeme-sayfasi" ||
        location.pathname == "/hareketlerim")
    ) {
      history.push("/");
      return;
    }
  },[location.pathname])

  const changeCurrency = async (newCurrency) => {
    setCurrency(newCurrency).then(async (res) => {
      localStorage.setItem("balance", res.data.data.balance);
      localStorage.setItem("currency", newCurrency);
      const set = await dispatch(newCurrencySet(newCurrency));
      const cart = await getCart();
      const update = await dispatch(setBasket(cart.data.data));
      setTimeout(() => {
        window.location.reload(false);
      }, 700);
    });
  };
  const removeItem = async (e, id) => {
    e.stopPropagation();
    const newbasket = await basket.filter((sub) => {
      return sub.productId !== id;
    });
    extractionCart(id)
      .then((response) => {
        dispatch(setBasket(newbasket));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.response);
      });
  };
  React.useEffect(() => {
    localStorage.setItem("clicked", history.location.pathname);
  }, [history.location.pathname]);
  const [result, setResult] = useState([]);
  const getResult = async () => {
    if (search.length < 3 || search.length > 10) {
      setResult([]);
      return;
    }
    getSearchComplete(search)
      .then((res) => {
        try {
          if (res.data.data != null) {
            setResult(res.data.data);
          } else {
            setResult([]);
          }
        } catch (error) {
          setResult([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setResult([]);
      });
  };
  const blurred = (e) => {
    setTimeout(() => {
      setResult([]);
    }, 300);
  };

  const [cartOpen, setCartOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const changeLoginPopUp = (value) => {
    dispatch(setLoginPopUp());
  }
  const changeRegisterPopUp = (value) => {
    dispatch(setRegisterPopUp());
  };

  const handleLanguage = (lang) => {
    dispatch(changeLanguage(lang));
  };
  return (
    <>
      <LoginBox open={loginPopUp} setOpen={changeLoginPopUp} />
      <RegisterBox open={registerPopUp} setOpen={changeRegisterPopUp} />
      <HeaderTop>
        <ul style={{ zIndex: 99966 }}>
          <li style={{ fontSize: 14, zIndex: 9999 }}>
            <div
              onClick={() => handleLanguage("tr")}
              className={"flag" + (language.lang == "tr" ? " active" : "")}
            >
              <TR title="Türkiye" />
            </div>
          </li>
          <li>
            <div
              onClick={() => handleLanguage("en")}
              className={"flag" + (language.lang == "en" ? " active" : "")}
            >
              <US title="United States" />
            </div>
          </li>
        </ul>
        <ul>
          <li>
            <Link style={{ fontSize: 15 }} to="/">
              {language.welcome}{" "}
              {localStorage.getItem("customer") == null
                ? ". "
                : JSON.parse(localStorage.getItem("customer")).title}
            </Link>
          </li>
        </ul>
      </HeaderTop>
      <Header>
        <div
          style={{
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            paddingLeft: 10,
          }}
        >
          <span
            className="bars"
            style={{
              fontSize: 8,
              marginTop: -8,
              marginBottom: 3,
              textAlign: "center",
              color: "#000",
            }}
          >
            MENÜ
          </span>
          <FontAwesomeIcon
            style={{ cursor: "pointer", fontSize: 25, color: "#000" }}
            onClick={() => setOpen(!open)}
            icon={faBars}
            color="#12087D"
            className="bars"
          />
        </div>
        <div className={"logo "}>
          <Link to="/">
            <img onContextMenu={() => false} src={logo} alt="Logo-Resim" />
            {/* <img onContextMenu={() => false} src={logo2} alt="Logo-Resim" /> */}
          </Link>
        </div>
        <div onBlur={blurred} className={"search-box box-none"}>
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            onKeyUp={(e) => {
              if (e.keyCode == 13) {
                history.push("/search/" + search.replace(/\//g, "-slash-"));
              } else {
                getResult(search);
              }
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={language.productSearch}
          />
          <button
            onClick={() => {
              if (search.length > 0) {
                history.push("/search/" +  search.replace(/\//g, "-slash-"));
              }
            }}
          >
            {" "}
            {language.search}{" "}
          </button>
          <div
            className="search-result"
            style={{
              transform: result.length > 0 ? "scale(1)" : "scale(0)",
              height: result.length > 0 ? "auto" : "0",
              width: result.length > 0 ? "100%" : "0",
            }}
          >
            <ul>
              {result.map((x) => {
                const startIndex = x.title
                  .toLowerCase()
                  .indexOf(search.toLowerCase());
                if (startIndex !== -1) {
                  // Aranan metni bulduysa vurgula
                  const before = x.title.substring(0, startIndex);
                  const highlighted = x.title.substring(
                    startIndex,
                    startIndex + search.length
                  );
                  const after = x.title.substring(startIndex + search.length);
                  console.log("before", before);
                  console.log("highlighted", highlighted);
                  console.log("after", after);
                  return (
                    <li key={x.slug}>
                      <Link
                        onClick={(e) => e.stopPropagation()}
                        to={"/urun/" + x.slug}
                      >
                        <img src={x.image!=null && x.image!=""  ? x.image:"https://entegroid.com/noimage"} alt={x.title} />
                        <span>
                          {before}
                          <strong>{highlighted}</strong>
                          {after}
                        </span>
                      </Link>
                    </li>
                  );
                } else {
                  // Aranan metni bulamadıysa normal olarak görüntüle
                  return (
                    <li key={x.slug}>
                      <Link
                        onClick={(e) => e.stopPropagation()}
                        to={"/urun/" + x.slug}
                      >
                        <img src={x.image} alt={x.title} />
                        <span>{x.title}</span>
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        <div className={"buttons"}>
          <div
            onClick={() => {
              setSearchOpen(!searchOpen);
              setSearch("");
              setResult([]);
              //history.push("/sepetim");
              //setCartOpen(!cartOpen);
            }}
          >
            <div
              className="mobile-search"
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                flexDirection: "column",
                marginRight: 10,
              }}
            >
              <FontAwesomeIcon
                icon={searchOpen ? faTimes : faSearch}
                style={{ fontSize: 15 }}
              />
              <span
                style={{
                  position: "absolute",
                  fontSize: searchOpen ? 14 : 12,
                  width: 60,
                  left: searchOpen ? -12 : 0,
                  top: searchOpen ? 17 : 18,
                  color: "#333",
                  fontWeight: "normal",
                }}
              >
                {searchOpen ? "Kapat" : "Ara"}
              </span>
            </div>
          </div>
          {login == false ? (
            <>
              <Menu as="nav" style={{ position: "relative" }}>
                <div
                  style={{ cursor: "pointer", position: "relative" }}
                  onClick={() => {
                    changeLoginPopUp(true);
                    // history.push("/giris-yap");
                  }}
                >
                  {" "}
                  <FontAwesomeIcon icon={faUserCircle} />{" "}
                  {true && (
                    <span
                      style={{
                        position: "absolute",
                        fontSize: 12,
                        width: 60,
                        left: -10,
                        top: 25,
                      }}
                    >
                      Giriş Yap
                    </span>
                  )}
                </div>
                <Menu.Items className="loginDrop">
                  <Menu.Item>
                    <Link to="/giris-yap">Giriş Yap</Link>
                  </Menu.Item>
                  {/* <Menu.Item>
                      <Link to="/kayit-ol" >Kayıt Ol</Link>
                    </Menu.Item> */}
                </Menu.Items>
              </Menu>
            </>
          ) : (
            <>
              <div
                className={
                  "balance " +
                  (1 * localStorage.getItem("balance") > 0 ? "plus" : "times")
                }
              >
                <span>{language.balance}</span>
                <Currency>{1 * localStorage.getItem("balance")}</Currency>
                {/* {(JSON.parse(localStorage.getItem('customer'))).currencyUnit} */}
              </div>
              {/* <Link to="/"><FontAwesomeIcon icon={faTags} /></Link> */}
              {/* <Link to="/"><FontAwesomeIcon icon={faBell} /></Link> */}
              <button
                onClick={() => {
                  history.push("/sepetim");
                  //setCartOpen(!cartOpen);
                }}
                onMouseMove={(e) => {
                  setCartOpen(true);
                }}
                onMouseLeave={(e) => {
                  setCartOpen(false);
                }}
                className="closer"
              >
                <div>
                  <FontAwesomeIcon icon={faShoppingBasket} />

                  {basket != null && basket != undefined ? (
                    <span>{basket.length}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="cart-box"
                  style={{
                    transform: `scale(${cartOpen ? 1 : 0})`,
                    width: cartOpen ? 300 : 0,
                    maxHeight: cartOpen ? 400 : 0,
                    zIndex: 999,
                  }}
                >
                  {basket != null && basket.length > 0 ? (
                    <>
                      <div className="title">{language.myCart}</div>
                      <div className="items">
                        {basket != null && basket != undefined
                          ? basket.map((item, index) => {
                              return (
                                <div
                                  className="item"
                                  key={index}
                                  onClick={(e) => {
                                    history.push("/sepetim");
                                  }}
                                >
                                  <div
                                    className="image"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      history.push("/urun/" + item.slug);
                                    }}
                                  >
                                    <img
                                      src={
                                        item.image == null
                                          ? "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png"
                                          : item.image
                                      }
                                      alt={item.title}
                                    />
                                  </div>
                                  <div
                                    className="details"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      history.push("/urun/" + item.slug);
                                    }}
                                  >
                                    <div className="name">
                                      {language.lang != "tr" &&
                                      item.title2 != null &&
                                      item.title2 != ""
                                        ? item.title2
                                        : item.title}
                                    </div>
                                    <div className="count">
                                      x{item.quantity}
                                    </div>
                                  </div>
                                  <div className="remove" style={{ width: 10 }}>
                                    <FontAwesomeIcon
                                      color="#FF9F43"
                                      onClick={(e) =>
                                        removeItem(e, item.productId)
                                      }
                                      icon={faTimes}
                                    />
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                      <div className="finish">
                        <div className="finish-item">
                          <div style={{ width: "50%" }}>
                            {language.total} {language.product}{" "}
                          </div>
                          <div style={{ width: 5 }}>:</div>
                          <div style={{ width: "calc(50% )" }}>
                            {basket != null &&
                              basket != undefined &&
                              basket.reduce((a, b) => a + b.quantity, 0)}{" "}
                            Adet
                          </div>
                        </div>
                        <div className="finish-item">
                          <div style={{ width: "50%" }}>
                            {language.total} {language.amount}{" "}
                          </div>{" "}
                          <div style={{ width: 5 }}>:</div>
                          <div
                            style={{
                              width: "calc(50%)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div style={{ marginRight: -10 }}>
                              <Currency>
                                {basket != null &&
                                  basket != undefined &&
                                  basket.reduce(
                                    (a, b) => a + b.salePrice * b.quantity,
                                    0
                                  )}
                              </Currency>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-list">
                        <div
                          onClick={(e) => history.push("/sepetim")}
                          className="btn"
                        >
                          {language.goCart}
                        </div>
                        <div
                          onClick={() => history.push("/odeme-sayfasi")}
                          className="btn"
                        >
                          {language.confirmOrder}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          marginRight: 0,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 25,
                          marginBottom: 20,
                          paddingTop: 50,
                          paddingBottom: 50,
                        }}
                      >
                        {language.emptyCart}
                      </div>
                      <div
                        onClick={() => {
                          history.push("/");
                          setCartOpen(false);
                        }}
                        style={{
                          marginRight: 0,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#FF9F43",
                          color: "#fff!important",
                          textShadow: "none",
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,

                          padding: 10,
                        }}
                      >
                        <FontAwesomeIcon color="#fff" icon={faShoppingBasket} />
                        {language.continueBuying}
                      </div>
                    </>
                  )}
                </div>
              </button>

              <Menu as="nav" style={{ position: "relative" }}>
                <Menu.Button>
                  <FontAwesomeIcon icon={faUserCircle} />
                  {true && (
                    <span
                      style={{
                        position: "absolute",
                        fontSize: 12,
                        width: 60,
                        left: 0,
                        top: 37,
                        color: "#333",
                        fontWeight: "normal",
                      }}
                    >
                      {language.account}
                    </span>
                  )}
                </Menu.Button>
                <Menu.Items className="loginDrop">
                  {/* <Menu.Item>
                    <Link to="#" className="balance-mobile">
                      Bakiyeniz <br />
                      {1 * localStorage.getItem("balance")}
                    </Link>
                  </Menu.Item> */}
                   {/* <Menu.Item>
                    <Link to="/hareketlerim">
                      {JSON.parse(localStorage.getItem("customer")).name}{" "}
                      {JSON.parse(localStorage.getItem("customer")).surname}
                    </Link>
                  </Menu.Item> */}
                   <Menu.Item>
                    <Link to="/tahsilat">Tahsilat</Link>
                  </Menu.Item> 
                  <Menu.Item>
                    <Link to="/hareketlerim">{language.currentExtract}</Link>
                  </Menu.Item>
                  {/* <Menu.Item>
                    <Link to="/faturalar">Faturalar</Link>
                  </Menu.Item> */}
                  <Menu.Item>
                    <Link to="/siparisler">{language.myOrders}</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/urun-raporu">{language.productReport}</Link>
                  </Menu.Item>
                  
                  <Menu.Item>
                    <Link to="/cikis-yap">{language.logout}</Link>
                  </Menu.Item>
                </Menu.Items>
                
              </Menu>
              <button onClick={() => history.push("/belgeler")}>
                <FontAwesomeIcon icon={faFile} />
                <span
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    width: 60,
                    left: -3,
                    top: 37,
                    color: "#333",
                    fontWeight: "normal",
                  }}
                >
                {language.document}
                </span>
              </button>
              
            </>
          )}
        </div>
        <MobileSearchBox
          open={searchOpen}
          setOpen={setSearchOpen}
          getResult={getResult}
          result={result}
          search={search}
          setSearch={setSearch}
          blurred={blurred}
          history={history}
        />
      </Header>

      <Categories open={open} setOpen={setOpen} />
    </>
  );
}
